import { Link } from "react-router-dom";

export const dummytabledata = [
    {
        id : "345346",
        name : "Petugas A",
        username :"petugasA",
        password : "kiky1234",
        phone :"085787899809",
        sex :"L",
        status_active :1,
        created_at :"01-06-2023 09:09:10",
        updated_at :"01-06-2023 09:09:10",
        created_by :1,
        updated_by :1
        
    }
];
// http://localhost:8080/api/v1
export const tableColumns = [
    {
        name: 'Foto',
        cell: (row) => <span><img src={`${process.env.REACT_APP_API_BASE_URL}${row.photo}`} alt={row.name} width={200}/></span>,
        ignoreRowClick: true,
        allowOverflow: true,
        button: true,
    },
    {
        name: 'Nama',
        selector: row => row['name'],
        sortable: true,
        center: false,
    },
    {
        name: 'No Telp.',
        selector: row => `${row.phone}`,
        sortable: true,
        center: true,
    },
    {
        name: 'Status Aktif',
        cell: (row) => (row.status_active === "1") ? <span className='badge badge-light-primary'>Aktif</span> : <span className='badge badge-light-danger'>Non-Aktif</span>,
        sortable: true,
        center: true,
    },
    {
        name: 'Aksi',
        cell: (row) => <span><Link to={`${process.env.PUBLIC_URL}/guard/${row.id}`}>Edit</Link></span>,
        ignoreRowClick: true,
        allowOverflow: true,
        button: true,
    },

];