import React from 'react'
import { Card, CardBody, Row } from 'reactstrap'
import { H4,Btn} from '../../../AbstractElements'
import { Link } from 'react-router-dom';
const RiwayatPatrol = () => {
    return (
        <Row>
            <div className="row">
                <div className="col-sm-12">
                    <Card>
                        <div className="card-header pb-0">
                            <H4>Riwayat Patrol</H4><span>Tekan Tombol untuk menuju halaman riwayat laporan</span>
                        </div>
                        <CardBody>
                            <Btn attrBtn={{ color: "info", size: "md" }}>
                                <Link to={process.env.PUBLIC_URL + "/patrol/laporan"}
                                    style={{ color: 'white' }}>
                                    <div className="likes-profile text-center">{"Menuju Halaman Laporan Riwayat Patroli"}</div>

                                </Link>
                            </Btn>
                        </CardBody>
                    </Card>
                </div>
            </div>
        </Row>
    )
}

export default RiwayatPatrol