import { useContext, useEffect, useState, Fragment } from 'react';
import DatePicker, { registerLocale } from 'react-datepicker';
import { Card, Col, Row, CardBody, Input, Label, InputGroup, Container,CardHeader,Collapse } from 'reactstrap';
import { H6, Image, LI, P, UL, Btn, Breadcrumbs, H5 } from '../../../AbstractElements';
import {  Filters } from '../../../Constant';
import axios from 'axios';
import { useDispatch } from 'react-redux';
import { jsPDF } from "jspdf";
import html2canvas from "html2canvas"
import id from "date-fns/locale/id";
import Select from 'react-select';
import { format } from 'date-fns';
import { useGetListAreaNamesNoLimitQuery, useGetListOfficerNoLimitQuery } from '../../../Services/patrolApiSlice';
import { Link } from 'react-router-dom';
import Filter from './Filter';
import Location from './Location';

registerLocale("id", id);
const Sidebar = ({ refresh, setRefresh, onSeacrhClicked, searchTerm, setSearchTerm, setSearchResults, filterSearchBoolean, setFilterSearchBoolean }) => {
  const [isOpen, setIsOpen] = useState(false);
  const onHandelClick = () => {
    setIsOpen(!isOpen);
  };
  const dispatch = useDispatch();
  const [isFilter, setIsFilter] = useState(true);
  const [startDate, setStartDate] = useState(new Date());
  const [isloadingData, setIsloadingData] = useState(false);
  const [selectedareaOption, setSelectedareaOption] = useState(null);
  const [selectedguardOption, setSelectedguardOption] = useState(null);
  const apiBaseUrl = process.env.REACT_APP_API_BASE_URL;
  let areaList = []
  let guardList = []
  const customDayNames = ['S', 'S', 'R', 'K', 'J', 'D', 'M'];

  const {
    data: areas,
    isLoading: loadArea,
    isSuccess: isSuccessArea,
    isError: isErrorArea,
    error: errorArea,
    refetch: refectArea
  } = useGetListAreaNamesNoLimitQuery()

  const {
    data: guards,
    isLoading: loadGuard,
    isSuccess: isSuccessGuard,
    isError: isErrorGuard,
    error: errorGuard,
    refetch: refectGuard
  } = useGetListOfficerNoLimitQuery()



  if (isSuccessArea) {
    if (areas.data) {

      areas.data.map((item, i) => areaList.push(
        { value: item.id, label: item.name }
      ))
    } else {
      areaList = []
    }
  } else {
    areaList = []
  }

  if (isSuccessGuard) {
    if (guards.data) {

      guards.data.map((item, i) => guardList.push(
        { value: item.id, label: item.name }
      ))
    } else {
      guardList = []
    }
  } else {
    guardList = []
  }

  const handleChange = async date => {
    setStartDate(date);
    setIsloadingData(true)
    console.log(format(date, 'yyyy-MM-dd'))
    getHistoryData(date, selectedareaOption, selectedguardOption)

  };

  const onInputAreaChange = (selectedareaOption) => {
    setSelectedareaOption(selectedareaOption);
    getHistoryData(startDate, selectedareaOption, selectedguardOption)
  };

  const onInputGuardChange = (selectedguardOption) => {
    setSelectedguardOption(selectedguardOption);
    getHistoryData(startDate, selectedareaOption, selectedguardOption)
  };

  const getHistoryData = async (
    startDate,
    selectedareaOption,
    selectedguardOption) => {
    console.log(selectedareaOption)
    console.log(selectedguardOption)
    console.log(startDate)
    setFilterSearchBoolean(true)
    let data = JSON.stringify({
      "arival_on": format(startDate, 'yyyy-MM-dd'),
      "officerId": (selectedguardOption != null ? selectedguardOption.value : ""),
      "area": (selectedareaOption != null ? selectedareaOption.label : "")
    });

    try {


      let config = {
        method: 'post',
        maxBodyLength: Infinity,
        url: `${apiBaseUrl}/patrol/GetFilterPatrolsReport`,
        headers: {
          'Content-Type': 'application/json'
        },
        data: data
      };

      const response = await axios.request(config)
        .then((response) => {
          console.log(JSON.stringify(response))
          setSearchResults(response.data);
          setTimeout(() => {
            setIsloadingData(false)
          }, 2000);
          // console.log(JSON.stringify(response.data));
        })
        .catch((error) => {
          console.log(error);
          setIsloadingData(false)
        });


    } catch (error) {
      console.error('Error fetching search results:', error);
      setIsloadingData(false)
    }
  }

  return (
    <Col xxl="12" xl="12" className="box-col-30">
      <div className="job-sidebar md-sidebar">
        <a className="btn btn-primary  md-sidebar-toggle" href="#javascript" onClick={onHandelClick}>Job Filter</a>
        <div className={`md-sidebar-aside job-left-aside custom-scrollbar ${isOpen ? 'open' : ''} `}>
          <div className="default-according style-1 faq-accordion job-accordion" id="accordionoc">
            <Card>
              <CardHeader>
                <H5 attrH5={{ className: 'mb-0 p-0' }} >
                  <Btn attrBtn={{ className: 'btn btn-link ps-0', onClick: () => setIsFilter(!isFilter), color: 'transperant', databstoggle: 'collapse', databstarget: '#collapseicon', ariaexpanded: 'true', ariacontrols: 'collapseicon' }} >{Filters}</Btn>
                </H5>
              </CardHeader>
              <Collapse isOpen={isFilter}>
                <CardBody className="filter-cards-view animate-chk">
                  <Row>
                    <Col md="3 mb-3">
                      <Filter onSeacrhClicked={onSeacrhClicked} searchTerm={searchTerm} setSearchTerm={setSearchTerm} />
                      {/* <Location refreshData = {refresh} setRefresh = {setRefresh}/> */}
                    </Col>
                    <Col md="3 mb-3">
                      <Label className="col-sm-5 col-form-label text-end">Pilih Tanggal</Label>
                      <InputGroup className="col-sm-12" id="dt-minimum" data-target-input="nearest">
                        <DatePicker
                          locale="id"
                          className="form-control custom-datepicker"
                          selected={startDate}
                          placeholderText='pilih tanggal...'
                          onChange={handleChange}
                          dateFormat="dd-MM-y"
                          weekdaysShort={customDayNames}
                          popperProps={{
                            strategy: "fixed" // use this to make the popper position: fixed
                          }}
                        />
                      </InputGroup>
                    </Col>
                    <Col md="3 mb-3">
                      <Label className="col-sm-5 col-form-label text-end">Pilih Area</Label>
                      {isSuccessArea && areas ?
                        <Select
                          options={areaList}
                          onChange={onInputAreaChange}
                          value={selectedareaOption}
                          isClearable
                          menuPosition={'fixed'}
                          className="js-example-basic-single col-sm-12" />
                        :
                        <p>Tidak Ada Data area</p>
                      }

                    </Col>
                    <Col md="3 mb-3">
                      <Label className="col-sm-5 col-form-label text-end">Pilih Petugas</Label>
                      {isSuccessArea && guards ?
                        <Select
                          options={guardList}
                          onChange={onInputGuardChange}
                          value={selectedguardOption}
                          isClearable
                          menuPosition={'fixed'}
                          className="js-example-basic-single col-sm-12" />
                        :
                        <p>Tidak Ada Data Petugas</p>
                      }

                    </Col>

                  </Row>
                </CardBody>
              </Collapse>
            </Card>
          </div>
        </div>
      </div>
    </Col>
  );
};

export default Sidebar;