import React, { useRef, useState, useEffect } from 'react';
import { FormGroup, Input, Label } from 'reactstrap';
import { Btn, H4, H6, P, Spinner } from '../../../AbstractElements';
import { EmailAddress, LoginWithJWT, OrSignInWith, Password, SignIn } from '../../../Constant';
import { useNavigate } from 'react-router-dom';
import { Jwt_token } from '../../../Config/Config';
import FormPassword from './FormPassword';
import usePersist from "../../../hooks/usePersist"
import { useDispatch } from 'react-redux'
import { useLoginMutation } from "../../../Services/authApiSlice"
import { setCredentials } from '../../../Services/authSlice';
import Cookies from 'js-cookie';

const LoginTab = ({ selected }) => {
  const [email, setEmail] = useState('milan@gmail.com');
  const [password, setPassword] = useState('kiky1234');
  const [togglePassword, setTogglePassword] = useState(false);
  const history = useNavigate();
  const userRef = useRef();
  const errRef = useRef();
  const [user, setUser] = useState('');
  const [errMsg, setErrMsg] = useState('');
  const [persist, setPersist] = usePersist();
  // const [value, setValue] = useState(localStorage.getItem('profileURL' || man));
  // const [name, setName] = useState(localStorage.getItem('Name'));


  const [login, { isLoading }] = useLoginMutation();
  const dispatch = useDispatch();


  useEffect(() => {
    userRef.current.focus()
  }, [])

  useEffect(() => {
    setErrMsg('')
  }, [user, email, password])


  // useEffect(() => {
  //   localStorage.setItem('profileURL', man);
  //   localStorage.setItem('Name', 'Emay Walter');
  // }, [value, name]);
  const loginAuth = async (e) => {
    e.preventDefault();
    // setValue(man);
    // setName('Emay Walter');
    // if (email !== '' && password !== '') {
    //   localStorage.setItem('login', JSON.stringify(true));
    //   history(`${process.env.PUBLIC_URL}/dashboard/default`);
    // }
  };
 
  const loginWithJwt = async (e) => {
    // const requestOptions = { method: 'POST', headers: { 'Content-Type': 'application/json' }, body: { email, password }, };
    // return fetch('/users/authenticate', requestOptions)
    //   .then(handleResponse)
    //   .then((user) => {
    //     setValue(man);
    //     setName('Emay Walter');
    //     localStorage.setItem('token', Jwt_token);
    //     // history( `${process.env.PUBLIC_URL}/dashboard/default`)
    //     history(`${process.env.PUBLIC_URL}/dashboard`)
    //     return user;
    //   });
    e.preventDefault()
    try {
      const userData = await login({ email: email, password: password }).unwrap()
      console.log(userData)
      dispatch(setCredentials({ ...userData }))
      Cookies.set('logged_in', true)
      Cookies.set('refreshToken', userData.refreshToken)
      Cookies.set('access_token', userData.access_token)

      // Extract and save cookies from response headers
      // const setCookieHeader = userData.headers.get('set-cookie');
      console.log( Cookies.get('refreshToken'));
      // if (setCookieHeader) {
      //   const cookies = setCookieHeader.split(';');
      //   cookies.forEach((cookie) => {
      //     document.cookie = cookie.trim(); // Set each cookie
      //   });
      // }

      setUser('')
      setPassword('')
      setEmail('')
      history(`${process.env.PUBLIC_URL}/dashboard`)
    } catch (err) {
      console.log(err, JSON.stringify({ email: email, password: password }))
      if (!err?.originalStatus) {
        // isLoading: true until timeout occurs
        setErrMsg('No Server Response');
      } else if (err.originalStatus === 400) {
        setErrMsg('Missing Username or Password');
      } else if (err.originalStatus === 401) {
        setErrMsg('Unauthorized');
      } else {
        setErrMsg('Login Failed');
      }
      console.log(err?.originalStatus);
      errRef.current.focus()
    }
  };


  const handleUserInput = (e) => setEmail(e.target.value)

  const handlePwdInput = (e) => setPassword(e.target.value)

  const handleToggle = () => setPersist(prev => !prev)

  const content = isLoading ? <div className="loader-box"><Spinner attrSpinner={{ className: 'loader-32' }} /></div> : (
    <form  onSubmit={loginWithJwt} className='theme-form'>
      {/* <H4>{selected === 'simpleLogin' ? 'Sign In With Simple Login' : 'Sign In With Jwt'}</H4> */}
      <H4>{'Mitra Abadi Setia 57'}</H4>
      <P>{'Enter your email & password to login'}</P>
      <p ref={errRef} className={errMsg ? "errmsg" : "offscreen"} aria-live="assertive">{errMsg}</p>

      <FormGroup>
        <Label className='col-form-label'>{EmailAddress}</Label>
        <Input
          className='form-control'
          type='email'
          innerRef={userRef}
          onChange={handleUserInput}
          value={email}
          autoComplete="off"
          required
        />
      </FormGroup>
      <FormGroup className='position-relative'>
        <Label className='col-form-label'>{Password}</Label>
        <div className='position-relative'>
          <Input
            className='form-control'
            type={togglePassword ? 'text' : 'password'}
            onChange={handlePwdInput}
            value={password}
            required
          />
          <div className='show-hide' onClick={() => setTogglePassword(!togglePassword)}><span className={togglePassword ? '' : 'show'}></span></div>
        </div>
      </FormGroup>
      <FormGroup className="mb-0 position-relative">
        <div className="checkbox">
          <input
            type="checkbox"
            className="form__checkbox"
            id="persist"
            onChange={handleToggle}
            checked={persist}
          />
          <Label className="text-muted" for="persist">Trust This Device</Label>
          {/* <a className="link" href="#javascript">{ForgotPassword}</a> */}
        </div>
      </FormGroup>
      <FormPassword />
      <div >
        {selected === 'simpleLogin' ? (
          <Btn attrBtn={{ color: 'primary', className: 'd-block w-100 mt-2', onClick: (e) => loginAuth(e) }}>{SignIn}</Btn>
        ) : (
          // <Btn attrBtn={{ color: 'primary', className: 'd-block w-100 mt-2', onClick: (e) => loginWithJwt(e) }}>{LoginWithJWT}</Btn>
          
          <Btn attrBtn={{ color: 'primary', className: 'd-block w-100 mt-2' }}>{LoginWithJWT}</Btn>
        )}
      </div>

     


      {/* <div className="login-social-title"><H6>{OrSignInWith}</H6></div>
        <FormHeader selected={selected} /> */}
    </form>
  )
  return content
};

export default LoginTab;