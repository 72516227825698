import { LI, P, UL } from "../../../AbstractElements";
import { Desktop, Mobile, Tablet } from "../../../Constant";

const DeviceList = () => {
  return (
    <UL attrUL={{ className: "simple-list d-flex" }}>
      <LI>
        <P attrPara={{ className: "f-w-600 font-primary f-12" }}>{'Laki-laki'}</P>
        <span className="f-w-700">100%</span>
      </LI>
      <LI>
        <P attrPara={{ className: "f-w-600 font-primary f-12" }}>{'Perempuan'} </P>
        <span className="f-w-700">0%</span>
      </LI>
    </UL>
  );
};

export default DeviceList;
