import React from 'react'
import { useForm } from 'react-hook-form';
import { Col, FormGroup, Input, Label, Row } from 'reactstrap';

const EditFormSubarea = ({ editrow, register, formState }) => {
    // const formState :{ errors }
    // const { ref, ...desc } = register("desc");
    return (
        <>
            <Row>
                <Col md="6 mb-3">
                    <FormGroup>
                        <Label className="col-form-label" for="recipient-name">{'Nama Sub-area*'}</Label>
                        <input
                            className="form-control"
                            type="text"
                            placeholder="Nama Subarea"
                            autoComplete="off"
                            name="namaSubarea"
                            defaultValue={editrow?.name}
                            error={formState.errors?.title ? true : false}
                            {...register('namaSubarea')}
                        />
                        {/* <span>{errors.namaSubarea && 'Nama Sub-area is required'}</span> */}
                        <div className="valid-feedback">{'Looks good!'}</div>
                    </FormGroup>
                </Col>
                <Col md="6 mb-3">
                    <FormGroup>
                        <Label className="col-form-label">{'ID Kartu'}</Label>
                        <input className="form-control" defaultValue={editrow?.no_card.Int64} type="Number" placeholder="ID Kartu*" name="no_card"
                            error={formState.errors?.title ? true : false}
                            {...register('no_card')} />
                        {/* <span>{errors.no_card && 'ID Kartu is required'}</span> */}
                        <div className="valid-feedback">{'Looks good!'}</div>
                    </FormGroup>
                </Col>
            </Row>
            <Row>
                <Col md="6 mb-3">
                    <FormGroup>
                        <Label className="col-form-label" for="recipient-name">{'Lattitude*'}</Label>
                        <input
                            className="form-control"
                            type="Number"
                            placeholder="lattitude"
                            autoComplete="off"
                            name="latitude"
                            defaultValue={editrow?.latitude}
                            error={formState.errors?.title ? true : false}
                            {...register('latitude')}
                        />
                        {/* <span>{errors.latitude && 'Lattitude is required'}</span> */}
                        <div className="valid-feedback">{'Looks good!'}</div>
                    </FormGroup>
                </Col>
                <Col md="6 mb-3">
                    <FormGroup>
                        <Label className="col-form-label">{'Longitude*'}</Label>
                        <input className="form-control" type="Number" placeholder="Longitude" name="longitude"
                            error={formState.errors?.title ? true : false}
                            defaultValue={editrow?.longitude}
                            {...register('longitude')}
                        />
                        {/* <span>{errors.longitude && 'Longitude is required'}</span> */}
                        <div className="valid-feedback">{'Looks good!'}</div>
                    </FormGroup>
                </Col>
            </Row>
            <Row>
                <Col md="6 mb-3">
                    <FormGroup>
                        <Label className="col-form-label" for="message-text">{'address*'}</Label>
                        <textarea className="form-control" name="address" id="message-text" placeholder="Alamat" defaultValue={editrow?.address} {...register('address', { required: true })} />

                    </FormGroup>
                </Col>
                <Col md="6 mb-3">
                    <FormGroup>
                        <Label className="col-form-label" for="message-text">{'Deskripsi (opsional):'}</Label>
                        <textarea className="form-control" name="description" id="message-desc" placeholder="Deksripsi" defaultValue={editrow?.description.String} {...register('deskripsi', { required: true })} />
                    </FormGroup>
                </Col>
            </Row>
            {/* <FormGroup>
                <Label className="col-form-label" for="message-text">{'Level Area:'}</Label>
                <Input type="select" className=" form-select" required>
                    <option value="">{'Open this select menu'}</option>
                    <option value="1">{'One'}</option>
                    <option value="2">{'Two'}</option>
                    <option value="3">{'Three'}</option>
                </Input>
                <div className="invalid-feedback">{'Example invalid custom select feedback'}</div>
            </FormGroup> */}
        </>
    )
}

export default EditFormSubarea