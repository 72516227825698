import React from 'react'
import { useParams } from "react-router";
import { Card, CardBody, CardHeader, Col, Collapse } from "reactstrap";
import {CompanyInformation} from "../../../Constant";
import { Btn, H4, H5, H6, Image } from "../../../AbstractElements";
import { useState } from "react";
import { dynamicImage } from "../../../Services";
import AddAreaModal from './AddAreaModal';
const ClientProfileCard = ({refresh, setRefresh}) => {
  
    const { id } = useParams(); // id as Client Id
    const [isProfile, setisProfile] = useState(true);
    const [modal, setModal] = useState(false);
    const toggle = () => setModal(!modal);

    return (
      <Col xl="12">
        <Card>
          <CardHeader>
            <H5 attrH5={{ className: "mb-0" }}> <Btn attrBtn={{ color: "link ps-0", onClick: () => setisProfile(!isProfile),}}>{CompanyInformation}</Btn></H5>
          </CardHeader>
          <Collapse isOpen={isProfile}>
            <CardBody className="socialprofile filter-cards-view">
              <div className="d-flex">
                <Image attrImage={{ className: "img-50 img-fluid m-r-20 rounded-circle", src: dynamicImage("user/1.jpg"), alt: "Image",}}/>
                <div>
                  <H6 attrH6={{ className: "font-primary f-w-600" }}>Nama Client</H6>
                  <span className="d-block">
                    <span><i className="fa fa-map-marker"> </i><span className="px-2">Jumlah Area : <span className="badge rounded-pill badge-light ms-1">2</span></span></span>
                  </span>
                  <span className="d-block">
                    <span><i className="fa fa-bell-o"></i><span className="px-2">Jumlah Subarea <span className="badge rounded-pill badge-light ms-1">{"10"}</span></span>
                    </span>
                  </span>
                </div>
              </div>
              <div className="social-btngroup d-flex">
                <Btn attrBtn={{ color: "primary text-center me-2", type: "button",  onClick: toggle }}>Tambah Area</Btn>
                <AddAreaModal modal={modal} NewMessage="Tambah Area Baru" toggle={toggle} clientId = {id}  refresh = {refresh} setRefresh = {setRefresh} />
              </div>
              <div className="likes-profile text-center"><H4><i className="fa fa-heart font-danger"></i> {"Kontak Resmi"}</H4></div>
              <div className="text-center">{"35 New Likes This Week"}</div>
              <div className="customers text-center social-group">
               
              </div>
            </CardBody>
          </Collapse>
        </Card>
      </Col>
    );

}

export default ClientProfileCard