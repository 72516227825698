import { GoogleMap, LoadScript, Polygon } from '@react-google-maps/api'
import React, { Fragment, useContext, useEffect, useRef, useState, useCallback } from 'react';
import AreaContext from '../../../Helper/area';
import { Btn } from '../../../AbstractElements';
import { Cancel, Save } from '../../../Constant';
import { useForm } from 'react-hook-form';
import { Form, FormGroup, Label, Modal, ModalBody, ModalHeader, Row, Col, Container, Input, InputGroup } from 'reactstrap';

import EditFormArea from './EditFormArea';
import GeoMaps from './GeoMaps';
import { toast } from 'react-toastify';
import axios from "axios";

const EditAreaModal = () => {
  const { updatearea, editrow, editModal, seteditModal } = useContext(AreaContext);
  const polygonRef = useRef(null);
  const listenersRef = useRef([]);
  const [coordinates, setCoordinates] = useState()
  const [color, setColor] = useState('Blue')
  const [editColor, setEditColor] = useState(color)
  const [search, setSearch] = useState('');
  const [mapLocation, setLocation] = useState();
  const apiBaseUrl = process.env.REACT_APP_API_BASE_URL;
  let colorArray = ['Red', 'Green', 'Yellow', 'Blue', 'Pink', 'Orange'];
  let points = []
  if (editrow.coordinate) {
    points = JSON.parse(editrow.coordinate.String)
  }
  // untuk geofencing baru
  const [state, setState] = useState([])
  const { paths } = state

  let centerDefault = {
    lat: -6.9174639,
    lng: 107.6191228
  }
  const editToggle = () => {
    seteditModal(!editModal);
  };

  const { register, handleSubmit, formState } = useForm();

  useEffect(() => {
    if (editrow.length > 0 && editrow.coordinate) {
      points = JSON.parse(editrow.coordinate.String)
    }
  }, [editrow.id])

  console.log(points);
  const [point, setPoint] = useState(points)

  var new_path = JSON.stringify(point);
  const center = points[0] ?? mapLocation;
  // Call setPath with new edited path
  const onEdit = useCallback(() => {
    if (polygonRef.current) {
      const nextPath = polygonRef.current
        .getPath()
        .getArray()
        .map(latLng => {
          return { lat: latLng.lat(), lng: latLng.lng() };
        });
      setPoint(nextPath);
      setEditColor(editColor)
      setColor(editColor)

    }
  }, [setPoint, color]);

  // Bind refs to current Polygon and listeners
  const onLoad = useCallback(
    polygon => {
      setPoint(points)
      polygonRef.current = polygon;
      const path = polygon.getPath();
      listenersRef.current.push(
        path.addListener("set_at", onEdit),
        path.addListener("insert_at", onEdit),
        path.addListener("remove_at", onEdit)
      );
    },
    [onEdit, points, setPoint]
  );
  // Clean up refs
  const onUnmount = useCallback(() => {
    listenersRef.current.forEach(lis => lis.remove());
    polygonRef.current = null;
  }, []);

  // console.log('editColor',editColor)

  const refreshPage = () => {
    window.location.reload()
  }

  const onSearchMap = async () => {
    let places = '';
    if (search == '') {
      places = 'Bandung'
    } else {
      places = search
    }

    await fetch(`https://maps.googleapis.com/maps/api/geocode/json?address="${places}"&key=${process.env.REACT_APP_GOOGLEAPI}`)
      .then(resp => resp.json())
      .then(data => {
        // console.log(data)
        //so to get the gemotry location:
        setLocation(data.results[0].geometry.location)

      }
      )
  };

  const Updatenewarea = (id, data, image_url) => {
    updatearea(id, data, image_url);
  };

  const Updatearea = async (data) => {
    console.log(data, new_path)
    if (data !== '') {
      // Updatenewarea(editrow.id, data, 'lightgallry/01.jpg');
      let body = JSON.stringify({
        "name": data.namaArea,
        "description": data.desc,
        "client_id": editrow?.client_id,
        "coordinate": new_path
      });
      try {


        let config = {
          method: 'post',
          maxBodyLength: Infinity,
          url: `${apiBaseUrl}/areas/update/${editrow?.id}`,
          headers: {
            'Content-Type': 'application/json'
          },
          data: body
        };

        const response = await axios.request(config)
          .then((response) => {
            console.log(JSON.stringify(response))
            toast.success("Berhasil Menyimpan Data Area.");
               // seteditModal(!editModal);
          })
          .catch((error) => {
            console.log(error);
          });


      } catch (error) {
        console.error('Error fetching search results:', error)
        toast.error("Gagal Menyimpan Data Area.")
      }
   
    } else {
      toast.error("Data Wajib DIiisi.")
    }
       seteditModal(!editModal);
  };

  const handleColorChange = (e) => setColor(e.target.value)

  const handleSearchChange = (e) => setSearch(e.target.value)
  return (
    <Fragment>
      <Modal isOpen={editModal} toggle={editToggle} size="xl">
        <ModalHeader toggle={editToggle}>
          Edit Area
        </ModalHeader>
        <ModalBody>
          <Container fluid={true}>
            <Form className="form-bookmark needs-validation" onSubmit={handleSubmit(Updatearea)} >
              <Row>
                <Col sm="12" xl="8">
                  <Row className="mb-2">
                    <Col md="3">
                      <select className="form-select" value={color} onChange={handleColorChange}>
                        <option>Select</option>
                        {
                          colorArray.map((item, index) =>
                            <option key={index}>{item}</option>
                          )
                        }
                      </select>
                    </Col>
                    <Col md="9">
                      <Row>
                        <Col md="7">
                          <InputGroup>
                            <Input type="text" placeholder="Search......." onChange={handleSearchChange} />
                            <span className="input-group-text">
                              <i className="fa fa-search"></i>
                            </span>
                          </InputGroup>
                        </Col>
                        <Col md="4">
                          <Btn attrBtn={{ color: 'success', type: 'button', onClick: onSearchMap }} className="mt-0"> Cari</Btn>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                  {
                    points.length > 1
                      ?
                      <LoadScript
                        id="script-loader"
                        googleMapsApiKey={process.env.REACT_APP_GOOGLEAPI}
                        language="id"
                        region="id"
                      >

                        <GoogleMap
                          mapContainerClassName="appmap"
                          center={center ?? centerDefault}
                          zoom={12}
                        >
                          <Polygon
                            path={point}
                            editable
                            onMouseUp={onEdit}
                            onLoad={onLoad}
                            onUnmount={onUnmount}
                            options={{
                              fillColor: editColor ? editColor : color,
                              strokeColor: `#0a6ebd`,
                              fillOpacity: 0.5,
                              strokeWeight: 2
                            }}
                          />
                        </GoogleMap>

                      </LoadScript>
                      :
                      <>
                        <h6>Belum ada lokasi yang ditambahkan. Silahkan buat area didalam maps.</h6>
                        <GeoMaps
                          apiKey={process.env.REACT_APP_GOOGLEAPI}
                          center={centerDefault}
                          paths={paths}
                          point={paths => setState({ paths })}
                          color={color}
                          setColor={setColor}
                        />
                      </>
                  }
                </Col>
                <Col sm="12" xl="4">
                  <div className="form-row">
                    <EditFormArea editrow={editrow} register={register} formState={formState} />
                  </div>
                </Col>
              </Row>
              {/* {editrow.coordinate ?
                <p>{editrow.coordinate.String}</p>

                :
                <p>Add Coordinate</p>
              } */}
              <Btn attrBtn={{ color: 'primary', type: 'submit' }}>{Save}</Btn>&nbsp;&nbsp;
              <Btn attrBtn={{ color: 'secondary', onClick: editToggle }}>{Cancel} </Btn>
            </Form>
          </Container>
        </ModalBody>
      </Modal>
    </Fragment>
  );
};
export default EditAreaModal;