import { Outlet, Link, useLocation, useNavigate , Navigate} from "react-router-dom"
import { useEffect, useRef, useState } from 'react'
import { useRefreshTokenMutation } from "./authApiSlice"
import usePersist from "../hooks/usePersist"
import { useSelector } from 'react-redux'
import { selectCurrentToken } from "./authSlice"
import Cookies from 'js-cookie'

const PersistLogin = () => {
    // const navigate = useNavigate
    const [persist] = usePersist()
    const token = useSelector(selectCurrentToken)
    const effectRan = useRef(false)
    const location = useLocation()
    const [trueSuccess, setTrueSuccess] = useState(false)
    const refreshTokenData = Cookies.get('refreshToken')

    const [refresh, {
        isUninitialized,
        isLoading,
        isSuccess,
        isError,
        error
    }] = useRefreshTokenMutation()


    useEffect(() => {

        if (effectRan.current === true ) { // React 18 Strict Mode

            const verifyRefreshToken = async () => {
                console.log('verifying refresh token')
                try {
                    const userData = await refresh({tokenParam: refreshTokenData}).unwrap()
                    console.log(userData)
                    // console.log(response)
                    //const { accessToken } = response.data
                    setTrueSuccess(true)
                }
                catch (err) {
                    console.error(err)
                }
            }

            if (!token && persist) verifyRefreshToken()
        }

        return () => effectRan.current = true

        // eslint-disable-next-line
    }, [])


    let content =<Outlet />
    if (!persist) { // persist: no
        console.log('no persist')
        content = <Outlet />
    } else if (isLoading) { //persist: yes, token: no
        console.log('loading')
        content = <Outlet />
        // content = <p>Loading data nih...</p>
    } else if (isError) { //persist: yes, token: no
        console.log('error')
        // setTimeout(() => {
        //     <Navigate to="/login" state={{ from:location}} replace/>
        //   }, 3000);
        content = (
            <p className='errmsg'>
                {error.data?.message}
                <Navigate to={`${process.env.PUBLIC_URL}/login`} state={{ from:location}} replace/>
            </p>
        )
       
    } else if (isSuccess && trueSuccess) { //persist: yes, token: yes
        console.log('success')
        content = <Outlet />
    } else if (token && isUninitialized) { //persist: yes, token: yes
        console.log('token and uninit')
        console.log(isUninitialized)
        content = <Outlet />
    }

    return content
}
export default PersistLogin