import cubaimg from "../../../assets/images/company/MAS57.png";
import { Col, Card, Table, Row, ButtonGroup } from "reactstrap";
import SkeletonReportPage from "./SkeletonReportPage";
import { format } from 'date-fns';
import id from "date-fns/locale/id";

const ReportPdfPatrol = ({ isloadingData, searchResults,startDate }) => {

    // const createPDF = async () => {
    //   const pdf = new jsPDF("portrait", "pt", "a4");
    //   const data = await document.querySelector("#pdf");
    //   pdf.html(data).then(() => {
    //     pdf.save("shipping_label.pdf");
    //   });
    // };\
    let content = ""
    if (isloadingData) {
        content = <SkeletonReportPage />
    } else {
        // content = <SkeletonReportPage />
        // console.log(searchResults.data.length)
        if (searchResults) {
            if (searchResults.data != null) {

                content = searchResults.data.map((item, i) => <tr key={i}>
                    <th scope="row">{i + 1}</th>
                    <td>{item.officer_name}</td>
                    <td>{format(new Date(item.arrival_on), 'dd MMMM yyyy HH:mm:ss', { locale: id })}</td>
                    <td>{item.area_name} - {item.subarea_name}</td>
                    <td>{item.subarea_name}</td>
                    <td>{item.description.Valid ? (item.description.String != "" ? item.description.String : "Tidak Ada Keterangan") : "Tidak Ada Keterangan"}</td>
                </tr>
                )
            } else {
                content = <>
                    <tr className="text-center">
                        <td colSpan={6}>
                            Data yang dimaksud tidak ditemukan.
                        </td>
                    </tr>
                </>
            }
        } else {
            content = <>
                <tr className="text-center">
                    <td colSpan={6}>
                        Data yang dimaksud tidak ditemukan.
                    </td>
                </tr>
            </>
        }
    }


    const textStyle = {
        textDecoration: "underline",
        fontWeight: "bold",
    };

    return (
        <div id="pdf" >
            <div>
                <header >
                    <div className="row  m-r-5 ">
                        <div id="img" className="col-md-3 m-t-20">
                            <img id="logo" src={cubaimg} width="100" height="100" />
                        </div>
                        <div id="text-header" className="col-md-7 align-items-center">
                            <center>
                                <p className="f-14 header-pdf"><b>PT. MITRA ABADI SETIA 57</b></p>
                                <p className="f-12">Jl. Margahurip Indah No. 304, RT.01/RW.10, Margahurip, Kec. Banjaran, Kabupaten Bandung.</p>
                                <p className="f-12">Telepon (022) 85933911</p>
                            </center>
                        </div>
                    </div>
                </header>

                <div className="container m-l-5">
                    <hr className="garis1" />
                    <div id="alamat" className="row">
                        <div id="lampiran" className="col-md-6">
                            Nomor	: 005 / <br />
                            Lampiran	: - <br />
                            Perihal	: Laporan Patroli Rutin {startDate ? 'Tanggal '+ format(startDate, 'dd MMMM yyyy', { locale: id }) : ''}
                        </div>
                        <div id="tgl-srt" className="col-md-6">
                            <p id="tls">Bandung, { format(new Date(), 'dd MMMM yyyy', { locale: id }) }</p>

                            {/* <p className="alamat-tujuan">Kepada Yth. :<br />
                                Kepala Desa</p>

                            <p className="alamat-tujuan">se - Kecamatan Bergas
                            </p> */}
                        </div>
                    </div>
                    <br />
                    <div id="pembuka" className="row">
                        <div className="table-responsive theme-scrollbar f-12">
                            <Table className="table">
                                <thead>
                                    <tr className="dashed">
                                        <th scope="col" class="w-0"> {"#"}</th>
                                        <th scope="col" class="w-5">{'Petugas'}</th>
                                        <th scope="col" class="w-5">{'Tanggal'}</th>
                                        <th scope="col" class="w-25">{'Area'}</th>
                                        <th scope="col" >{'Subarea'}</th>
                                        <th scope="col" >{'Keterangan'}</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {searchResults ?
                                        content :
                                        <p>Tidak Ada Data Patroli.</p>}
                                </tbody>
                            </Table>
                        </div>
                    </div>
                    <div id="penutup" className="m-t-5">
                        Demikian untuk menjadikan perhatian dan atas kehadirannya diucapkan terimakasih.
                    </div>
                    <div id="ttd" className="row">
                        <div className="col-md-4"></div>
                        <div className="col-md-4"></div>
                        <div className="col-md-4 text-end">
                            <p id="camat" className="f-12 text-end"><strong>Bandung, { format(new Date(), 'dd MMMM yyyy', { locale: id }) }</strong></p>
                            {/* <p id="camat"><strong>CAMAT BERGAS</strong></p>
                            <div id="nama-camat"><strong><u>TRI MARTONO, SH, MM</u></strong><br />
                                Pembina Tk. I<br />
                                NIP. 196703221995031001</div> */}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );

};

export default ReportPdfPatrol;