import React, { Fragment } from 'react'
import { Card, CardBody, Container, Input, InputGroup } from 'reactstrap'
import { Breadcrumbs,  H4 } from '../../AbstractElements'
import GuestCard from './GuestCard'


const Guest = () => {
  return (
    <Fragment>
    <Breadcrumbs mainTitle="Data Tamu" parent="Home" title="Data Tamu" />
    <Container fluid={true}>
      <div className="row">
        <div className="col-sm-12">
          <Card>
            <div className="card-header pb-0">
              <H4>Semua Data Tamu</H4>
            </div>
            <CardBody>
              <div className="social-tab">
                <InputGroup>
                  <Input type="text" placeholder="Cari Data....." />
                  <span className="input-group-text">
                    <i className="fa fa-search"></i>
                  </span>
                </InputGroup>
              </div>
            </CardBody>
          </Card>
        </div>
        <div className="col-sm-12">
          <GuestCard />
        </div>
      </div>
    </Container>
  </Fragment>
  )
}

export default Guest