import React,{ useCallback, useContext, useState }  from 'react'
import { Col, Card, Row} from "reactstrap";
import { tableColumns } from "../../Data/DataTable/guestData";
import DataTable from "react-data-table-component";
import GuestContext from '../../Helper/guest';
const GuestCard = () => {
    const [selectedRows, setSelectedRows] = useState([]);
    const [toggleDelet, setToggleDelet] = useState(false);
    // const [data, setData] = useState;
    const { guest, editModal, seteditModal ,getSubareaById} = useContext(GuestContext);
    // add modal 
    const [modal, setModal] = useState(false);
    const toggle = () => setModal(!modal);

    const handleRowSelected = useCallback(state => {
        setSelectedRows(state.selectedRows);
    }, []);

   
    const editSubareaToggle = () => {
        seteditModal(!editModal);
    };
    const handleEditClick = (event) => {
        var idguests = event.currentTarget.id;
        getSubareaById(idguests);
        
        // setEditrow(state.target.id);
        editSubareaToggle();
      };
    return (
        <Card className="mb-2">
            <Row className="card-block">
                <Col sm="12" lg="12" xl="12">
                    <div className="table-responsive theme-scrollbar">
                        <DataTable
                            className='data-tables theme-scrollbar'
                            data={guest}
                            columns={tableColumns}
                            striped={true}
                            center={true}
                            pagination
                            selectableRows
                            onSelectedRowsChange={handleRowSelected}
                            clearSelectedRows={toggleDelet}
                        />
                    </div>
                </Col>
            </Row>
        </Card>
    )
}

export default GuestCard