import memoize from 'memoize-one';
export const dummytabledata = [
    {
        id: "198983",
        name: "Check Point A",
        coordinate: "(-92.298302, 83.983292)",
        description: "Aaron Chapman"
    },
    {
        id: "345346",
        name: "Check Point B",
        coordinate: "(-92.298302, 83.983292)",
        description: "Donna Wilson"
    },
    {
        id: "342642",
        name: "Check Point C",
        coordinate: "(-92.298302, 83.983292)",
        description: "Randy Porter"
    },
    {
        id: "234532",
        name: "Check Point D",
        coordinate: "(-92.298302, 83.983292)",
        description: "Aaron Chapman"
    },
    {
        id: "229783",
        name: "Check Point E",
        coordinate: "(-92.298302, 83.983292)",
        description: "Adam Stewart"
    }
];
export const tableColumns = memoize(clickHandler => [
    {
        name: 'Id',
        selector: row => row['id'],
        sortable: true,
        center: true,
    },
    {
        name: 'Name',
        selector: row => row['name'],
        sortable: true,
        center: false,
    },
    {
        name: 'Coordinate',
        cell: (row) => <span> {row.latitude}, {row.longitude}</span>,
        sortable: true,
        center: true,
    },
    {
        name: 'Description',
        selector: row => `${row.description.String}`,
        sortable: true,
        center: true,
    },
    {
        name: 'Aksi',
        cell: (row) => 
        // <ButtonGroup>
        //     <Btn attrBtn={{ color: "warning", size: "sm", onClick: clickHandler, id: row.id }}>
        //         <i className={`fa fa-pencil-square-o `}></i>
        //     </Btn>
        // </ButtonGroup>
        <button onClick={clickHandler} id={row.id}  className="btn btn-primary"><i className={`fa fa-pencil-square-o `}></i></button>,
        ignoreRowClick: true,
        allowOverflow: true,
        button: true,
    },

]);