import { useLocation, Navigate, Outlet } from "react-router-dom"
import { useEffect, useRef, useState } from 'react'
import { useSelector } from "react-redux"
import { selectCurrentToken } from "../../Services/authSlice"
import { useRefreshTokenMutation } from "../../Services/authApiSlice"
import usePersist from "../..//hooks/usePersist"
import Cookies from 'js-cookie'
const RequireAuth = () => {
     // const navigate = useNavigate
     const [persist] = usePersist()
    const token = useSelector(selectCurrentToken)
    const location = useLocation()
    const effectRan = useRef(false)
    const [trueSuccess, setTrueSuccess] = useState(false)
    const refreshTokenData = Cookies.get('refreshToken')
    const access_token = Cookies.get('access_token')
  
    const [refresh, {
        isUninitialized,
        isLoading,
        isSuccess,
        isError,
        error
    }] = useRefreshTokenMutation()


    useEffect(() => {

        // if (effectRan.current === true ) { // React 18 Strict Mode

            const verifyRefreshToken = async () => {
                console.log('verifying refresh token')
                try {
                    const userData = await refresh({tokenParam: refreshTokenData}).unwrap()
                    console.log(userData)
                    //const { accessToken } = response.data
                    setTrueSuccess(true)
                }
                catch (err) {
                    console.error(err)
                }
            }

            if (!token && persist) verifyRefreshToken()
        // }

        return () => effectRan.current = true

        // eslint-disable-next-line
    }, [])

    console.log(token)
    // ${process.env.PUBLIC_URL}
    let content =<Outlet />
    if (refreshTokenData && access_token){     
        if (!persist) { // persist: no
            console.log('no persist')
            content =  token ? <Outlet/> : <Navigate to={`${process.env.PUBLIC_URL}/login`} state={{ from:location}} replace/>
        } else if (isLoading) { //persist: yes, token: no
            console.log('loading')
            content = <Outlet />
            // content = <p>Loading data nih...</p>
        } else if (isError) { //persist: yes, token: no
            console.log('error')
            // setTimeout(() => {
            //     <Navigate to="/login" state={{ from:location}} replace/>
            //   }, 3000);
            content = (
                <p className='errmsg'>
                    {error.data?.message}
                    <Navigate to={`${process.env.PUBLIC_URL}/login`} state={{ from:location}} replace/>
                </p>
            )
        
        } else if (isSuccess && trueSuccess) { //persist: yes, token: yes
            console.log('success')
           
            content =  <Outlet/>
        } else if (token && isUninitialized) { //persist: yes, token: yes
            console.log('token and uninit')
            console.log(isUninitialized)
            content = <Outlet />
        }
    }else{
        content  = <Navigate to={`${process.env.PUBLIC_URL}/login`} state={{ from:location}} replace/>
    }

    return content

    // return (
    //     token
    //         ? content
    //         : <Navigate to={`${process.env.PUBLIC_URL}/login`} state={{ from:location}} replace/>
            
    //         // <Navigate to={`${process.env.PUBLIC_URL}/login`} state={{ from:location}} replace/>
    // )
}

export default RequireAuth