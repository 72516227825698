import Sample from "../Components/Sample";
import SampleCard from "../Components/SampleCard";
import Dashboard from "../Components/Dashboard";
import Clients from "../Components/Clients";
import ClientsDetail from "../Components/Clients/ClientsDetail";
import Guest from "../Components/Guest";

import Logistic from "../Components/Logistic";
import Setting from "../Components/Setting";
import Patrol from "../Components/Patrol";
import Subarea from "../Components/Clients/Subarea";

// Guard Component
import Guards from "../Components/Guards";
import GuardsEdit from "../Components/Guards/GuardsEdit";
import AddNewGuard from "../Components/Guards/GuardsAdd";
import GuardSchedule from "../Components/Guards/Schedule";
import ReportPage from "../Components/Patrol/Cards/ReportPage";
export const routes = [
  { path: `${process.env.PUBLIC_URL}/samplepage`, Component: <SampleCard /> },
  { path: `${process.env.PUBLIC_URL}/sample`, Component: <Sample /> },
  { path: `${process.env.PUBLIC_URL}/dashboard`, Component: <Dashboard /> },
  { path: `${process.env.PUBLIC_URL}/client`, Component: <Clients /> },
  { path: `${process.env.PUBLIC_URL}/client/:id/client-detail`, Component: <ClientsDetail /> },
  { path: `${process.env.PUBLIC_URL}/client/:client_id/client-detail/:id/subarea`, Component: <Subarea /> },
  { path: `${process.env.PUBLIC_URL}/guest`, Component: <Guest /> },
  // Guard Route
  { path: `${process.env.PUBLIC_URL}/guard`, Component: <Guards /> },
  { path: `${process.env.PUBLIC_URL}/guard/:id`, Component: <GuardsEdit /> },
  { path: `${process.env.PUBLIC_URL}/guard/addNewGuard`, Component: <  AddNewGuard /> },
  { path: `${process.env.PUBLIC_URL}/guard/schedule`, Component: <  GuardSchedule /> },

  { path: `${process.env.PUBLIC_URL}/logistic`, Component: <Logistic /> },
  { path: `${process.env.PUBLIC_URL}/setting`, Component: <Setting/> },
  { path: `${process.env.PUBLIC_URL}/patrol`, Component: <Patrol /> },
  { path: `${process.env.PUBLIC_URL}/patrol/laporan`, Component: <ReportPage /> },
];





