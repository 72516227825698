import { createEntityAdapter } from "@reduxjs/toolkit";
import { apiSlice } from "../app/api/apiSlice"


const areaAdapter = createEntityAdapter()

const initialState = areaAdapter.getInitialState()

export const patrolApiSlice = apiSlice.injectEndpoints({
    endpoints: builder => ({
        getPatrols: builder.query({
            query: ({ limit }) => '/patrol' + limit,
            method: 'GET',
        }),
        getPatrolFilterByName: builder.query({
            query: ({ name }) => '/patrol/hisotryFilterByName?searchTerm=' + name,
            method: 'GET',
        }),
        getListAreaNames: builder.query({
            query: () => '/areas/listAreasName',
            method: 'GET',
        }),
        getListAreaNamesNoLimit: builder.query({
            query: () => '/areas/listAreasNameNoLimit',
            method: 'GET',         
        }),
        getListOfficerNoLimit: builder.query({
            query: () => '/guard/noLimitation',
            method: 'GET',         
        }),

    })
})

export const {
    useGetPatrolsQuery,
    useGetListAreaNamesQuery,
    useGetPatrolFilterByNameQuery,
    useGetListAreaNamesNoLimitQuery,
    useGetListOfficerNoLimitQuery,
} = patrolApiSlice 