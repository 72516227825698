import { ChevronDown } from 'react-feather'
import { LI, UL } from "../../../AbstractElements";
import { Link } from "react-router-dom";
const Pages = () => {
  return (
    <LI attrLI={{ className: "onhover-dropdown" }}>
      <span className="f-w-600">Halaman</span>
      <span><ChevronDown className="middle" /></span>
      <UL attrUL={{ className: "onhover-show-div left-dropdown" }}>
        <LI><Link to={`${process.env.PUBLIC_URL}/setting`}>Setting</Link></LI>
        <LI><Link to={`${process.env.PUBLIC_URL}/patrol`}>Patroli</Link></LI>
        <LI><Link to={`${process.env.PUBLIC_URL}/client`}>Klien</Link></LI>
        <LI><Link to={`${process.env.PUBLIC_URL}/guard`}>Petugas Keamanan</Link></LI>
      </UL>
    </LI>
  );
};

export default Pages;
