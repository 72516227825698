import { createEntityAdapter } from "@reduxjs/toolkit";
import { apiSlice } from "../app/api/apiSlice"


const subareaAdapter = createEntityAdapter()

const initialState = subareaAdapter.getInitialState()

export const subareaApiSlice = apiSlice.injectEndpoints({
    endpoints: builder => ({
        getSubareas: builder.query({
            query: () => '/subarea',
            method: 'GET',
        }),
        getSubareaByAreaId: builder.query({
            query: ({areaId}) => ({
                url: `/areas/${areaId}/subarea`,
                method: 'GET',
            })
        }),
        addNewSubarea: builder.mutation({
            query: initialData => ({
                url: '/subarea',
                method: 'POST',
                body: {
                    ...initialData
                }
            }),
            async onQueryStarted(arg, { dispatch, queryFulfilled }) {
                try {
                    const { data } = await queryFulfilled
                    console.log(data)
                } catch (err) {
                    console.log(err)
                }
            }
        }),
    }) 
})

export const {
    useGetSubareasQuery,
    useGetSubareaByAreaIdQuery,
    useAddNewSubareaMutation
} = subareaApiSlice 