import { useState } from "react";
import { Inbox, LogIn, Settings, User } from "react-feather";
import { Link, useNavigate } from "react-router-dom";
import { LI, UL } from "../../../AbstractElements";
import { Account, LogOut } from "../../../Constant";
import { useSendLogoutMutation } from '../../../features/auth/authApiSlice'
const Users = () => {
  const [toggle, setToogle] = useState(true);
  const history = useNavigate();
  const Logout = () => {
    localStorage.removeItem("profileURL");
    localStorage.removeItem("Name");
    localStorage.removeItem("token");
    history(`${process.env.PUBLIC_URL}/login`);
    localStorage.setItem("login", false);
  };
  const [sendLogout, {
    isLoading,
    isSuccess,
    isError,
    error
  }] = useSendLogoutMutation()
  const Active = () => setToogle(!toggle);

  return (
    <LI attrLI={{ className: `profile-nav onhover-dropdown` }}>
      <div className="account-user"><User onClick={Active} /></div>
      <UL attrUL={{ className: "profile-dropdown onhover-show-div" }}>
        <LI><Link to={`${process.env.PUBLIC_URL}/setting`}><User /><span>{Account}</span></Link></LI>
        <LI attrLI={{ onClick: sendLogout }}>
          {/* <Link to={`${process.env.PUBLIC_URL}/logout`}> */}
            <LogIn /><span>{LogOut}</span>
          {/* </Link> */}
        </LI>
      </UL>
    </LI>
  );
};

export default Users;
