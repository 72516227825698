import { Btn, H4, Image } from '../../../AbstractElements';
import { Dropzone } from 'react-dropzone-uploader';
import { AddGuard, Company, Username, UsersCountryMenu, AboutMe, SimpanDatalGuard, FirstName, LastName, Address, EmailAddress, PostalCode, Country, City } from '../../../Constant';
import React, { Fragment, useState, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { Typeahead } from "react-bootstrap-typeahead";
import { toast } from 'react-toastify';
import { Row, Col, CardHeader, CardBody, CardFooter, Form, FormGroup, Label, Input } from 'reactstrap';
import user from '../../../assets/images/user/7.jpg';
import { useAddNewGuardsMutation } from '../../../Services/securityOfficerApiSlice';
import { useNavigate } from 'react-router';
import axios from "axios";

const AddGuardForm = () => {
  const [addNewGuards, { isLoading }] = useAddNewGuardsMutation()
  const { register, handleSubmit, formState: { errors } } = useForm();
  const { ref, ...sex } = register("sex");
  const regionName = register('region')
  const [regions, setRegions] = useState([])
  const apiBaseUrl = process.env.REACT_APP_API_BASE_URL;
  const navigate = useNavigate()
  const onRegionChanged = (e) => setRegions(e.target.value)


  const handleInputChange = (input, e) => {
    console.log("value", input)
  }

  const handleChange = (selectedOptions) => {
    setRegions(selectedOptions)
    console.log("data : ", selectedOptions);
  }


  const onSaveClicked = async (data) => {

    try {
      // console.log(data.image[0]);
      console.log(
        {
          name: data.name,
          Username: data.username,
          password: data.password,
          phone: data.phone,
          sex: data.sex,
          status_active: "1",
          photo: data.img ? data.img[0] : ""
        }
      );
      // await addNewGuards(
      //   {
      //     name: data.name,
      //     Username: data.username,
      //     password: data.password,
      //     phone: data.phone,
      //     sex: data.sex,
      //     status_active: "1",
      //     photo: data.img ? data.img[0] : ""
      // //     //region: regions, //nanti kalau database udah di update di tambah data region nya untuk mempermudah penjadwalan.
      //   }).unwrap()
      const formData = new FormData();
      formData.append('Name', data.name);
      formData.append('Username', data.username);
      formData.append('Password', data.password);
      formData.append('Phone', data.phone);
      formData.append('Sex', data.sex);
      formData.append('StatusActive', "1");
      formData.append('photo', data.img ? data.img[0] : "");
      const response = await axios.post(`${apiBaseUrl}/guard`, formData,{
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      navigate(`${process.env.PUBLIC_URL}/guard`)
      // setRefresh(true)
      toast.success("Berhasil Menyimpan Data Petugas.");
      console.log(response)
      // toggle()
    } catch (err) {
      toast.error("Gagal Menyimpan Data Petugas.");
      console.error('Failed to save the post', err)
    }
    // console.log(data, regions)
  };
  return (
    <Fragment>
      <Form className="card theme-form" onSubmit={handleSubmit(onSaveClicked)}>
        <CardHeader className="pb-0">
          <H4 attrH4={{ className: 'card-title mb-0' }}>{AddGuard}</H4>
          <div className="card-options">
            <a className="card-options-collapse" href="#javascript">
              <i className="fe fe-chevron-up"></i>
            </a>
            <a className="card-options-remove" href="#javascript">
              <i className="fe fe-x"></i>
            </a>
          </div>
        </CardHeader>
        <CardBody>
          <Row>
            <Col md="12">
              <Label className="form-label">{"Foto Petugas"}</Label>


              <FormGroup>

                {/* <Dropzone
                  className="dropzone digits text-center h-10"
                  maxFiles={1}
                  accept="image/*"
                  multiple={false} height={100}
                  canCancel={false}
                  inputContent={'Upload Foto Petugas'}
                  name="image"  // Update the field value
                  inputWithFilesContent={(files) => `${files.length} file(s) selected`}
                  onChangeStatus={({ meta, file }) => {
                    // if (status === 'done') {
                    // Update the form field value with the uploaded file information
                    register('image').onChange([file]);
                    // }
                  }}
                // Use react-hook-form's register function
                /> */}

                <input className="form-control" type="file" {...register("img", { required: true })} />
              </FormGroup>
            </Col>
            <Col md="4">
              <FormGroup className="mb-3">
                <Label className="form-label">{"Nama Lengkap Petugas"}</Label>
                <input className="form-control" type="text" name="name" placeholder="Nama Petugas" {...register('name', { required: true })} /><span style={{ color: 'red' }}>{errors.name && 'Nama is required'} </span>
              </FormGroup>
            </Col>

            <Col sm="4" md="4">
              <FormGroup>
                <Label className="form-label">{"No Telepon"}</Label>
                <input className="form-control m-input" name="phone" type="tel" placeholder="No Telepon" defaultValue="09879879987" {...register('phone', { required: true })} /><span style={{ color: 'red' }}>{errors.phone && 'No Telepon is required'} </span>
              </FormGroup>

            </Col>
            <Col sm="4" md="4">
              <FormGroup>
                <Label className="form-label">{"Jenis Kelamin"}</Label>
                <Col>
                  <div className="m-t-15 m-checkbox-inline mb-0 custom-radio-ml">
                    <div className="custom-control radio custom-radio radio-primary">
                      <Input className="radio_animated custom-control-input me-2" id="customControlValidation2" type="radio" name="radio-stacked" value="L" innerRef={ref} {...sex} />
                      <Label className="custom-control-label" htmlFor="customControlValidation2">{'Laki-Laki'}</Label>
                    </div>
                    <div className="custom-control radio custom-radio radio-primary mb-3">
                      <Input className="radio_animated custom-control-input me-2" id="customControlValidation3" type="radio" name="radio-stacked" value="P" innerRef={ref} {...sex} />
                      <Label className="custom-control-label" htmlFor="customControlValidation3">{'Perempuan'}</Label>
                      <div className="invalid-feedback">{'More example invalid feedback text'}</div>
                    </div>
                  </div>
                </Col>
              </FormGroup>
            </Col>
            {/* <Col md="5">
              <FormGroup>
                <Label className="form-label">{"Region"}</Label>
                <Typeahead id="multiple-typeahead" labelKey={"items"} multiple options={UsersCountryMenu} placeholder="Pilih Region..." instanceRef={regionName.ref} 
                onInputChange={handleInputChange}
                onChange={handleChange}
                clearButton /> */}
            {/* <Input type="select" name="select" className="form-control btn-square form-select">
                  {UsersCountryMenu.map((items, i) =>
                    <option key={i}>{items}</option>
                  )}
                </Input> */}
            {/* </FormGroup>
            </Col> */}
            <Col md="12">
              <Row>
                <Col sm="6" md="3">
                  <FormGroup>
                    <Label className="form-label">{"Username"}</Label>
                    <input className="form-control" type="text" placeholder="Username" {...register('username', { required: true })} /><span style={{ color: 'red' }}>{errors.Username && 'Username is required'} </span>
                  </FormGroup>
                </Col>
                <Col sm="6" md="4">
                  <FormGroup>
                    <Label className="form-label">{"Password"}</Label>
                    <input className="form-control" type="text" placeholder="Password" {...register('password', { required: true })} /><span style={{ color: 'red' }}>{errors.EmailAddress && 'EmailAddress is required'} </span>
                  </FormGroup>
                </Col>
              </Row>

            </Col>

          </Row>
        </CardBody>
        <CardFooter className="text-end">
          <Btn attrBtn={{ color: 'primary', type: 'submit' }}>{SimpanDatalGuard}</Btn>
        </CardFooter>
      </Form>
    </Fragment>
  );
};
export default AddGuardForm;