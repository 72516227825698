import React, { Fragment,useCallback, useState, } from 'react'
import {Container, Row, TabPane, TabContent } from 'reactstrap'
import { Breadcrumbs} from '../../../AbstractElements'
import DetailArea from './DetailArea';
import RiwayatPatrol from './RiwayatPatrol';
import TimelineTab from './TimelineTab';
import EditAreaModal from './EditArea';

const ClientsDetail = () => {
  const [activeTab, setActiveTab] = useState('1');
  const callback = useCallback((tab) => {
    setActiveTab(tab);
  }, []);
  return (
    <Fragment>
      <Breadcrumbs mainTitle="Detail Client" parent="Client" title="Detail Client" />
      <Container fluid={true}>
        <div className="user-profile social-app-profile">
          <TimelineTab callbackActive={callback} />
          <TabContent activeTab={activeTab} className="tab-content">
            <TabPane tabId="1">
              <DetailArea />
            </TabPane>
            <TabPane tabId="2">
              <RiwayatPatrol />
            </TabPane>
            <EditAreaModal/>
          </TabContent>
        </div>
      </Container>
    </Fragment>
  )
}

export default ClientsDetail