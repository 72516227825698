import Context from "./index";
import React, { useEffect, useState } from "react";
import axios from "axios";
import { GuestsApi } from "../../api";

const GuestProvider = (props) => {
  const [guest, setGuest] = useState([]);
  const [myGuestData, setMyGuestData] = useState([]);
  const [activeTabss, setActiveTabss] = useState("1");
  const [editrow, setEditrow] = useState({});
  const [editimgurl, setEditImgUrl] = useState();
  const [gridView, setgridView] = useState(true);
  const [editModal, seteditModal] = useState(false);
  const [bookMarkClass, setBookMarkClass] = useState(false);
  useEffect(() => {
    const getGuestdata = async () => {
      try {
        await axios.get(GuestsApi).then((resp) => {
          setGuest(resp.data);
        });
      } catch (error) {
        console.log("cancelled", error);
      }
    };
    getGuestdata();
  }, [setGuest]);

  const addNewGuest = (guestData) => {
    const guestTemp = {
      id: guest.length + 1,
      name: guestData.name,
      created_at: "20-05-2023",
      updated_at: "20-05-2023",
      created_by: "1",
      updated_by: "1"
    };
    setGuest((prev) => [...prev, guestTemp]);
  };

  const updateguest = (id, updatedData) => {
    const guestTemp = {
      id: id,
      name: updatedData.name,
      created_at: "20-05-2023",
      updated_at: "20-05-2023",
      created_by: "1",
      updated_by: "1"
    };
    setGuest(guest.map((item) => (item.id === id ? guestTemp : item)));
  };

  const removeguest = (id) => {
    setGuest(guest.filter((data) => data.id !== id));
  };

  const myguest = (myGuestData) => {
    setMyGuestData((prev) => [...prev, myGuestData]);
  };

  const updateMyguest = (id, updateMydata) => {
    const guestTemp = {
      id: id,
      name: updateMydata.name,
      description: updateMydata.desc,
      client_id: 1,
      created_at: "20-05-2023",
      updated_at: "20-05-2023",
      created_by: "1",
      updated_by: "1"
    };
    setMyGuestData(
      myGuestData.map((item) => (item.id === id ? guestTemp : item))
    );
  };

  const removemyguest = (id) => {
    setMyGuestData(myGuestData.filter((data) => data.id !== id));
  };

  return (
    <Context.Provider
      value={{
        ...props,
        guest,
        myGuestData,
        activeTabss,
        editrow,
        editimgurl,
        editModal,
        gridView,
        bookMarkClass,
        setBookMarkClass: setBookMarkClass,
        setgridView,
        seteditModal: seteditModal,
        setEditrow: setEditrow,
        setActiveTabss: setActiveTabss,
        setEditImgUrl: setEditImgUrl,
        myguest: myguest,
        updateguest: updateguest,
        removeguest: removeguest,
        addNewGuest: addNewGuest,
        updateMyguest: updateMyguest,
        removemyguest: removemyguest,
      }}
    >
      {props.children}
    </Context.Provider>
  );
};

export default GuestProvider;
