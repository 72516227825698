import { createEntityAdapter } from "@reduxjs/toolkit";
import { apiSlice } from "../app/api/apiSlice"


const areaAdapter = createEntityAdapter()

const initialState = areaAdapter.getInitialState()

export const securityOfficerApiSlice = apiSlice.injectEndpoints({
    endpoints: builder => ({
        getGuards: builder.query({
            query: () => '/guard',
            method: 'GET',
        }),
        getGuardsById: builder.query({
            query: ({guardId}) => ({
                url: `/guard?userId=${guardId}`,
                method: 'GET',
                keepUnusedDataFor: 0,
                providesTags: (result, error, arg) => [
                    { type: 'Guard', id: "LIST" },
                    ...result.data.map(id => ({ type: 'Guard', id }))
                    // console.log(result)
                ]
            })
        }),
        getGuardsByofficerId: builder.query({
            query: ({guardId}) => ({
                url: `/guard/${guardId}`,
                method: 'GET',
                keepUnusedDataFor: 0
            })
        }),
        addNewGuards: builder.mutation({
            
            query: initialData => ({
                url: '/guard',
                method: 'POST',
                body: {
                    ...initialData
                }
            }),
            async onQueryStarted(arg, { dispatch, queryFulfilled }) {
                try {
                    const { data } = await queryFulfilled
                    console.log(data)
                } catch (err) {
                    console.log(err)
                }
            }
        }),
    })
})

export const {
    useGetGuardsQuery,
    useGetGuardsByIdQuery,
    useAddNewGuardsMutation,
    useGetGuardsByofficerIdQuery,
} = securityOfficerApiSlice 