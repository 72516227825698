import React, { useEffect, useState } from 'react'
import { formatDate } from '@fullcalendar/core'
import FullCalendar from '@fullcalendar/react'
import dayGridPlugin from '@fullcalendar/daygrid'
import timeGridPlugin from '@fullcalendar/timegrid'
import interactionPlugin from '@fullcalendar/interaction'
import idLocale from '@fullcalendar/core/locales/id';
// import { INITIAL_EVENTS, CreateEventId } from '../../../Data/Calendar/event-utils'
import { Card, CardBody } from 'reactstrap'
import { useGetSchedulesForCalendarQuery } from '../../../Services/scheduleOfficerApiSlice'
import AddScheduleModal from './AddScheduleModal'
import { useDispatch } from 'react-redux'
const Schedule = () => {
    const [weekendsVisible, setWeekendsVisible] = useState(true);
    const [currentEvents, setCurrentEvents] = useState([]);
    const [modal, setModal] = useState(false);
    const [refresh, setRefresh] = useState(false);
    const [selectionDate, SetSelectionDate] = useState();
    const [events, setEvents] = useState()
    const toggle = () => setModal(!modal);
    const dispatch = useDispatch();

    const {
        data: schedule,
        isLoading,
        isSuccess,
        isError,
        error,
        refetch
    } = useGetSchedulesForCalendarQuery('getSchedulesForCalendar')
    
    useEffect(() => {
        dispatch(refetch);
        setRefresh(false);
        (isSuccess) ? setEvents(schedule.data) : setEvents(null)
      }, [refresh, dispatch]);

    const RenderEventContent = (eventInfo) => {
        return (
            <>
                <b>{eventInfo.timeText}</b>
                <i>{eventInfo.event.title}</i>
            </>
        )
    }

    const HandleWeekendsToggle = () => {
        setWeekendsVisible(!weekendsVisible)

    }

    const HandleDateSelect = (selectInfo) => {
        toggle() 
        SetSelectionDate(selectInfo)
        // let title = prompt('Please enter a new title for your event')
        let title = 'test'
        let calendarApi = selectInfo.view.calendar

        calendarApi.unselect() // clear date selection

        // if (title) {
        //     calendarApi.addEvent({
        //         id: CreateEventId(),
        //         title,
        //         start: selectInfo.startStr,
        //         end: selectInfo.endStr,
        //         allDay: selectInfo.allDay
        //     })
        // }
    }

    const HandleEventClick = (clickInfo) => {
        if (window.confirm(`Are you sure you want to delete the event '${clickInfo.event.title}'`)) {
            clickInfo.event.remove()
        }
    }

    const HandleEvents = (events) => {
        setCurrentEvents(events);
    }


    

    return (
        <div className='col-sm-12'>
            <Card>
                <CardBody>
                    {
                        isLoading ?
                            <p>Mengambil Data ...</p>
                            : isSuccess ?
                                <FullCalendar
                                    locale={idLocale}
                                    plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
                                    headerToolbar={{
                                        left: 'prev,next today',
                                        center: 'title',
                                        right: 'dayGridMonth,timeGridWeek,timeGridDay'
                                    }}
                                    initialView='dayGridMonth'
                                    editable={true}
                                    selectable={true}
                                    selectMirror={true}
                                    dayMaxEvents={true}
                                    weekends={weekendsVisible}
                                    events={schedule.data}
                                    // initialEvents={schedule.data} // alternatively, use the `events` setting to fetch from a feed
                                    select={HandleDateSelect}
                                    eventContent={RenderEventContent} // custom render function
                                    eventClick={HandleEventClick}
                                    eventsSet={HandleEvents} // called after events are initialized/added/changed/removed
                                    // events={events}
                                /* you can update a remote database when these fire:
                                eventAdd={function(){}}
                                eventChange={function(){}}
                                eventRemove={function(){}}
                                */
                                /> :
                                <p>Tidak Ada Jadwal</p>

                    }

                </CardBody>
                <AddScheduleModal modal={modal} NewMessage="Tambah Area Baru" toggle={toggle} selectInfo = {selectionDate} refresh = {refresh} setRefresh = {setRefresh} />
            </Card>
        </div>
    )
}

export default Schedule