import React, { Fragment } from 'react';
import { Form, FormGroup, Input } from 'reactstrap';

const SearchBar = ({ searchTerm, setSearchTerm }) => {
  const handleChange = (event) => {
    event.preventDefault();
    setSearchTerm(event.target.value);
    // console.log(searchTerm)
  };
  return (
    <Fragment>
      <div className="file-content">

        <Form className="form-inline">
          <FormGroup className="d-flex m-0">
            <Input
              className="form-control-plaintext form-control p-1 border-0"
              type="text"
              value={searchTerm}
              onChange={(e) => handleChange(e)}
              placeholder="Cari Data Area ..."
            />
            <i className="fa fa-search"></i>
          </FormGroup>
        </Form>
      </div>
    </Fragment>
  );
};
export default SearchBar;
