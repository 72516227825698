import { CardHeader } from "reactstrap";
import { H4, P } from "../../../AbstractElements";
import { OurTotalUsers } from "../../../Constant";

const UserCardHeader = ({countPetugas}) => {
  return (
    <CardHeader className="pb-0">
      <div className="d-flex justify-content-between">
        <div className="flex-grow-1">
          <P attrPara={{ className: "square-after f-w-600 header-text-primary" }}>{'Total Petugas Keamanan'}
            <i className="fa fa-circle"></i>
          </P>
          <H4>Total : {countPetugas} Petugas Keamanan</H4>
        </div>
      </div>
    </CardHeader>
  );
};

export default UserCardHeader;