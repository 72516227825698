import Context from "./index";
import React, { useEffect, useState } from "react";
import axios from "axios";
import { LogisticApi } from "../../api";

const LogisticProvider = (props) => {
  const [logistic, setLogistic] = useState([]);
  const [myGuestData, setMyLogisticData] = useState([]);
  const [activeTabss, setActiveTabss] = useState("1");
  const [editrow, setEditrow] = useState({});
  const [editimgurl, setEditImgUrl] = useState();
  const [gridView, setgridView] = useState(true);
  const [editModal, seteditModal] = useState(false);
  const [bookMarkClass, setBookMarkClass] = useState(false);
  useEffect(() => {
    const getLogisticData = async () => {
      try {
        await axios.get(LogisticApi).then((resp) => {
          setLogistic(resp.data);
        });
      } catch (error) {
        console.log("cancelled", error);
      }
    };
    getLogisticData();
  }, [setLogistic]);

  const addNewGuest = (logisticData) => {
    const logisticTemp = {
      id: logistic.length + 1,
      name: logisticData.name,
      created_at: "20-05-2023",
      updated_at: "20-05-2023",
      created_by: "1",
      updated_by: "1"
    };
    setLogistic((prev) => [...prev, logisticTemp]);
  };

  const updatelogistic = (id, updatedData) => {
    const logisticTemp = {
      id: id,
      name: updatedData.name,
      created_at: "20-05-2023",
      updated_at: "20-05-2023",
      created_by: "1",
      updated_by: "1"
    };
    setLogistic(logistic.map((item) => (item.id === id ? logisticTemp : item)));
  };

  const removelogistic = (id) => {
    setLogistic(logistic.filter((data) => data.id !== id));
  };

  const mylogistic = (myGuestData) => {
    setMyLogisticData((prev) => [...prev, myGuestData]);
  };

  const updateMylogistic = (id, updateMydata) => {
    const logisticTemp = {
      id: id,
      name: updateMydata.name,
      description: updateMydata.desc,
      client_id: 1,
      created_at: "20-05-2023",
      updated_at: "20-05-2023",
      created_by: "1",
      updated_by: "1"
    };
    setMyLogisticData(
      myGuestData.map((item) => (item.id === id ? logisticTemp : item))
    );
  };

  const removemylogistic = (id) => {
    setMyLogisticData(myGuestData.filter((data) => data.id !== id));
  };

  return (
    <Context.Provider
      value={{
        ...props,
        logistic,
        myGuestData,
        activeTabss,
        editrow,
        editimgurl,
        editModal,
        gridView,
        bookMarkClass,
        setBookMarkClass: setBookMarkClass,
        setgridView,
        seteditModal: seteditModal,
        setEditrow: setEditrow,
        setActiveTabss: setActiveTabss,
        setEditImgUrl: setEditImgUrl,
        mylogistic: mylogistic,
        updatelogistic: updatelogistic,
        removelogistic: removelogistic,
        addNewGuest: addNewGuest,
        updateMylogistic: updateMylogistic,
        removemylogistic: removemylogistic,
      }}
    >
      {props.children}
    </Context.Provider>
  );
};

export default LogisticProvider;
