import React from "react";
import { Card, Col, Row, CardBody } from 'reactstrap';
import Skeleton from "react-loading-skeleton";
import 'react-loading-skeleton/dist/skeleton.css'
const SkeletonPatrolCard = () => {
    return (
        <>
     
          {Array(9)
            .fill()
            .map((item, index) => (
                <Col xl="6" className="box-col-6" key={index}>
                <Card
                  className='ribbon-vertical-left-wrapper'
                >
        
                  <div className="ribbon ribbon-bookmark ribbon-vertical-left ribbon-secondary">
                    <i className="icofont icofont-user-alt-2"></i>
                  </div>
        
                  <div className="job-search">
                    <CardBody>
                      <div className='d-sm-flex d-block align-items-start'>
                        <Skeleton circle={true} height={50} width={50} className="m-r-20"/>
                        <div className="flex-grow-1">
                          <Skeleton width={`40%`} />
                          <Skeleton width={`60%`} />
        
                          <br/>
                          <Skeleton width={`100%`} />
    
                        </div>
                      </div>
                      <p>  <Skeleton width={`100%`} /> </p>        
                    </CardBody>
                  </div>
                </Card>
              </Col>
            //   <li className="card" key={index}>
            //     <Skeleton height={180} />
            //     <h4 className="card-title">
            //     <Skeleton circle={true} height={50} width={50} /> &nbsp;
            //       <Skeleton height={36} width={`80%`} />
            //     </h4>
            //     <p className="card-channel">
            //       <Skeleton width={`60%`} />
            //     </p>
            //     <div className="card-metrics">
            //       <Skeleton width={`90%`} />
            //     </div>
            //   </li>
            ))}
   
   </>
    );
  };

  export default SkeletonPatrolCard;