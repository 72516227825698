import React from "react";
import { Suspense, useEffect, useState } from "react";
import { BrowserRouter, Route, Routes, Navigate } from "react-router-dom";
import { auth0 } from "../Config/Config";
import { Auth0Provider } from "@auth0/auth0-react";
// import { configureFakeBackend, authHeader, handleResponse } from "../Services/fack.backend";
import Callback from '../Auth/Callback';
import Loader from "../Layout/Loader";
import LayoutRoutes from "../Route/LayoutRoutes";
import Signin from '../Auth/Signin';
import PrivateRoute from "./PrivateRoute";
// import PersistLogin from "../Services/PersistLogin";
import Cookies from 'js-cookie';
import PersistLogin from '../Services/PersistLogin';
import RequireAuth from '../features/auth/RequireAuth';
// setup fake backend
// configureFakeBackend();
const Routers = () => {
  const [authenticated, setAuthenticated] = useState(localStorage.getItem("login"));
  const [loggedIn, setLoggedIn] = useState(null);
  const jwt_token = localStorage.getItem("token");

  useEffect(() => {
    let abortController = new AbortController();
    // const requestOptions = { method: "GET", headers: authHeader() };
    // fetch("/users", requestOptions).then(handleResponse);
    setAuthenticated(JSON.parse(localStorage.getItem("login")));
    setLoggedIn(Cookies.get('logged_in') ? true : false)
    console.ignoredYellowBox = ["Warning: Each", "Warning: Failed"];
    console.disableYellowBox = true;
    return () => {
      abortController.abort();
    };
  }, []);

  return (
    <Auth0Provider domain={auth0.domain} clientId={auth0.clientId} redirectUri={auth0.redirectUri}>
      <BrowserRouter basename={"/"}>
        <>
          <Suspense fallback={<Loader />}>
            <Routes>
              <Route element={<PersistLogin />}>
                <Route path={"/"} element={<RequireAuth />}>
                  {authenticated || jwt_token ? (
                    <>
                      <Route exact path={`${process.env.PUBLIC_URL}`} element={<Navigate to={`${process.env.PUBLIC_URL}/dashboard`} />} />
                      <Route exact path={`/`} element={<Navigate to={`${process.env.PUBLIC_URL}/dashboard`} />} />
                    </>
                  ) : (
                    ""
                  )}
                  <Route path={`/*`} element={<LayoutRoutes />} />
                </Route>
              </Route>
              <Route path={`${process.env.PUBLIC_URL}/callback`} render={() => <Callback />} />
              <Route exact path={`${process.env.PUBLIC_URL}/login`} element={<Signin />} />
            </Routes>
          </Suspense>
        </>
      </BrowserRouter>
    </Auth0Provider>
  );
};

export default Routers;
