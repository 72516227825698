import Context from "./index";
import React, { useState,useEffect } from 'react'
import axios from "axios";
import { SubareaApi } from "../../api";

const SubareaProvider = (props) => {
  const [subarea, setSubarea] = useState([]);
  const [subareaById, setSubareaById] = useState([]);
  const [editrow, setEditrow] = useState({});
  
  const [editModal, seteditModal] = useState(false);

  useEffect(() => {
    const getSubAreadata = async () => {
      try {
        await axios.get(SubareaApi).then((resp) => {
          setSubarea(resp.data);
        });
      } catch (error) {
        console.log("cancelled", error);
      }
    };
    getSubAreadata();
  }, [setSubarea]);

  const getSubareaById = (id) => {
    setSubareaById(subarea.filter((data) => data.id === id));
  };
  return (
    <Context.Provider
      value={{
        ...props,
        subarea,
        subareaById,
        editrow,
        editModal,
        seteditModal: seteditModal,
        setEditrow: setEditrow,
        setSubareaById: setSubareaById,
        getSubareaById:getSubareaById
      }}
    >
      {props.children}
    </Context.Provider>
  );
}

export default SubareaProvider