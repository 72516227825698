import { Btn, H4 ,Image} from '../../AbstractElements';
import { EditProfile, Company, Username, UsersCountryMenu, AboutMe, UpdateProfile, FirstName, LastName, Address, EmailAddress, PostalCode, Country, City } from '../../Constant';
import React, { Fragment } from 'react';
import { useForm } from 'react-hook-form';
import { Row, Col, CardHeader, CardBody, CardFooter, Form, FormGroup, Label, Input } from 'reactstrap';
import { useContext, useEffect } from 'react';

import { useDispatch } from 'react-redux';
import { useGetSystemSettingQuery } from "../../Services/clientApiSlice"
import { dynamicImage } from "../../Services"
const EditMyProfile = () => {
  const { register, handleSubmit, formState: { errors } } = useForm();

  const dispatch = useDispatch();
  const {
    data: sistem,
    isLoading,
    isSuccess,
    isError,
    error,
    refetch
  } = useGetSystemSettingQuery()

  useEffect(() => {
    dispatch(refetch);
  }, [dispatch]);

  let content;
  if (isLoading) {
    content = <p>"Loading..."</p>;
  } else if (isSuccess) {
    content = sistem.data.map((item, i) =>
        <Row className="mb-2" key={i}>
          { item.slug == 'logo' ?
            <Col sm="12" md="12">
              <Label className="form-label">{item.title}</Label>
              <div className="profile-title">
                <div className="d-lg-flex d-block align-items-center">
                  <Image attrImage={{ className: "img-70 rounded-circle", src: dynamicImage(`company/${item.value}`), }} alt="image" />
                </div>
              </div>
            </Col>
            :
            <Col sm="12" md="12">
              <FormGroup>
                <Label className="form-label">{item.title} {item.slug}</Label>
                <Input type="textarea" className="form-control" rows="5" placeholder={item.title} value={item.value}/>
                {/* <input className="form-control" type="text"  value={item.value} placeholder={item.title}  /> */}
              </FormGroup>
            </Col>
          }
        </Row>

    )
  } else if (isError) {
    content = <p>{error}</p>;
  }

  const onEditSubmit = (data) => {
    alert('Submitted');
  };
  return (
    <Fragment>
      <Form className="card" onSubmit={handleSubmit(onEditSubmit)}>
        <CardHeader className="pb-0">
          <H4 attrH4={{ className: 'card-title mb-0' }}>{EditProfile}</H4>
          <div className="card-options">
            <a className="card-options-collapse" href="#javascript">
              <i className="fe fe-chevron-up"></i>
            </a>
            <a className="card-options-remove" href="#javascript">
              <i className="fe fe-x"></i>
            </a>
          </div>
        </CardHeader>
        <CardBody>
        {isSuccess ? (sistem ?
          content :
          <p>Tidak Ada Data Patroli.</p>) : ''}
        </CardBody>
        <CardFooter className="text-end">
          <Btn attrBtn={{ color: 'primary', type: 'submit' }}>{UpdateProfile}</Btn>
        </CardFooter>
      </Form>
    </Fragment>
  );
};
export default EditMyProfile;