import React, { Fragment } from 'react'
import { Card, CardBody, Container, Input, InputGroup } from 'reactstrap'
import { Breadcrumbs, Btn, H4 } from '../../AbstractElements'
import GuardsCard from './GuardsCard'
import { Link } from 'react-router-dom'

const Guards = () => {
  return (
    <Fragment>
      <Breadcrumbs mainTitle="Petugas Lapangan" parent="Home" title="Petugas Lapangan" />
      <Container fluid={true}>
        <div className="row">
          <div className="col-sm-12">
            <Card>
              <div className="card-header pb-0">
                <H4>Data Petugas Lapangan</H4>
              </div>
              <CardBody>
                <div className="social-tab">
                  <div className="text-end">
                    <Link to={`${process.env.PUBLIC_URL}/guard/addNewGuard`} style={{ marginRight: '10px' }}>
                      <Btn attrBtn={{ color: "success" }} >
                        <i className={`fa fa-plus`} style={{ marginRight: '10px' }}></i>
                        Tambah Petugas
                      </Btn>
                    </Link>
                    <Link to={`${process.env.PUBLIC_URL}/guard/schedule`}>
                      <Btn attrBtn={{ color: "primary" }} >
                        <i className={`fa fa-calendar`} style={{ marginRight: '10px' }}></i>
                        Jadwal Petugas Lapangan
                      </Btn>
                    </Link>
                  </div>
                  
                  <InputGroup>
                    <Input type="text" placeholder="Cari Data....." />
                    <span className="input-group-text">
                      <i className="fa fa-search"></i>
                    </span>
                  </InputGroup>
                </div>
              </CardBody>
            </Card>
          </div>
          <div className="col-sm-12">
            <GuardsCard />
          </div>
        </div>
      </Container>
    </Fragment>
  )
}

export default Guards