import { useContext, useEffect, useState } from 'react';
import { Card, Col, Row, CardBody, Input, InputGroup } from 'reactstrap';
import { H6, Image, LI, P, UL, Btn } from '../../../AbstractElements';
import JobSearchContext from '../../../Helper/JobSearch';
import CardsPagination from './CardsPagination';
import { dynamicImage } from "../../../Services/index"
import { Link } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { useGetPatrolsQuery } from "../../../Services/patrolApiSlice";
import SkeletonPatrolCard from "./SkeletonPatrolCard";
import { jsPDF } from "jspdf";
import html2canvas from "html2canvas"
import ReportPdfPatrol from './ReportPdfPatrol';

const CardsClass = ({ refresh, setRefresh, filterSearchBoolean, setFilterSearchBoolean, setSearchTerm, isloadingData, searchResults, pages, setPages }) => {
  const { jobData } = useContext(JobSearchContext);

  const [currentPages, setCurrentPages] = useState(1);
  const dispatch = useDispatch();
  const {
    data: patrols,
    isLoading,
    isSuccess,
    isError,
    error,
    refetch
  } = useGetPatrolsQuery({ limit: "?page=" + currentPages })


  useEffect(() => {
    if (isSuccess) {
      setPages(patrols.totalPages)
    } else {
      setPages(1)
    }
  }, []);

  useEffect(() => {
    dispatch(refetch);
    setRefresh(false);
    if (isSuccess) {
      setPages(patrols.totalPages)
    } else {
      setPages(1)
    }
  }, [refresh, dispatch]);
  console.log("total Pages : " + pages)

  useEffect(() => {
    if (filterSearchBoolean && searchResults) {
      setPages(searchResults.totalPages)
    }
  }, [filterSearchBoolean]);



  const onResetClicked = (event) => {
    setFilterSearchBoolean(false)
    dispatch(refetch);
    setPages(patrols.totalPages)
    setSearchTerm('')
  }

  const createPDF = async (e) => {
    e.preventDefault()
    // const pdf = new jsPDF("portrait", "pt", "a4");
    //   const data =  document.querySelector("#pdf");
    //   pdf.html(data).then(() => {
    //     pdf.save("shipping_label.pdf");
    //   });
    const pdf = new jsPDF("portrait", "pt", "a4");
    const data = await html2canvas(document.querySelector("#pdf"));
    const img = data.toDataURL("image/png");
    const imgProperties = pdf.getImageProperties(img);
    const pdfWidth = pdf.internal.pageSize.getWidth();
    const pdfHeight = (imgProperties.height * pdfWidth) / imgProperties.width;
    pdf.addImage(img, "PNG", 0, 0, pdfWidth, pdfHeight);
    pdf.save("shipping_label.pdf");
  };


  const paginate = (e, value) => {
    e.preventDefault()
    setCurrentPages(value)
    let prev = value - 1
    let next = value + 1
    if (value == 1) {
      prev = 1
    }

    if (next > pages) {
      next = pages
    }

    // console.log('Page Clicked:', value);
    // console.log('Current Paget Now:', value);
    // console.log('Current Paget Previous:', prev);
    // console.log('Current Paget Next:', next);
    setRefresh(true);
    dispatch(refetch);
    setRefresh(false);
  }
  let content;
  if (filterSearchBoolean) {
    content = <SkeletonPatrolCard />;
    if (isloadingData) {
      content = <SkeletonPatrolCard />;
    } else if (searchResults) {
      if (searchResults.data != null) {
        // setTimeout(() => {
        content = searchResults.data.map((item, i) => <Col xl="6" className="box-col-6" key={i}>
          <Card
            className='ribbon-vertical-left-wrapper'
          >

            <div className="ribbon ribbon-bookmark ribbon-vertical-left ribbon-secondary">
              <i className="icofont icofont-user-alt-2"></i>
            </div>

            <div className="job-search">
              <CardBody>
                <div className='d-sm-flex d-block align-items-start'>
                  <Image
                    attrImage={{
                      className: 'img-40 img-fluid m-r-20',
                      src: dynamicImage('job-search/1.jpg'),
                      alt: '',
                    }}
                  />
                  <div className="flex-grow-1">
                    <H6 attrH6={{ className: 'f-w-600' }} >
                      <Link to={process.env.PUBLIC_URL + "/patrol"}>{item.subarea_name}</Link>
                      {/* <span className="pull-right">
                      {item.arrival_on}
                      </span> */}
                      <span className="badge badge-primary pull-right"> {item.arrival_on}</span>

                    </H6>
                    <P>
                      Area : {item.area_name}

                    </P>
                  </div>
                </div>
                <p className='f-12'> Petugas : <strong>{item.officer_name}</strong></p>
                <p className='f-12'> Bukti Patroli : </p> <br />
                <div className='d-sm-flex d-block align-items-start'>
                  <Image
                    attrImage={{
                      className: 'img-100 img-fluid m-r-20',
                      src: process.env.REACT_APP_API_BASE_URL + item.picture,
                      alt: '',
                      error: dynamicImage(`company/MAS57.png`)
                    }}
                  />
                  <div className="flex-grow-1">
                    <P attrPara={{ className: 'mb-1' }}>Deskripsi : {item.description['String']}</P>
                    <P attrPara={{ className: 'mb-1' }}>Alamat : {item.subarea_address}</P>
                  </div>
                </div>

              </CardBody>
            </div>
          </Card>
        </Col>)  
        // }, 200);
      }else{        
        // setTimeout(() => {
          content = 'Tidak Ada histori patroli.';
      // }, 2000);
      }
    } else {
      setFilterSearchBoolean(false)
    }

  } else {
    if (isLoading) {
      content = <SkeletonPatrolCard />;
    } else if (isSuccess) {

      content = patrols.data.map((item, i) => <Col xl="6" className="box-col-6" key={i}>
        <Card
          className='ribbon-vertical-left-wrapper'
        >

          <div className="ribbon ribbon-bookmark ribbon-vertical-left ribbon-secondary">
            <i className="icofont icofont-user-alt-2"></i>
          </div>

          <div className="job-search">
            <CardBody>
              <div className='d-sm-flex d-block align-items-start'>
                <Image
                  attrImage={{
                    className: 'img-40 img-fluid m-r-20',
                    src: dynamicImage('job-search/1.jpg'),
                    alt: '',
                  }}
                />
                <div className="flex-grow-1">
                  <H6 attrH6={{ className: 'f-w-600' }} >
                    <Link to={process.env.PUBLIC_URL + "/patrol"}>{item.subarea_name}</Link>
                    {/* <span className="pull-right">
                        {item.arrival_on}
                        </span> */}
                    <span className="badge badge-primary pull-right"> {item.arrival_on}</span>

                  </H6>
                  <P>
                    Area : {item.area_name}

                  </P>
                </div>
              </div>
              <p> Bukti Patroli : </p> <br />
              <div className='d-sm-flex d-block align-items-start'>
                <Image
                  attrImage={{
                    className: 'img-100 img-fluid m-r-20',
                    src: process.env.REACT_APP_API_BASE_URL + item.picture,
                    alt: '',
                  }}
                />
                <div className="flex-grow-1">
                  <P attrPara={{ className: 'mb-1' }}>Deskripsi : {item.description['String'] != "" ? item.description['String'] : "Tidak ada keterangan."}</P> <br/>
                  <P attrPara={{ className: 'mb-1' }}>Alamat : {item.subarea_address}</P>
                </div>
              </div>

            </CardBody>
          </div>
        </Card>
      </Col>)
    } else if (isError) {
      content = <p>{error}</p>;
    }
  }
  return (
    <Col xxl="12" xl="12" className="box-col-8">
      <Row>
        <div className='btn-showcase m-b-5'>
          <Btn attrBtn={{ color: 'warning', type: 'submit', onClick: (e) => onResetClicked(e), className: "align-items-center justify-content-center" }}>{'Reset Data'}</Btn>
          <Btn attrBtn={{ color: "info", size: "md" }}>
            <Link to={process.env.PUBLIC_URL + "/patrol/laporan"}
              style={{ color: 'white' }}>
              <div className="likes-profile text-center">{"Laporan Patroli"}</div>

            </Link>
          </Btn>
        </div>
      </Row>
      <Row>
        {isSuccess ? (patrols ?
          content :
          <p>Tidak Ada Data Patroli.</p>) : ''}
      </Row>

      <CardsPagination totalPages={pages} currentPages={currentPages} paginate={paginate} />

    </Col>

  );
};

export default CardsClass;