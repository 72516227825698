import { Link } from "react-router-dom";

export const tableColumns = [
    {
        name: 'Id',
        selector: row => row['id'],
        sortable: true,
        center: true,
    },
    {
        name: 'No Identitas',
        selector: (row) => row['id_card'],
        sortable: true,
        center: true,
    },
    {
        name: 'Nama',
        selector: row => row['name'],
        sortable: true,
        center: false,
    },
    {
        name: 'Keperluan.',
        selector: row => `${row.purposes}`,
        sortable: true,
        center: true,
    },
    {
        name: 'Aksi',
        cell: (row) => <span><Link to={`${process.env.PUBLIC_URL}/guard`}>Detail</Link></span>,
        ignoreRowClick: true,
        allowOverflow: true,
        button: true,
    },

];