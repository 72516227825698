import { useContext, useEffect } from 'react';
import { CardBody } from "reactstrap";
import { Todolist } from "../../../Data/DashBord/DefaultList/todolist";
import { Image, P } from "../../../AbstractElements";
import { useDispatch } from 'react-redux';
import { useGetPatrolsQuery } from "../../../Services/patrolApiSlice";
const TodoListCardBody = () => {
  // { limit: "?limit=5" }
  const dispatch = useDispatch();
  const {
    data: patrols,
    isLoading,
    isSuccess,
    isError,
    error,
    refetch
  } = useGetPatrolsQuery({ limit: "?limit=5" })

  useEffect(() => {
    dispatch(refetch);
  }, [dispatch]);

  const formatDate = (date) => {
    const today = new Date();
    const inputDate = new Date(date);

    // Check if the date is today
    if (inputDate.toDateString() === today.toDateString()) {
      return 'Hari Ini.';
    }

    // Check if the date is yesterday
    const yesterday = new Date(today);
    yesterday.setDate(yesterday.getDate() - 1);
    if (inputDate.toDateString() === yesterday.toDateString()) {
      return 'Kemarin';
    }

    // Format the date using a custom format
    const options = { year: 'numeric', month: 'long', day: 'numeric' };
    return inputDate.toLocaleDateString('id-ID', options);
  }
  let content;
  if (isLoading) {
    content = <p>"Loading..."</p>;
  } else if (isSuccess) {
    content = patrols.data.map((item, i) =>
      <div className="d-flex" key={i}>
        <div className="activity-line"></div>
        {/* <div className={`${item.classname} `}></div> */}
        <div className="activity-dot-primary"></div>
        <div className="flex-grow-1">
          <P attrPara={{ className: "mt-0 todo-font" }}>
            <span className="font-primary">{item.arrival_on} </span>
            {formatDate(item.arrival_on)}
            { formatDate(item.arrival_on) == 'Hari Ini.' ?
              <span className="badge badge-primary ms-2">New</span> : " "
            }
            
          </P>
          <div className="d-flex mt-0">
            {item?.picture && (
              <Image
                attrImage={{
                  className: "img-fluid img-100 p-2 media",
                  src: `${process.env.REACT_APP_API_BASE_URL}${item.picture}`,
                  alt: "image",
                }}
              />
            )}
            <div className="flex-grow-1 p-1">
              <span className="f-w-600">{item.area_name} </span>
              <i className="primary"></i>
              <P attrPara={{ className: "mb-0" }}> Subarea : {item.subarea_name}</P>
              <P attrPara={{ className: "mb-0 " }}>{item.subarea_address}</P>
              <P attrPara={{ className: "mb-0 " }}>Deskripsi Patroli: {item.description['String']}</P>
            </div>
          </div>
        </div>
      </div>
    )
  } else if (isError) {
    content = <p>{error}</p>;
  }
  return (
    <CardBody>
      <div className="activity-timeline todo-timeline">

        {isSuccess ? (patrols ?
          content :
          <p>Tidak Ada Data Patroli.</p>) : ''}
      </div>
    </CardBody>
  );
};

export default TodoListCardBody;