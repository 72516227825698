import { useEffect, useState } from 'react';
import Ravanuechart from "./RevanueChart.jsx/index.jsx";
import Greetingcard from "./GreetingCard/index.jsx";
import TotalUserCard from "./TotalUser/TotalUserCard.jsx";
import TotalEarning from "./TotalEarning/TotalEarning.jsx";
import AppoimentCard from "./Appoiment/AppoimentCard.jsx";
import TotalGrowthCard from "./TotalGrowth/TotalGrowthCard.jsx";
import RecentActivity from "./RecentActivity/RecentActivity.jsx";
import CountryCard from "./Country/Country.jsx";
import ChatCard from "./Chat/ChatCard.jsx";
import TodoList from "./Todo/TodoList.jsx";
import { Breadcrumbs,Btn } from "../../AbstractElements.jsx";
import { Container, Row } from "reactstrap";
import { useDispatch } from 'react-redux';
import { useGetDashboardDataQuery } from "../../Services/clientApiSlice.jsx";
import { useRefreshTokenMutation } from "../../Services/authApiSlice.jsx"
import Cookies from 'js-cookie';
const Dashboard = () => {
  // 
  // useGetDashboardDataQuery
  const dispatch = useDispatch();
  const {
    data: dashboard,
    isLoading,
    isSuccess,
    isError,
    error,
    refetch
  } = useGetDashboardDataQuery('getUserLogs')


  useEffect(() => {
    const delay = 3000; // 3 seconds in milliseconds

    const timeoutId = setTimeout(() => {
      dispatch(refetch);
      console.log('Delayed code executed after 3 seconds');
    }, delay);

    // Clean up the timeout when the component unmounts or when the effect runs again
    return () => {
      clearTimeout(timeoutId);
    };
  }, []); // Empty dependency array ensures the effect runs only once


  let content;
  if (isLoading) {
    content = <p>"Loading..."</p>;
  } else if (isSuccess) {
    content = (
      <>
        <Ravanuechart countUser = {dashboard.data.totalUser} countArea={dashboard.data.totalArea} countSubarea={dashboard.data.totalSubarea} />
        <TotalUserCard countPetugas={dashboard.data.totalPetugas} />
      </>
    )
    // setCountUser(dashboard.data['totalUser']),
    // setCountPetugas(dashboard.data['totalPetugas']),
    // setCountArea(dashboard.data['totalArea']),
    // setCountSubarea(dashboard.data['totalSubarea'])

  } else if (isError) {
    console.log(error)
    content = <p>{error}</p>;
  }

  return (
    <>
      <Breadcrumbs mainTitle="Halaman Dasbor" parent="Dasbor" title="Halaman Dasbor" />
      <Container fluid={true} className="dashboard-default">
        <Row>
        {/* <Btn attrBtn={{ color: 'primary', className: 'd-block w-100 mt-2', onClick: (e) => refreshToken(e) }}>{'Refresh Token'}</Btn> */}
          <Greetingcard />
          {isSuccess ? (dashboard ?
            content :
            <>
              <Ravanuechart countUser="0" countArea="0" countSubarea="0" />
              <TotalUserCard countPetugas="0" />
            </>
          ) : ''}

          <TodoList />
          {/* <TotalEarning /> */}
          <AppoimentCard />
          {/* <CountryCard /> */}
          {/* <TotalGrowthCard />
          <RecentActivity />
          <ChatCard /> */}

        </Row>
      </Container>
    </>
  );
};

export default Dashboard;
