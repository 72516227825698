import { Link } from "react-router-dom";
import { Btn, Image, P } from "../../../AbstractElements";
import { Appoiment } from "../../../Data/DashBord/Default/Default";
import { dynamicImage } from "../../../Services";
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useGetUserLogsQuery } from "../../../Services/clientApiSlice";

const AppoimentCardTableBody = () => {

  // ,
  // { limit: "?limit=5" }
  const dispatch = useDispatch();
  const {
    data: logs,
    isLoading,
    isSuccess,
    isError,
    error,
    refetch
  } = useGetUserLogsQuery('getUserLogs')

 

  useEffect(() => {
    const delay = 3000; // 3 seconds in milliseconds

    const timeoutId = setTimeout(() => {
      dispatch(refetch);
      console.log('Delayed code executed after 3 seconds');
    }, delay);

    // Clean up the timeout when the component unmounts or when the effect runs again
    return () => {
      clearTimeout(timeoutId);
    };
  }, []); // Empty dependency array ensures the effect runs only once

  const formatDate = (date) => {
    const today = new Date();
    const inputDate = new Date(date);

    // Check if the date is today
    if (inputDate.toDateString() === today.toDateString()) {
      return 'Hari Ini.';
    }

    // Check if the date is yesterday
    const yesterday = new Date(today);
    yesterday.setDate(yesterday.getDate() - 1);
    if (inputDate.toDateString() === yesterday.toDateString()) {
      return 'Kemarin';
    }

    // Format the date using a custom format
    const options = { year: 'numeric', month: 'long', day: 'numeric' };
    return inputDate.toLocaleDateString('id-ID', options);
  }
  let content;
  if (isLoading) {
    content = <p>"Loading..."</p>;
  } else if (isSuccess) {
    content = logs.data.map((item, i) =>
      <tr key={i}>
        <td>
          <div className="d-flex">
            <Image
              attrImage={{
                className: "img-fluid align-top circle",
                src: dynamicImage(`company/MAS57-h60.png`),
                alt: "image",
              }}
            />
            <div className="flex-grow-1">
              <Link to={`${process.env.PUBLIC_URL}/users/userProfile`}>
                <span>{item.name}</span>
              </Link>
              <P attrPara={{ className: "mb-0" }}>{formatDate(item.created_at)}</P>
              <P attrPara={{ className: "mb-0" }}>{item.description}</P>
              Tabel : <br />
              <Btn attrBtn={{color: "primary"}}>                
                {item.table_on}
                <i className={`fa fa-check-circle`}></i>
              </Btn>
            </div>
            <div className={`active-status active-online`}></div>
          </div>
        </td>
        <td className="text-end">
          <Btn attrBtn={{color: "secondary"}}>
            Logs
          </Btn>
        </td>
      </tr>
    )
  } else if (isError) {
    content = <p>{error}</p>;
  }

  return (
    <tbody>
      {isSuccess ? (logs ?
        content :
        <p>Tidak Ada Data Patroli.</p>) : ''}
    </tbody>
  );
};

export default AppoimentCardTableBody;

