import React, { Fragment } from 'react'
import EditGuardForm from './EditGuardForm'
import { Breadcrumbs } from '../../../AbstractElements'
import { Col, Container, Row } from 'reactstrap'

const index = () => {
  return (
    <Fragment>
      <Breadcrumbs mainTitle="Edit Data Petugas" parent="Guards" title="Guards Edit" />
      <Container fluid={true}>
        <div className="edit-profile typeahead">
          <Row>
            <Col xl="12" lg="12">
              <EditGuardForm/>
            </Col>
          </Row>
        </div>
      </Container>
    </Fragment>
  )
}

export default index