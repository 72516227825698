import React from "react";
import Skeleton from "react-loading-skeleton";
import { Col, Card, Table, Row, ButtonGroup } from "reactstrap";
import 'react-loading-skeleton/dist/skeleton.css'
const SkeletonReportPage = () => {
  return (
    <>
      {Array(9)
        .fill()
        .map((item, index) => (
          <tr>
            <th scope="row"><Skeleton width={`100%`} /></th>
            <td><Skeleton width={`100%`} /></td>
            <td><Skeleton width={`100%`} /></td>
            <td><Skeleton width={`100%`} /></td>
            <td><Skeleton width={`100%`} /></td>
            <td><Skeleton width={`100%`} /></td>
          </tr>
        ))
      }


    </>
  );
};

export default SkeletonReportPage;