import { createEntityAdapter } from "@reduxjs/toolkit";
import { apiSlice } from "../app/api/apiSlice"


const areaAdapter = createEntityAdapter()

const initialState = areaAdapter.getInitialState()

export const areaApiSlice = apiSlice.injectEndpoints({
    endpoints: builder => ({
        getAreas: builder.query({
            query: () => '/areas',
            method: 'GET',
        }),
        getAreasById: builder.query({
            query: ({id}) => `/areas/${id}`,
            method: 'GET'
        }),
        getAreaByClient: builder.query({
            query: ({clientId}) => ({
                url: `/client/${clientId}/areas`,
                method: 'GET',
                keepUnusedDataFor: 0,
                providesTags: (result, error, arg) => [
                    { type: 'Client', id: "LIST" },
                    ...result.data.map(id => ({ type: 'Client', id }))
                    // console.log(result)
                ]
            })
        }),
        addNewAreas: builder.mutation({
            query: initialData => ({
                url: '/areas',
                method: 'POST',
                body: {
                    ...initialData
                }
            }),
            async onQueryStarted(arg, { dispatch, queryFulfilled }) {
                try {
                    const { data } = await queryFulfilled
                    console.log(data)
                } catch (err) {
                    console.log(err)
                }
            }
        }),
    })
})

export const {
    useGetAreasQuery,
    useGetAreasByIdQuery,
    useGetAreaByClientQuery,
    useAddNewAreasMutation
} = areaApiSlice 