import React, { useEffect, useRef, useState } from 'react'
import { useForm } from 'react-hook-form';
import { Col, Container, Form, FormGroup, Input, InputGroup, Label, Row } from 'reactstrap';
import { toast } from 'react-toastify';
import { Btn } from '../../../AbstractElements';
import Modals from '../../../Common/Modals';
import { Close, Save } from '../../../Constant';
import { useAddNewAreasMutation } from '../../../Services/areaApiSlice';
// import AreaContext from '../../../Helper/area';
import axios from 'axios';
import GeoMaps from './GeoMaps';
const AddAreaModal = ({ modal, NewMessage, toggle, clientId, refresh, setRefresh }) => {
    const [addNewAreas, { isLoading }] = useAddNewAreasMutation()
    const { register, handleSubmit, formState: { errors } } = useForm();
    const { ref, ...desc } = register("desc");

    let btnRef = useRef();

    let { name } = "Bandung"
    const [mapLocation, setLocation] = useState();
    const [mapInfo, setMapInfo] = useState([])
    const [search, setSearch] = useState('');
    const [color, setColor] = useState('Grey')
    let _color = ['Red', 'Green', 'Yellow', 'Blue', 'Pink', 'Orange']

    useEffect(async () => {
        await fetch(`https://maps.googleapis.com/maps/api/geocode/json?address="bandung"&key=${process.env.REACT_APP_GOOGLEAPI}`)
            .then(resp => resp.json())
            .then(data => {
                console.log(data)
                //so to get the gemotry location:
                setLocation(data.results[0].geometry.location)
            }
            )
        setMapInfo({
            id: "1",
            name: "Bandung"
        })
        //get mapinfo:
        // await axios.post("http://localhost:2000/api/getMapInfo", { name: name })
        //     .then((response) => {
        //         console.log("response", response)
        //         // setMapInfo(response)
        //     })

        return () => {
            setLocation(null)
            setMapInfo(null)
        }

    }, [name])



    const onSearchMap = async () => {
        let places = '';
        if (search == '') {
            places = 'Bandung'
        } else {
            places = search
        }

        await fetch(`https://maps.googleapis.com/maps/api/geocode/json?address="${places}"&key=${process.env.REACT_APP_GOOGLEAPI}`)
            .then(resp => resp.json())
            .then(data => {
                // console.log(data)
                //so to get the gemotry location:
                setLocation(data.results[0].geometry.location)
                setMapInfo({
                    id: "1",
                    name: places
                })
            }
            )
    };

    

    const center = mapLocation;
    const [state, setState] = useState([])
    const { paths } = state

    console.log(center)

    const new_path = JSON.stringify(state.paths)
    // console.log("new path stat " + new_path)


    const onSaveClicked = async (data) => {
        try {
            await addNewAreas(
                {
                    Name: data.namaArea,
                    Description: data.desc,
                    client_id: clientId,
                    coordinate: new_path
                }
            ).unwrap()
            // navigate('/')
            setRefresh(true)

            toast.success("Berhasil Menyimpan Data Area.");
            toggle()
        } catch (err) {
            toast.error("Gagal Menyimpan Data Area.");
            console.error('Failed to save the post', err)
        }
    }

    const handleColorChange = (e) => setColor(e.target.value)

    const handleSearchChange = (e) => setSearch(e.target.value)

    return (
        <Modals isOpen={modal} title={NewMessage} toggler={toggle} onSubmit={false} size="xl">
            <Form
                className="form-bookmark needs-validation"
                onSubmit={handleSubmit(onSaveClicked)}
            >
                <Container fluid={true}>
                    <Row>
                        <Col sm="12" xl="8">
                            <Row className="mb-2">
                                <Col md="3">
                                    <select className="form-select" value={color} onChange={handleColorChange}>
                                        <option>Select</option>
                                        {
                                            _color.map((item, index) =>
                                                <option key={index}>{item}</option>
                                            )
                                        }
                                    </select>
                                </Col>
                                <Col md="9">
                                    <Row>
                                        <Col md="7">
                                            <InputGroup>
                                                <Input type="text" placeholder="Search......." onChange={handleSearchChange} />
                                                <span className="input-group-text">
                                                    <i className="fa fa-search"></i>
                                                </span>
                                            </InputGroup>
                                        </Col>
                                        <Col md="4">
                                            <Btn attrBtn={{ color: 'success', type: 'button', onClick: onSearchMap }} className="mt-0"> Cari</Btn>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>

                            <GeoMaps
                                apiKey={process.env.REACT_APP_GOOGLEAPI}
                                center={center}
                                paths={paths}
                                point={paths => setState({ paths })}
                                color={color}
                                setColor={setColor}
                            />
                        </Col>
                        <Col sm="12" xl="4">
                            <FormGroup>
                                <Label className="col-form-label" for="recipient-name">{'Nama Area:'}</Label>
                                <input
                                    className="form-control"
                                    type="text"
                                    laceholder="Nama Area Anda"
                                    autoComplete="off"
                                    name="namaArea"
                                    {...register('namaArea', { required: true })}
                                />
                            </FormGroup>
                            <FormGroup>
                                <Label className="col-form-label" for="message-text">{'Deskripsi (opsional):'}</Label>
                                <Input type="textarea" className="form-control" name="desc" id="message-text" placeholder="Deksripsi" innerRef={ref} {...desc} />
                            </FormGroup>
                            <Btn attrBtn={{ color: 'primary', type: 'submit' }}>{Save}</Btn>&nbsp;&nbsp;
                            <Btn attrBtn={{ color: 'secondary', onClick: toggle }} >{Close}</Btn>
                        </Col>
                    </Row>

                </Container>



            </Form>
        </Modals>
    )
}

export default AddAreaModal