import { Layers, Home,
  Users,
Shield,
Navigation,
Package,
UserCheck,
Settings,
LogOut
} from "react-feather";

export const MENU = [
  {
    className: "menu-box",
    menu: [
      {
        url:`${process.env.PUBLIC_URL}/dashboard`,
        title: "Dasbor",
        icon: <Home />,
        type: "link",
      },
      {
        url:`${process.env.PUBLIC_URL}/client`,
        title: "Klien",
        icon: <Users />,
        type: "link",
      },
      {
        url:`${process.env.PUBLIC_URL}/guard`,
        title: "Petugas Lapangan",
        icon: <Shield />,
        type: "link",
      },
      {
        url:`${process.env.PUBLIC_URL}/patrol`,
        title: "Patroli",
        icon: <Navigation />,
        type: "link",
      },
      // {
      //   url:`${process.env.PUBLIC_URL}/guest`,
      //   title: "Guest",
      //   icon: <UserCheck />,
      //   type: "link",
      // },
      // {
      //   url:`${process.env.PUBLIC_URL}/logistic`,
      //   title: "Logistic",
      //   icon: <Package />,
      //   type: "link",
      // },
      {
        url: `${process.env.PUBLIC_URL}/setting`,
        icon: <Settings />,
        title: "Settings",
        type: "link",
      }
    ],
  },
  {
    className: "menu-box",
    menu: [
      {
        url: `${process.env.PUBLIC_URL}/logout`,
        icon: <LogOut />,
        title: "Keluar",
        type: "link",
      }
    ],
  },
];

export const SEARCHMENU = [
  {
    className: "menu-box",
    menu: [
      {
        url: `${process.env.PUBLIC_URL}/samplepage`,
        icon: <Layers />,
        title: "SamplePage",
        type: "link",
      },
      {
        url:`${process.env.PUBLIC_URL}/dashboard/default`,
        icon: <Home />,
        title: "Dashboards",
        type: "link",
      },
      {
        url:`${process.env.PUBLIC_URL}/client`,
        title: "Clients",
        icon: <Users />,
        type: "link",
      },
      {
        url:`${process.env.PUBLIC_URL}/guard`,
        title: "Guards",
        icon: <Shield />,
        type: "link",
      },
      {
        url:`${process.env.PUBLIC_URL}/patrol`,
        title: "Patrols",
        icon: <Navigation />,
        type: "link",
      },
      {
        url:`${process.env.PUBLIC_URL}/guest`,
        title: "Guest",
        icon: <UserCheck />,
        type: "link",
      },
      {
        url:`${process.env.PUBLIC_URL}/logistic`,
        title: "Logistic",
        icon: <Package />,
        type: "link",
      },
      {
        url: `${process.env.PUBLIC_URL}/samplepage`,
        icon: <Settings />,
        title: "Settings",
        type: "link",
      },
      {
        title: "sample",
        icon: <Layers />,
        class: "lan-3",
        menu: [
          {
            title: "sample",
            url: `${process.env.PUBLIC_URL}/sample`,
            class: "lan-4",
            type: "link",
          },
        ],
      },
    ],
  },
  {
    className: "menu-box",
    menu: [
      {
        url: `https://docs.pixelstrap.com/tivo/document/getting-started-react.html`,
        icon: <Layers />,
        title: "Documentation",
        type: "link",
      },
      {
        url: `http://support.pixelstrap.com/help-center`,
        icon: <Layers />,
        title: "Support",
        type: "link",
      },
    ],
  },
];
