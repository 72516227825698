import React from "react";
import Routers from "./Route";

import CustomizerProvider from "./Helper/Customizer/CustomizerProvider";
import AnimationThemeProvider from "./Helper/AnimationTheme/AnimationThemeProvider";
import BookmarkProvider from "./Helper/bookmark/BookmarkProvider";
import ProductProvider from "./Helper/product/ProductProvider";
import JobSearchProvider from './Helper/JobSearch/JobSearchProvider'
import AreaProvider from "./Helper/area/AreaProvider";
import SubareaProvider from "./Helper/subarea/SubareaProvider";
import GuardProvider from "./Helper/guard/GuardProvider";
import GuestProvider from "./Helper/guest/GuestProvider";
import LogisticProvider from "./Helper/logistic/LogisticProvider";

const App = () => {
  return (
    <div className="App">
     
        <CustomizerProvider>
          <AnimationThemeProvider>
            <AreaProvider>
              <SubareaProvider>
                <GuardProvider>
                  <GuestProvider>
                    <LogisticProvider>
                      <BookmarkProvider>
                        <ProductProvider>
                          <JobSearchProvider>
                            <Routers />
                          </JobSearchProvider>
                        </ProductProvider>
                      </BookmarkProvider>
                    </LogisticProvider>
                  </GuestProvider>
                </GuardProvider>
              </SubareaProvider>
            </AreaProvider>
          </AnimationThemeProvider>
        </CustomizerProvider>
   
    </div>
  );
};

export default App;
