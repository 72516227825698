import { useContext, useEffect, useState, Fragment } from 'react';
import DatePicker, { registerLocale } from 'react-datepicker';
import { Card, Col, Row, CardBody, Input, Label, InputGroup, Container } from 'reactstrap';
import { H6, Image, LI, P, UL, Btn, Breadcrumbs, H4 } from '../../../AbstractElements';
import axios from 'axios';
import { useDispatch } from 'react-redux';
import { jsPDF } from "jspdf";
import html2canvas from "html2canvas"
import ReportPdfPatrol from './ReportPdfPatrol';
import id from "date-fns/locale/id";
import Select from 'react-select';
import { format } from 'date-fns';
import { useGetListAreaNamesNoLimitQuery, useGetListOfficerNoLimitQuery } from '../../../Services/patrolApiSlice';
import { Link } from 'react-router-dom';

registerLocale("id", id);
const options = [
    { value: 'AL', label: 'Area Gistek' },
    { value: 'WY', label: 'Area Itenas' },
    { value: 'WY', label: 'Area Unikom Dago' }
];

const options2 = [
    { label: 'Developer', isDisabled: true },
    { value: 'AL', label: 'Alabama' },
    { value: 'WY', label: 'Wyoming' },
    { label: 'Designer', isDisabled: true },
    { value: 'WY', label: 'Coming' },
    { value: 'WY', label: 'Hanry Die' },
    { value: 'WY', label: 'John Doe' },
];

const ReportPage = () => {
    const dispatch = useDispatch();
    const [startDate, setStartDate] = useState(new Date());
    const [isloadingData, setIsloadingData] = useState(false);
    const [searchResults, setSearchResults] = useState([]);
    const [selectedareaOption, setSelectedareaOption] = useState(null);
    const [selectedguardOption, setSelectedguardOption] = useState(null);
    const apiBaseUrl = process.env.REACT_APP_API_BASE_URL;
    let areaList = []
    let guardList = []
    const {
        data: areas,
        isLoading: loadArea,
        isSuccess: isSuccessArea,
        isError: isErrorArea,
        error: errorArea,
        refetch: refectArea
    } = useGetListAreaNamesNoLimitQuery()

    const {
        data: guards,
        isLoading: loadGuard,
        isSuccess: isSuccessGuard,
        isError: isErrorGuard,
        error: errorGuard,
        refetch: refectGuard
    } = useGetListOfficerNoLimitQuery()



    if (isSuccessArea) {
        if (areas.data) {

            areas.data.map((item, i) => areaList.push(
                { value: item.id, label: item.name }
            ))
        } else {
            areaList = []
        }
    } else {
        areaList = []
    }

    if (isSuccessGuard) {
        if (guards.data) {

            guards.data.map((item, i) => guardList.push(
                { value: item.id, label: item.name }
            ))
        } else {
            guardList = []
        }
    } else {
        guardList = []
    }

    const handleChange = async date => {
        setStartDate(date);
        setIsloadingData(true)
        console.log(format(date, 'yyyy-MM-dd'))
        getHistoryData(date, selectedareaOption, selectedguardOption)

    };

    const onInputAreaChange = (selectedareaOption) => {
        setSelectedareaOption(selectedareaOption);
        getHistoryData(startDate, selectedareaOption, selectedguardOption)
    };

    const onInputGuardChange = (selectedguardOption) => {
        setSelectedguardOption(selectedguardOption);
        getHistoryData(startDate, selectedareaOption, selectedguardOption)
    };

    const getHistoryData = async (
        startDate,
        selectedareaOption,
        selectedguardOption) => {
        console.log(selectedareaOption)
        console.log(selectedguardOption)
        console.log(startDate)

        let data = JSON.stringify({
            "arival_on": format(startDate, 'yyyy-MM-dd'),
            "officerId": (selectedguardOption != null ? selectedguardOption.value : ""),
            "area": (selectedareaOption != null ? selectedareaOption.label : "")
        });

        try {


            let config = {
                method: 'post',
                maxBodyLength: Infinity,
                url: `${apiBaseUrl}/patrol/GetFilterPatrolsReport`,
                headers: {
                    'Content-Type': 'application/json'
                },
                data: data
            };

            const response = await axios.request(config)
                .then((response) => {
                    console.log(JSON.stringify(response))
                    setSearchResults(response.data);
                    setTimeout(() => {
                        setIsloadingData(false)
                    }, 2000);
                    // console.log(JSON.stringify(response.data));
                })
                .catch((error) => {
                    console.log(error);
                    setIsloadingData(false)
                });


        } catch (error) {
            console.error('Error fetching search results:', error);
            setIsloadingData(false)
        }
    }
    const createPDF = async (e) => {
        e.preventDefault()
        // const pdf = new jsPDF("portrait", "pt", "a4");
        //   const data =  document.querySelector("#pdf");
        //   pdf.html(data).then(() => {
        //     pdf.save("shipping_label.pdf");
        //   });
        const pdf = new jsPDF("portrait", "pt", "a4");
        const data = await html2canvas(document.querySelector("#pdf"));
        const img = data.toDataURL("image/png");
        const imgProperties = pdf.getImageProperties(img);
        const pdfWidth = pdf.internal.pageSize.getWidth();
        const pdfHeight = (imgProperties.height * pdfWidth) / imgProperties.width;
        pdf.addImage(img, "PNG", 0, 0, pdfWidth, pdfHeight);
        let printDate = format(new Date(), 'ddMMyyyyHHmmss')
        pdf.save(printDate + "_LaporanRiwayatPatroli.pdf");
    };


    return (
        <Fragment>
            <Breadcrumbs mainTitle="Laporan Patroli" parent="Home" subParent="Patroli" title="Laporan Patroli" />
            <Container fluid={true}>
                <div className="row">
                    <div className="col-sm-4 text-start m-b-5">
                        <Btn attrBtn={{ color: "info", size: "md" }}>
                            <Link to={process.env.PUBLIC_URL + "/patrol"}
                                style={{ color: 'white' }}>
                                <div className="likes-profile text-center">{"Kembali"}</div>

                            </Link>
                        </Btn>
                    </div>
                    <div className="project-list col-sm-12">
                        <Card>
                            <div className="card-header pb-0">

                                <H4>

                                    Data Riwayat Patroli</H4>
                            </div>
                            <CardBody>

                                <span>Halaman yang berisi semua data riwayat Patroli dari PT. Mitra Abadi Setia 57</span>
                            </CardBody>
                        </Card>
                    </div>
                </div>
                <Col xxl="12" xl="12" className="box-col-12">
                    <Row>
                        <Col md="3 mb-3">
                            <Label className="col-sm-4 col-form-label text-end">Pilih Tanggal</Label>
                            <InputGroup className="date" id="dt-minimum" data-target-input="nearest">
                                <DatePicker
                                    locale="id"
                                    className="form-control"
                                    selected={startDate}
                                    onChange={handleChange}
                                    dateFormat="dd-MM-y"
                                />
                            </InputGroup>
                        </Col>
                        <Col md="3 mb-3">
                            <Label className="col-sm-3 col-form-label text-end">Pilih Area</Label>
                            {isSuccessArea && areas ?
                                <Select
                                    options={areaList}
                                    onChange={onInputAreaChange}
                                    value={selectedareaOption}
                                    isClearable
                                    className="js-example-basic-single col-sm-12" />
                                :
                                <p>Tidak Ada Data area</p>
                            }

                        </Col>
                        <Col md="3 mb-3">
                            <Label className="col-sm-4 col-form-label text-end">Pilih Petugas</Label>
                            {isSuccessArea && guards ?
                                <Select
                                    options={guardList}
                                    onChange={onInputGuardChange}
                                    value={selectedguardOption}
                                    isClearable
                                    className="js-example-basic-single col-sm-12" />
                                :
                                <p>Tidak Ada Data Petugas</p>
                            }

                        </Col>
                        <Col md="3 mb-3">
                            <Label className="col-sm-5 col-form-label text-end">Cetak Laporan</Label>
                            <Btn attrBtn={{ color: 'primary', type: 'submit', onClick: (e) => createPDF(e), className: "align-items-center justify-content-center" }}>{'Export Data ke PDF'}</Btn>

                        </Col>
                    </Row>

                    <ReportPdfPatrol
                        isloadingData={isloadingData}
                        searchResults={searchResults}
                        startDate={startDate}
                    />
                </Col>
            </Container>
        </Fragment>
    );
};

export default ReportPage;