import { Link } from "react-router-dom";


export const tableColumns = [
    {
        name: 'Id',
        selector: row => row['id'],
        sortable: true,
        center: true,
    },
    {
        name: 'Nama',
        selector: row => row['driver_name'],
        sortable: true,
        center: false,
    },
    {
        name: 'Area',
        selector: row => `${row.area_id}`,
        sortable: true,
        center: true,
    },
    {
        name: 'Waktu',
        cell: (row) => <span className='badge badge-light-primary'>${row.time_arrival}</span>,
        sortable: true,
        center: true,
    },
    {
        name: 'Aksi',
        cell: (row) => <span><Link to={`${process.env.PUBLIC_URL}/logistic/${row.id}`}>Detail</Link></span>,
        ignoreRowClick: true,
        allowOverflow: true,
        button: true,
    },

];