import { useContext, useEffect } from 'react';
import { Card, Col, Row, CardBody } from 'reactstrap';
import { H6, Image, LI, P, UL, Btn } from '../../../AbstractElements';
import JobSearchContext from '../../../Helper/JobSearch';
import CardsPagination from './CardsPagination';
import { dynamicImage } from "../../../Services/index"
import { useGetClientsQuery } from "../../../Services/clientApiSlice"

import { Link } from 'react-router-dom';
import { useDispatch } from 'react-redux';
const CardsClass = ({ refresh, setRefresh, searchTerm }) => {
  const { jobData } = useContext(JobSearchContext);
  const dispatch = useDispatch();
  const {
    data: clients,
    isLoading,
    isSuccess,
    isError,
    error,
    refetch
  } = useGetClientsQuery('getClients')

  useEffect(() => {
    dispatch(refetch);
    setRefresh(false);
  }, [refresh, dispatch]);

  let content;
  if (isLoading) {
    content = <p>"Loading..."</p>;
  } else if (isSuccess) {
    if (searchTerm != "") {
      const filteredData = clients.data.filter(item =>
        item.name.toLowerCase().includes(searchTerm.toLowerCase())
      );
      content = filteredData.map((item, i) => <Col xl="6" className="box-col-6" key={i}>
        <Card
          className='ribbon-vertical-left-wrapper'
        >

          <div className="ribbon ribbon-bookmark ribbon-vertical-left ribbon-secondary">
            <i className="icofont icofont-user-alt-2"></i>
          </div>

          <div className="job-search">
            <CardBody>
              <div className='d-sm-flex d-block align-items-start'>
                <Image
                  attrImage={{
                    className: 'img-40 img-fluid m-r-20',
                    src: dynamicImage('job-search/1.jpg'),
                    alt: '',
                  }}
                />
                <div className="flex-grow-1">
                  <H6 attrH6={{ className: 'f-w-600' }} >
                    <Link to={process.env.PUBLIC_URL + "/client/" + item.id + "/client-detail"}>{item.name}</Link>
                    {/* <span className="pull-right">
                      2 days ago
                      </span> */}
                    {(item.verified) ? <span className="badge badge-primary pull-right">Aktif</span> : <span className="badge badge-danger pull-right">Non-Aktif</span>}

                  </H6>
                  <P>
                    {item.email}
                  </P>
                </div>
              </div>
              <P attrPara={{ className: 'mb-1' }}>Klik tombol dibawah untuk mendapatkan informasi lebih lengkap.</P>
              <Link to={process.env.PUBLIC_URL + "/client/" + item.id + "/client-detail"}>
                <Btn attrBtn={{
                  className: "btn-pill btn-air-primary",
                  color: "primary",
                  activ: true,
                  disab: true,
                  outli: true
                }}>
                  Details
                </Btn>
              </Link>
            </CardBody>
          </div>
        </Card>
      </Col>)
    } else {
      content = clients.data.map((item, i) => <Col xl="6" className="box-col-6" key={i}>
        <Card
          className='ribbon-vertical-left-wrapper'
        >

          <div className="ribbon ribbon-bookmark ribbon-vertical-left ribbon-secondary">
            <i className="icofont icofont-user-alt-2"></i>
          </div>

          <div className="job-search">
            <CardBody>
              <div className='d-sm-flex d-block align-items-start'>
                <Image
                  attrImage={{
                    className: 'img-40 img-fluid m-r-20',
                    src: dynamicImage('job-search/1.jpg'),
                    alt: '',
                  }}
                />
                <div className="flex-grow-1">
                  <H6 attrH6={{ className: 'f-w-600' }} >
                    <Link to={process.env.PUBLIC_URL + "/client/" + item.id + "/client-detail"}>{item.name}</Link>
                    {/* <span className="pull-right">
                      2 days ago
                      </span> */}
                    {(item.verified) ? <span className="badge badge-primary pull-right">Aktif</span> : <span className="badge badge-danger pull-right">Non-Aktif</span>}

                  </H6>
                  <P>
                    {item.email}
                  </P>
                </div>
              </div>
              <P attrPara={{ className: 'mb-1' }}>Klik tombol dibawah untuk mendapatkan informasi lebih lengkap.</P>
              <Link to={process.env.PUBLIC_URL + "/client/" + item.id + "/client-detail"}>
                <Btn attrBtn={{
                  className: "btn-pill btn-air-primary",
                  color: "primary",
                  activ: true,
                  disab: true,
                  outli: true
                }}>
                  Details
                </Btn>
              </Link>
            </CardBody>
          </div>
        </Card>
      </Col>)
    }
  } else if (isError) {
    content = <p>{error}</p>;
  }

  //  console.log(clients)
  return (
    <Col xxl="12" xl="12" className="box-col-8">
      <Row>
        {isSuccess ? (clients ?
          content :
          <p>Tidak Ada Data Klien.</p>) : ''}
      </Row>
      <CardsPagination />
    </Col>

  );
};

export default CardsClass;