import React, { Fragment } from 'react'
import AddGuardForm from './AddGuardForm'
import { Breadcrumbs } from '../../../AbstractElements'
import { Col, Container, Row } from 'reactstrap'

const index = () => {
  return (
    <Fragment>
      <Breadcrumbs mainTitle="Tambah Data Petugas" parent="Guards" title="Guards Add" />
      <Container fluid={true}>
        <div className="edit-profile typeahead">
          <Row>
            <Col xl="12" lg="12">
              <AddGuardForm/>
            </Col>
          </Row>
        </div>
      </Container>
    </Fragment>
  )
}

export default index