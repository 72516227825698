import { Btn, H4, Image } from '../../../AbstractElements';
import { Dropzone } from 'react-dropzone-uploader';
import { AddGuard, Company, Username, UsersCountryMenu, AboutMe, SimpanDatalGuard, FirstName, LastName, Address, EmailAddress, PostalCode, Country, City } from '../../../Constant';
import React, { Fragment, useState, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { Typeahead } from "react-bootstrap-typeahead";
import { toast } from 'react-toastify';
import { Row, Col, CardHeader, CardBody, CardFooter, Form, FormGroup, Label, Input } from 'reactstrap';
import user from '../../../assets/images/user/7.jpg';
import { useGetGuardsByofficerIdQuery } from '../../../Services/securityOfficerApiSlice';
import { useNavigate } from 'react-router';
import axios from "axios";
import { dynamicImage } from "../../../Services"
import { useParams } from 'react-router';

const EditGuardForm = () => {
  // const [addNewGuards, { isLoading }] = useAddNewGuardsMutation()
  const { id } = useParams(); // id as officer Id
  const { register, handleSubmit, formState: { errors }, reset  } = useForm();
  const { ref, ...sex } = register("sex");
  const regionName = register('region')
  const [regions, setRegions] = useState([])
  const apiBaseUrl = process.env.REACT_APP_API_BASE_URL;
  const {
    data: guards,
    isLoading,
    isSuccess,
    isError,
    error,
    refetch
  } = useGetGuardsByofficerIdQuery({ guardId: id })

  // if (isSuccess) {
  //   reset({
  //     name: guards.name,
  //     username: guards.username,
  //     password: guards.password,
  //     phone: guards.phone,
  //     sex: guards.sex
  //   })
  // }
  
  const navigate = useNavigate()
  const onRegionChanged = (e) => setRegions(e.target.value)


  const handleInputChange = (input, e) => {
    console.log("value", input)
  }

  const handleChange = (selectedOptions) => {
    setRegions(selectedOptions)
    console.log("data : ", selectedOptions);
  }


  const onUpdateClicked = async (data) => {
    console.log(data)
    try {
      // console.log(data.image[0]);
      console.log(
        {
          name: data.name,
          Username: data.username,
          password: data.password,
          phone: data.phone,
          sex: data.sex,
          status_active: "1",
          photo: data.img ? data.img[0] : ""
        }
      );
      // await addNewGuards(
      //   {
      //     name: data.name,
      //     Username: data.username,
      //     password: data.password,
      //     phone: data.phone,
      //     sex: data.sex,
      //     status_active: "1",
      //     photo: data.img ? data.img[0] : ""
      // //     //region: regions, //nanti kalau database udah di update di tambah data region nya untuk mempermudah penjadwalan.
      //   }).unwrap()
      const formData = new FormData();
      formData.append('Name', data.name);
      formData.append('Username', data.username);
      formData.append('Password', data.password);
      formData.append('Phone', data.phone);
      formData.append('Sex', data.sex);
      formData.append('StatusActive', "1");
      formData.append('photo', data.img ? (data.img.length > 0 ? data.img[0] : null) : null);
      const response = await axios.post(`${apiBaseUrl}/guard/update/${id}`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      navigate(`${process.env.PUBLIC_URL}/guard`)
      // setRefresh(true)
      toast.success("Berhasil Menyimpan Data Petugas.");
      console.log(response)
      // toggle()
    } catch (err) {
      toast.error("Gagal Menyimpan Data Petugas.");
      console.error('Failed to save the post', err)
    }
    console.log(data, regions)
  };
  return (
    <Fragment>
      <Form className="card theme-form" onSubmit={handleSubmit(onUpdateClicked)}>
        <CardHeader className="pb-0">
          <H4 attrH4={{ className: 'card-title mb-0' }}>{"Edit Data Petugas"}</H4>
          <div className="card-options">
            <a className="card-options-collapse" href="#javascript">
              <i className="fe fe-chevron-up"></i>
            </a>
            <a className="card-options-remove" href="#javascript">
              <i className="fe fe-x"></i>
            </a>
          </div>
        </CardHeader>
        <CardBody>
          <Row>
            <Col md="12">
              <Label className="form-label">{"Foto Petugas"}</Label>
              {isSuccess && guards ?
                <div className="profile-title">
                  <div className="d-lg-flex d-block align-items-center">
                    <Image attrImage={{ className: "img-70 rounded-circle", src: `${apiBaseUrl}${guards.data.photo}` }} alt="image" />
                  </div>
                </div> :
                <p>Tidak ada foto profil yang tersimpan.</p>
              }

              <FormGroup>
                <Label className="form-label">{"* Biarkan Kosong Jika tidak ingin ganti foto profil petugas."}</Label>

                <input className="form-control" type="file" {...register("img")} />
              </FormGroup>
            </Col>
            <Col md="4">
              <FormGroup className="mb-3">
                <Label className="form-label">{"Nama Lengkap Petugas"}</Label>
                {isSuccess && guards ?
                  <>
                    <input className="form-control" defaultValue={guards.data.name} type="text" name="name" placeholder="Nama Petugas" {...register('name', { required: true })} /><span style={{ color: 'red' }}>{errors.name && 'Nama is required'} </span>
                  </>
                  :
                  <>
                    <input className="form-control" type="text" name="name" placeholder="Nama Petugas" {...register('name', { required: true })} /><span style={{ color: 'red' }}>{errors.name && 'Nama is required'} </span>
                  </>
                }
              </FormGroup>
            </Col>

            <Col sm="4" md="4">
              <FormGroup>
                <Label className="form-label">{"No Telepon"}</Label>
                {isSuccess && guards ?
                  <>
                    <input className="form-control m-input" defaultValue={guards.data.phone} name="phone" type="tel" placeholder="No Telepon" {...register('phone', { required: true })} /><span style={{ color: 'red' }}>{errors.phone && 'No Telepon is required'} </span>
                  </>
                  :
                  <>
                    <input className="form-control m-input" name="phone" type="tel" placeholder="No Telepon"  {...register('phone', { required: true })} /><span style={{ color: 'red' }}>{errors.phone && 'No Telepon is required'} </span>

                  </>
                }
              </FormGroup>

            </Col>
            <Col sm="4" md="4">
              <FormGroup>
                <Label className="form-label">{"Jenis Kelamin"}</Label>
                <Col>
                  <div className="m-t-15 m-checkbox-inline mb-0 custom-radio-ml">

                    {isSuccess && guards ?
                      <>
                        <div className="custom-control radio custom-radio radio-primary">
                          {guards.data.sex == "L" ?
                            <Input className="radio_animated custom-control-input me-2" id="customControlValidation2" type="radio" name="radio-stacked" value="L" innerRef={ref} {...sex} checked />
                            :
                            <Input className="radio_animated custom-control-input me-2" id="customControlValidation2" type="radio" name="radio-stacked" value="L" innerRef={ref} {...sex} />
                          }
                          <Label className="custom-control-label" htmlFor="customControlValidation2">{'Laki-Laki'}</Label>
                        </div>
                        <div className="custom-control radio custom-radio radio-primary mb-3">
                          {guards.data.sex == "P" ?
                            <Input className="radio_animated custom-control-input me-2" id="customControlValidation3" type="radio" name="radio-stacked" value="P" innerRef={ref} {...sex} checked />
                            :
                            <Input className="radio_animated custom-control-input me-2" id="customControlValidation3" type="radio" name="radio-stacked" value="P" innerRef={ref} {...sex} />
                          }
                          <Label className="custom-control-label" htmlFor="customControlValidation3">{'Perempuan'}</Label>
                          <div className="invalid-feedback">{'More example invalid feedback text'}</div>
                        </div>
                      </>
                      :
                      <>
                        <div className="custom-control radio custom-radio radio-primary">
                          <Input className="radio_animated custom-control-input me-2" id="customControlValidation2" type="radio" name="radio-stacked" value="L" innerRef={ref} {...sex} />
                          <Label className="custom-control-label" htmlFor="customControlValidation2">{'Laki-Laki'}</Label>
                        </div>
                        <div className="custom-control radio custom-radio radio-primary mb-3">
                          <Input className="radio_animated custom-control-input me-2" id="customControlValidation3" type="radio" name="radio-stacked" value="P" innerRef={ref} {...sex} />
                          <Label className="custom-control-label" htmlFor="customControlValidation3">{'Perempuan'}</Label>
                          <div className="invalid-feedback">{'More example invalid feedback text'}</div>
                        </div>
                      </>
                    }

                  </div>
                </Col>
              </FormGroup>
            </Col>
            <Col md="12">
              <Row>
                <Col sm="6" md="3">
                  <FormGroup>
                    <Label className="form-label">{"Username"}</Label>
                    {isSuccess && guards ?
                      <>
                        <input className="form-control" defaultValue={guards.data.username} type="text" placeholder="Username" {...register('username', { required: true })} /><span style={{ color: 'red' }}>{errors.Username && 'Username is required'} </span>
                      </>
                      :
                      <>
                        <input className="form-control" type="text" placeholder="Username" {...register('username', { required: true })} /><span style={{ color: 'red' }}>{errors.Username && 'Username is required'} </span>
                      </>
                    }
                  </FormGroup>
                </Col>
                <Col sm="6" md="4">
                  <FormGroup>
                    <Label className="form-label">{"Password"}</Label>
                    <input className="form-control" type="text" placeholder="Password" {...register('password')} /><span style={{ color: 'red' }}>{errors.EmailAddress && 'EmailAddress is required'} </span>
                    <p><small>*Biarkan kosong jika tidak ingin mengubah password petugas.</small></p>
                  </FormGroup>
                </Col>
              </Row>

            </Col>

          </Row>
        </CardBody>
        <CardFooter className="text-end">
          <Btn attrBtn={{ color: 'primary', type: 'submit' }}>{SimpanDatalGuard}</Btn>
        </CardFooter>
      </Form>
    </Fragment>
  );
};
export default EditGuardForm;