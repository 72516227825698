import { createApi, fetchBaseQuery} from '@reduxjs/toolkit/query/react'
// import { setCredentials, logOut } from '../../Services/authSlice'
import { setCredentials, logOut } from '../../features/auth/authSlice'
import Cookies from 'js-cookie';
const refreshToken = Cookies.get('refreshToken')
// 'http://localhost:8080/api/v1'
// "http://www.mas57.mitraabadisetia.co.id:8080/api/v1"
const baseQuery = fetchBaseQuery({
    baseUrl: 'http://www.mas57.mitraabadisetia.co.id:8080/api/v1',
    // credentials: 'include',
    credentials: "same-origin",
    headers: {
        'Origin' : 'https://mas57administrator.mitraabadisetia.co.id',
        'Cookie' : refreshToken
    },
    prepareHeaders: (headers, {getState}) => {
        const token = getState().auth.token
        
        headers.set("Content-type", `application/json; charset=UTF-8; `)
        headers.set("Origin", 'https://mas57administrator.mitraabadisetia.co.id')
        headers.set("Host", 'http://www.mas57.mitraabadisetia.co.id:8080')
        // headers.set('Access-Control-Allow-Headers','Access-Control-Allow-Headers, Content-Type, Authorization')
        // headers.set('Access-Control-Allow-Origin', '*')
        // headers.set("Cookie", `refreshToken=${refreshToken}`)
        if (token) {          
            headers.set("authorization", `Bearer ${token}`)
        }

        // if (refreshToken){
        //     headers.set("Cookie", `refreshToken=${refreshToken}`)
        // }
        return headers
    }
})

const baseQueryWithReauth = async (args, api, extraOptions) => {
    let result = await baseQuery(args, api, extraOptions)

    if (result?.error?.originalStatus === 403) {
        console.log('sending refresh token')
        // send refresh token
        const refreshResult = await baseQuery('/auth/refresh', api, extraOptions)
        console.log(refreshResult)
        if (refreshResult?.data) {
            const user = api.getState().auth.user
            //store teh new token
            api.dispatch(setCredentials({ ...refreshResult.data, user}))

            // retry the original query with new access token
            result = await baseQuery(args, api, extraOptions)
        }
        else {
            api.dispatch(logOut)
        }
    }

    return result
}

export const apiSlice = createApi({
    reducerPath: 'api', // optional
    baseQuery: baseQueryWithReauth,
    tagTypes: ['Area', 'Client','Guard','Schedule','Patrol'],
    endpoints: builder => ({})
})