import {
    SubArea, TableSubAreaSpanText
} from "../../../Constant";
import axios from 'axios';
import { Col, Card, Row, Input, InputGroup } from "reactstrap";
import HeadingCommon from "../../../Common/Component/HeadingCommon";
import { Btn, H6 } from "../../../AbstractElements";
import { dummytabledata, tableColumns } from "../../../Data/DataTable/ContohDataSubarea";
import { useCallback, useContext, useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import AddSubareaModal from "./AddSubareaModal";
import SubareaContext from "../../../Helper/subarea";
import { useGetSubareaByAreaIdQuery, useGetSubareasQuery } from "../../../Services/subareaApiSlice";
import { useParams } from "react-router";
import { useDispatch } from "react-redux";
import { toast } from 'react-toastify';


const SubareaCard = ({ areaId, area }) => {
    const { client_id, id } = useParams();
    const [selectedRows, setSelectedRows] = useState([]);
    const [toggleDelet, setToggleDelet] = useState(false);
    const [data, setData] = useState(dummytabledata);
    const { editModal, seteditModal, setSubareaById } = useContext(SubareaContext);
    const [refresh, setRefresh] = useState(false);
    const dispatch = useDispatch();
    // add modal 
    const [modal, setModal] = useState(false);
    const toggle = () => setModal(!modal);
    const {
        data: subarea,
        isLoading,
        isSuccess,
        isError,
        error,
        refetch
    } = useGetSubareaByAreaIdQuery({ areaId: id })

    useEffect(() => {
        dispatch(refetch);
        setRefresh(false);
    }, [refresh, dispatch]);

    const handleRowSelected = useCallback(state => {
        setSelectedRows(state.selectedRows);
    }, []);

    const handleDelete = () => {
        if (window.confirm(`Are you sure you want to delete:\r ${selectedRows.map(r => r.title)}?`)) {
            setToggleDelet(!toggleDelet);
            setData(data.filter((item) => selectedRows.filter((elem) => elem.id === item.id).length > 0 ? false : true));
            setSelectedRows('');
        }
    };
    const editSubareaToggle = () => {
        seteditModal(!editModal);
    };
    const apiBaseUrl = process.env.REACT_APP_API_BASE_URL;

    const handleEditClick = async (event) => {
        var idSubArea = event.currentTarget.id;
        // const dataId = event.target.getAttribute('data-subarea');
        // console.log('data-id:', dataId);
        // console.log(idSubArea)
        if (idSubArea) {
            try {
                const response = await axios.get(`${apiBaseUrl}/subarea/${idSubArea}`); // Replace with your API endpoint
                // console.log(response)
                if (response.status == 200 && response.data) {
                    setSubareaById(response.data);
                    editSubareaToggle();
                } else {
                    toast.error("Gagal Mengambil data subarea.");
                }

            } catch (error) {
                toast.error("Gagal Mengambil data subarea.");
                console.error('Error fetching search results:', error);
            }
        }

        // setEditrow(state.target.id);

    };
    //   console.log(subarea);
    return (
        <Card className="mb-2">
            <HeadingCommon Heading={SubArea} span={TableSubAreaSpanText} />
            <div className="social-tab">
                <Btn attrBtn={{ color: "primary text-center me-2", type: "button", onClick: toggle }}>Tambah Sub Area</Btn>
                <AddSubareaModal modal={modal} NewMessage="Tambah Sub Area" toggle={toggle} areaId={areaId} refresh={refresh} setRefresh={setRefresh} area={area} />
                <InputGroup>
                    <Input type="text" placeholder="Cari Data....." />
                    <span className="input-group-text">
                        <i className="fa fa-search"></i>
                    </span>
                </InputGroup>
            </div>

            {(selectedRows.length !== 0) &&
                <div className={`d-flex align-items-center justify-content-between bg-light-info p-2`}>
                    <H6 attrH4={{ className: 'text-muted m-0' }}>Delete Selected Data?</H6>
                    <Btn attrBtn={{ color: 'danger', onClick: () => handleDelete() }}>Delete</Btn>
                </div>
            }
            <Row className="card-block">
                <Col sm="12" lg="12" xl="12">
                    <div className="table-responsive theme-scrollbar">
                        {isSuccess ?
                            (
                                <DataTable
                                    className='data-tables theme-scrollbar'
                                    data={subarea.data}
                                    columns={tableColumns(handleEditClick)}
                                    striped={true}
                                    center={true}
                                    pagination
                                    selectableRows
                                    onSelectedRowsChange={handleRowSelected}
                                    clearSelectedRows={toggleDelet}
                                />
                            )
                            : <p>No data Found.</p>
                        }

                    </div>
                </Col>
            </Row>
        </Card>
    )
}

export default SubareaCard