import React, { Fragment } from 'react'
import SubareaCard from './SubareaCard'
import { Breadcrumbs } from '../../../AbstractElements'
import { Col, Row } from 'reactstrap'
import { useParams } from 'react-router'
import EditSubareaModal from './EditSubareaModal'
import { useGetAreasByIdQuery } from '../../../Services/areaApiSlice'


const Subarea = () => {
    const { client_id, id } = useParams(); // id as areaId
    // console.log(client_id, id);
    const {
        data: areas,
        isLoading,
        isSuccess,
        isError,
        error,
        refetch
    } = useGetAreasByIdQuery({ id: id })
    console.log(areas)
    return (
        <Fragment>
            <Breadcrumbs mainTitle="Sub Area" parent="Client" subParent="Area" title="Subarea" />
            <Row>
                <Col xxl="12" xl="12" md="12" className="box-col-12">
                    <div className="email-right-aside bookmark-tabcontent">
                        {isLoading ? 
                            <h4>Loading ...</h4>
                        :
                            isSuccess ? 
                            <SubareaCard areaId = {id} area = {areas}/>
                                :
                            isError ?
                            <p>Oops.. Sepertinya Server bermasalah.</p>
                            : 
                            <p>Oops.. Ada Masalah Sistem.</p>
                        }
                       
                    </div>
                </Col>
            </Row>
            <EditSubareaModal area = {areas}/>
        </Fragment>
    )
}

export default Subarea