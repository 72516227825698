import React, { useCallback, useEffect, useRef, useState } from 'react'
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import { Card, CardBody, CardHeader, Col, Container, Form, FormGroup, Input, Label, Row } from 'reactstrap';
import { Btn, H4 } from '../../../AbstractElements';
import Modals from '../../../Common/Modals';
import { Close, Save } from '../../../Constant';
import { useAddNewSubareaMutation } from '../../../Services/subareaApiSlice';
import { GoogleMap, LoadScript, Marker, Polygon } from '@react-google-maps/api'
const AddSubareaModal = ({ modal, NewMessage, toggle, areaId, refresh, setRefresh, area }) => {
    const [addNewSubarea, { isLoading }] = useAddNewSubareaMutation()
    const { register, handleSubmit, formState: { errors }, setValue  } = useForm();
    const { ref, ...desc } = register("desc")
    const [markers, setMarkers] = useState([])
    const polygonRef = useRef(null);
    const listenersRef = useRef([]);
    let colorArray = ['Red', 'Green', 'Yellow', 'Blue', 'Pink', 'Orange'];
    let points = []
    if (area.area.coordinate) {
        points = JSON.parse(area.area.coordinate.String)
    }
    let centerDefault = {
        lat: -6.9174639,
        lng: 107.6191228
    }

    const containerStyle = {
        height: "500px",
    };

    useEffect(() => {
        if (area.coordinate) {
            points = JSON.parse(area.area.coordinate.String)
        }
    }, [area.area.id])

    const [point, setPoint] = useState(points)
    // console.log(points);

    var new_path = JSON.stringify(point);
    const center = points[0] ?? centerDefault;
    // Call setPath with new edited path
    // Call setPath with new edited path
    const onEdit = useCallback(() => {

    }, []);

    // Bind refs to current Polygon and listeners
    const onLoad = useCallback(
        polygon => {
            setPoint(points)
            polygonRef.current = polygon;
            const path = polygon.getPath();
            listenersRef.current.push(
                path.addListener("set_at", onEdit),
                path.addListener("insert_at", onEdit),
                path.addListener("remove_at", onEdit)
            );
        },
        [onEdit, points, setPoint]
    );
    // Clean up refs
    const onUnmount = useCallback(() => {
        listenersRef.current.forEach(lis => lis.remove());
        polygonRef.current = null;
    }, []);

    const onPolygonClick = (e) => {
        setMarkers((current) => [
            {
                lat: e.latLng.lat(),
                lng: e.latLng.lng()
            }
        ]);
        setValue("latitude", e.latLng.lat())
        setValue("longitude", e.latLng.lng())
    };

    const onSaveClicked = async (data) => {

        try {
            await addNewSubarea(
                {
                    area_id: areaId,
                    no_card: parseInt(data.no_card),
                    Name: data.namaSubarea,
                    Description: data.desc,
                    Address: data.address,
                    Latitude: JSON.stringify(data.latitude),
                    Longitude: JSON.stringify(data.longitude),
                    level_area: "1"
                }
            ).unwrap()
            // navigate('/')
            setRefresh(true)
            toast.success("Berhasil Menyimpan Data Area.");
            toggle()
        } catch (err) {
            toast.error("Gagal Menyimpan Data Area.");
            console.error('Failed to save the post', err)
        }
    }

    return (
        <Modals isOpen={modal} title={NewMessage} toggler={toggle} onSubmit={false} size="xl">
            <Container fluid={true}>
                <Row>
                    <Col sm="12" xl="6">
                        <Row>
                            <Col sm="12">
                                <Card>
                                    <CardHeader className="pb-0">
                                        <H4>Tampilan Map</H4>
                                        <span>{'Cari Berdasarkan Alamat, '}
                                            <a href="#javascript">{'Klik lokasi yang dituju pada Map'}</a> {'untuk mendapatkan koordinat lokasi.'}
                                        </span>
                                    </CardHeader>
                                    <CardBody>
                                        <div className="map-js-height">
                                            <div id="gmap-simple" className="map-block">
                                                <LoadScript
                                                    id="script-loader"
                                                    googleMapsApiKey={process.env.REACT_APP_GOOGLEAPI}
                                                    language="id"
                                                    region="id"
                                                >

                                                    <GoogleMap
                                                        mapContainerStyle={containerStyle}
                                                        center={center}
                                                        zoom={19}
                                                        onClick={ev => {
                                                            toast.error("Pilih koordinat didalam Area yang sudah dibuat..");
                                                        }}
                                                    >
                                                        <Polygon
                                                            path={points}
                                                            editable
                                                            onMouseUp={onEdit}
                                                            onLoad={onLoad}
                                                            onUnmount={onUnmount}
                                                            options={{
                                                                fillColor: 'blue',
                                                                strokeColor: `#0a6ebd`,
                                                                fillOpacity: 0.5,
                                                                strokeWeight: 2
                                                            }}
                                                            onClick={onPolygonClick}
                                                        />
                                                        { 
                                                            markers.map((marker) => (
                                                            <Marker
                                                                position={{
                                                                    lat: marker.lat,
                                                                    lng: marker.lng
                                                                }} key={marker.lat}/>
                                                        ))}
                                                    </GoogleMap>

                                                </LoadScript>
                                            </div>
                                        </div>
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                    </Col>
                    <Col sm="12" xl="6">
                        <Row>
                            <Col sm="12">
                                <Card>
                                    <CardHeader className="pb-0">
                                        <H4>Form Data Subarea</H4>
                                        <span>{'*Wajib diisi.'}
                                        </span>
                                    </CardHeader>
                                    <Form
                                        className="form-bookmark needs-validation"
                                        onSubmit={handleSubmit(onSaveClicked)}
                                    >
                                        <Row>
                                            <Col md="6 mb-3">
                                                <FormGroup>
                                                    <Label className="col-form-label" for="recipient-name">{'Nama Sub-area*'}</Label>
                                                    <input
                                                        className="form-control"
                                                        type="text"
                                                        placeholder="Nama Subarea"
                                                        autoComplete="off"
                                                        name="namaSubarea"
                                                        {...register('namaSubarea', { required: true })}
                                                    />
                                                    <span>{errors.namaSubarea && 'Nama Sub-area is required'}</span>
                                                    <div className="valid-feedback">{'Looks good!'}</div>
                                                </FormGroup>
                                            </Col>
                                            <Col md="6 mb-3">
                                                <FormGroup>
                                                    <Label className="col-form-label">{'ID Kartu'}</Label>
                                                    <input className="form-control" type="number" placeholder="ID Kartu*" name="no_card"
                                                        {...register('no_card', { required: true })} />
                                                    <span>{errors.no_card && 'ID Kartu is required'}</span>
                                                    <div className="valid-feedback">{'Looks good!'}</div>
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col md="6 mb-3">
                                                <FormGroup>
                                                    <Label className="col-form-label" for="recipient-name">{'Lattitude*'}</Label>
                                                    <input
                                                        className="form-control"
                                                        type="text"
                                                        placeholder="lattitude"
                                                        autoComplete="off"
                                                        name="latitude"
                                                        {...register('latitude', { required: true })}
                                                    />
                                                    <span>{errors.latitude && 'Lattitude is required'}</span>
                                                    <div className="valid-feedback">{'Looks good!'}</div>
                                                </FormGroup>
                                            </Col>
                                            <Col md="6 mb-3">
                                                <FormGroup>
                                                    <Label className="col-form-label">{'Longitude*'}</Label>
                                                    <input className="form-control" type="text" placeholder="Longitude" name="longitude"
                                                        {...register('longitude', { required: true })} />
                                                    <span>{errors.longitude && 'Longitude is required'}</span>
                                                    <div className="valid-feedback">{'Looks good!'}</div>
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col md="6 mb-3">
                                                <FormGroup>
                                                    <Label className="col-form-label" for="message-text">{'address*'}</Label>
                                                    {/* <input type="textarea" className="form-control" name="address" id="message-text" placeholder="Alamat"  {...register('address', { required: true })}/> */}
                                                    <textarea className="form-control" name="address" id="message-text" placeholder="Alamat"  {...register('address', { required: true })} />
                                                </FormGroup>
                                            </Col>
                                            <Col md="6 mb-3">
                                                <FormGroup>
                                                    <Label className="col-form-label" for="message-text">{'Deskripsi (opsional):'}</Label>
                                                    <Input type="textarea" className="form-control" name="desc" id="message-text" placeholder="Deksripsi" innerRef={ref} {...desc} />
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                        <Btn attrBtn={{ color: 'primary', type: 'submit' }}>{Save}</Btn>&nbsp;&nbsp;
                                        <Btn attrBtn={{ color: 'secondary', onClick: toggle }} >{Close}</Btn>

                                    </Form>
                                </Card>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </Container>

        </Modals>
    )
}

export default AddSubareaModal