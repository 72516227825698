import { Btn, H5 } from '../../../AbstractElements';
import {  useState } from 'react';
import { Card, CardBody, CardHeader, Col, Collapse, Input, Label } from 'reactstrap';
import { Commission, Contract, Filters, FindJobs, Fulltime, Internship, Parttime, Temporary } from '../../../Constant';
import { MapPin } from 'react-feather';
import SearchBar from '../SearchBar';

const Filter = ({onSeacrhClicked, searchTerm, setSearchTerm, onSeacrhReset}) => {
  const [isFilter, setIsFilter] = useState(true);
 
  
  return (
      <Col xl="12">
        {/* <Card>
          <CardHeader>
            <H5 attrH5={{ className: 'mb-0 p-0' }} >
              <Btn attrBtn={{ className: 'btn btn-link ps-0', onClick: () => setIsFilter(!isFilter), color: 'transperant', databstoggle: 'collapse', databstarget: '#collapseicon', ariaexpanded: 'true', ariacontrols: 'collapseicon' }} >{Filters}</Btn>
            </H5>
          </CardHeader>
          <Collapse isOpen={isFilter}>
            <CardBody className="filter-cards-view animate-chk"> */}
              <div className="job-filter mb-3">
                <div className="faq-form">
                  <SearchBar searchTerm={searchTerm} setSearchTerm={setSearchTerm} />
                  <br/>
                  <Btn  attrBtn={{ color: 'primary', type: 'submit',  onClick: (e) => onSeacrhClicked(e), className: "d-flex align-items-center justify-content-center" }}>{'Cari'}</Btn>&nbsp;&nbsp;
                                        
                </div>
              </div>
              {/* <div className="job-filter">
                <div className="faq-form">
                  <Input  type="text" placeholder="Cari Lokasi.." />
                  <MapPin className="search-icon"/>
                </div>
              </div> */}
              
            {/* </CardBody>
          </Collapse>
        </Card> */}
      </Col>
  );
};

export default Filter;