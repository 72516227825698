import React, { useState } from 'react'
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import Modals from '../../Common/Modals';
import { Card,  CardHeader, Col, Container, Form, FormGroup, Input, Label, Row } from 'reactstrap';
import { Btn,H4, Image } from '../../AbstractElements';
import { Close, Save } from '../../Constant';
import user from '../../assets/images/user/1.jpg';
import {useAddNewClientMutation} from "../../Services/clientApiSlice";
import { useNavigate } from 'react-router-dom';
const AddClient = ( {modal, NewMessage, toggle }) => {

    const [addNewClient, { isLoading }] = useAddNewClientMutation()
    const { register,handleSubmit, formState: { errors } } = useForm();
    const { ref, ...desc } = register("desc");
    const navigate = useNavigate();
    
    const [dataInput, setDataInput ] = useState({})
    const [namaClient, setNamaClient] = useState('')
    const [email, setEmail] = useState('')
    const [phone, setPhone] = useState('')


    const onNameChanged =(e) => setNamaClient(e.target.value)
    const onEmailChanged = (e) => setEmail(e.target.value)
    const onPhoneChanged = (e) => setPhone(e.target.value)

    

    const canSave = [namaClient, email, phone].every(Boolean) && !isLoading;

    const onSaveClicked = async (e) => {
        e.preventDefault()
        if (canSave) {
            try {
                
                setDataInput({
                    email : email,
                    name : namaClient,
                    password : "password",
                    passwordConfirm: "password",
                    photo : "1.jpg",
                    role : "Client"
                })
                // console.log(dataInput)
                await addNewClient(dataInput).unwrap()
                setNamaClient('')
                setEmail('')
                setPhone('')
                toast.success("Berhasil Menyimpan Data Klien");
                navigate(`${process.env.PUBLIC_URL}/client`)
            } catch (err) {
                toast.error("Gagal Menyimpan Data Klien");

                console.error('Failed to save the post', err)
            }
        }
    }
    return (
        <Modals isOpen={modal} title={NewMessage} toggler={toggle} onSubmit={false} size="md">
            <Container fluid={true}>
                <Row>
                    <Col sm="12" xl="6">
                        <Row>
                            <Col sm="12">
                                <Card>
                                    <CardHeader className="pb-0">
                                        <H4>Form Data Client</H4>
                                        <span>{'*Wajib diisi.'}
                                        </span>
                                    </CardHeader>
                                    <Form
                                        className="form-bookmark needs-validation"
                                        onSubmit=""
                                    >
                                       <Row>
                                            <Col md="12">
                                                <Image attrImage={{ className: 'img-70 rounded-circle', alt: '', src: `${user}` }} />
                                            </Col>
                                            <Col md="12 mb-3">
                                                <FormGroup>
                                                    <Label className="col-form-label" for="recipient-name">{'Nama Client*'}</Label>
                                                    <input
                                                        className="form-control"
                                                        type="text"
                                                        placeholder="Nama Client"
                                                        autoComplete="off"
                                                        name="namaClient"
                                                        onChange={onNameChanged}
                                                        value={namaClient}
                                                        // {...register('namaClient', { required: true })}
                                                    />
                                                    <span>{errors.namaSubarea && 'Nama Client is required'}</span>
                                                    <div className="valid-feedback">{'Looks good!'}</div>
                                                </FormGroup>
                                            </Col>
                                            <Col md="12 mb-3">
                                                <FormGroup>
                                                    <Label className="col-form-label">{'Email'}</Label>
                                                    <Input className="form-control" type="email" placeholder="Email" name="email"
                                                        onChange={onEmailChanged}
                                                        value={email}
                                                        // {...register('email', { required: true })} 
                                                        />
                                                    <span>{errors.no_card && 'Email is required'}</span>
                                                    <div className="valid-feedback">{'Looks good!'}</div>
                                                </FormGroup>
                                            </Col>
                                            <Col md="12 mb-3">
                                                <FormGroup>
                                                    <Label className="col-form-label">{'No Telepon'}</Label>
                                                    <span>Format : 0857xxxxx</span>
                                                    <Input className="form-control" type="number" placeholder="No Telepon" name="phone"
                                                        onChange={onPhoneChanged}
                                                        value={phone}
                                                        required
                                                        // {...register('phone', { required: true })} 
                                                        />
                                                    <span>{errors.no_card && 'No Telepon is required'}</span>
                                                    <div className="valid-feedback">{'Looks good!'}</div>
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                        <Btn attrBtn={{ color: 'primary', type: 'submit',  onClick: (e) => onSaveClicked(e) ,  disabled:!canSave }}>{Save}</Btn>&nbsp;&nbsp;
                                        <Btn attrBtn={{ color: 'secondary', onClick: toggle }} >{Close}</Btn>

                                    </Form>
                                </Card>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </Container>

        </Modals>
    )
}

export default AddClient