import { createEntityAdapter } from "@reduxjs/toolkit";
import { apiSlice } from "../app/api/apiSlice"


const areaAdapter = createEntityAdapter()

const initialState = areaAdapter.getInitialState()

export const scheduleOfficerApiSlice = apiSlice.injectEndpoints({
    endpoints: builder => ({
        getSchedules: builder.query({
            query: () => '/schedule',
            method: 'GET',
            providesTags: (result, error, arg) => [
                { type: 'Schedule', id: "LIST" },
                ...result.data.map(id => ({ type: 'Schedule', id }))
            ]
        }),
        getSchedulesForCalendar: builder.query({
            query: () => '/schedule/fullCalendar',
            method: 'GET',
        }),
        getSchedulesById: builder.query({
            query: ({guardId}) => ({
                url: `/schedule?id_schedule=${guardId}`,
                method: 'GET',
               
            })
        }),
        getScheduleByAreaId: builder.query({
            query: ({areaId}) => ({
                url: `schedule/area/${areaId}`,
                method: 'GET',
            })
        }),
        getScheduleByOfficerId: builder.query({
            query: ({areaId}) => ({
                url: `schedule/officer/${areaId}`,
                method: 'GET',
            })
        }),
        addNewSchedules: builder.mutation({
            query: initialData => ({
                url: '/schedule',
                method: 'POST',
                body: {
                    ...initialData
                }
            }),
            async onQueryStarted(arg, { dispatch, queryFulfilled }) {
                try {
                    const { data } = await queryFulfilled
                    console.log(data)
                } catch (err) {
                    console.log(err)
                }
            }
        }),
    })
})

export const {
    useGetSchedulesQuery,
    useGetSchedulesByIdQuery,
    useGetSchedulesForCalendarQuery,
    useGetScheduleByOfficerIdQuery,
    useGetScheduleByAreaIdQuery,
    useAddNewSchedulesMutation
} = scheduleOfficerApiSlice 