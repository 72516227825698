import { Pagination, PaginationItem, PaginationLink, } from 'reactstrap';
import { Previous, Next, Href } from '../../../Constant';
const CardsPagination = ({ totalPages, currentPages, paginate }) => {
   
    const renderedItems = [];
    if (totalPages > 0) {
        for (let i = 1; i <= totalPages; i++) {
            if (currentPages == i) {
                renderedItems.push(
                    <PaginationItem active={true}>
                        <PaginationLink onClick={(e) => paginate(e, i)}>{i}</PaginationLink>
                    </PaginationItem>)
            } else {
                renderedItems.push(
                    <PaginationItem>
                        <PaginationLink onClick={(e) => paginate(e, i)}>{i}</PaginationLink>
                    </PaginationItem>)
            }

        }
    } else {
        renderedItems.push(<PaginationItem active={true}>
            <PaginationLink onClick={(e) => paginate(e, 1)} >{1}</PaginationLink>
        </PaginationItem>)
    }
    return (
        <div className="job-pagination">

            <Pagination aria-label="Page navigation example" className="pagination-primary">
                {currentPages == 1 ? 
                <PaginationItem disabled={true}>
                    <PaginationLink >{Previous}</PaginationLink>
                </PaginationItem>
                :
                <PaginationItem>
                    <PaginationLink onClick={(e) => paginate(e, (currentPages-1))}>{Previous}</PaginationLink>
                </PaginationItem>
                }
                
                {renderedItems.map((item, index) => (
                    <div key={index}>{item}</div>
                ))}

                {currentPages == totalPages ? 
                <PaginationItem disabled={true}>
                    <PaginationLink >{Next}</PaginationLink>
                </PaginationItem>
                :
                <PaginationItem>
                    <PaginationLink onClick={(e) => paginate(e, (currentPages+1))}>{Next}</PaginationLink>
                </PaginationItem>
                }

                {/* 
                <PaginationItem>
                    <PaginationLink href={Href}>{'1'}</PaginationLink>
                </PaginationItem>
                <PaginationItem>
                    <PaginationLink href={Href}>{'2'}</PaginationLink>
                </PaginationItem>
                <PaginationItem>
                    <PaginationLink href={Href}>{'3'}</PaginationLink>
                </PaginationItem>
                 */}
            </Pagination>

        </div>
    )
}

export default CardsPagination