import React, { Fragment, useState } from 'react'
import { RefreshCcw,PlusSquare } from 'react-feather';
import { Card, CardBody, Container, Col, Row } from 'reactstrap'
import { Breadcrumbs, Btn, H4 } from '../../AbstractElements'
import { createNewClients } from '../../Constant';
import { Link } from 'react-router-dom';
import SearchBar from './SearchBar';
import Sidebar from './Sidebar';
import CardsClass from './Cards';
import AddClient from './AddClient'; 

const Clients = () => {

  const [searchTerm, setSearchTerm] = useState('');
  // add modal 
  const [addmodal, setAddModal] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const toggleAddModal = () => setAddModal(!addmodal);

  const refreshData = () => {
    setRefresh(!refresh)
  }
  return (
    <Fragment>
      <Breadcrumbs mainTitle="Clients" parent="Clients" title="Clients" />
      <Container fluid={true}>
        <div className="row">
          <div className="project-list col-sm-12">
            <Card>
              <div className="card-header pb-0">
                <H4>Data Clients</H4><span>Halaman yang berisi semua data Clients dari PT. Mitra Abadi Setia 57</span>
              </div>
              <CardBody>
                <Row>
                  <Col md="4" className='p-0 d-flex'>
                    <SearchBar searchTerm={searchTerm} setSearchTerm={setSearchTerm} />
                    <Btn attrBtn={{ color: "success text-center me-2 mx-2", type: "button",  onClick: refreshData  }}><RefreshCcw /></Btn>
                  </Col>
                  
             
                  <Col md="6 p-0">
                    <p className="mb-0">
                    <Btn attrBtn={{ color: "primary text-center me-2", type: "button",  onClick: toggleAddModal  }}><PlusSquare />{createNewClients}</Btn>
                    <AddClient modal={addmodal} NewMessage="Tambah Sub Area" toggle={toggleAddModal}  />
                      {/* <Link className="btn btn-primary" style={{ color: 'white' }} to={`${process.env.PUBLIC_URL}/project/new-project`}>
                        <PlusSquare />{createNewClients}
                      </Link> */}
                    </p>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </div>
        </div>
        <Row>
          <CardsClass refreshData = {refresh} setRefresh = {setRefresh} searchTerm={searchTerm}/>
          {/* <Sidebar /> */}
        </Row>
      </Container>
    </Fragment>
  )
}

export default Clients