import React,{ useCallback, useContext, useEffect, useState }  from 'react'
import { Col, Card, Row} from "reactstrap";
import { tableColumns } from "../../Data/DataTable/GuardData";
import DataTable from "react-data-table-component";
import GuardContext from '../../Helper/guard';
import { useGetGuardsQuery } from "../../Services/securityOfficerApiSlice";
import { useDispatch } from "react-redux";
const GuardsCard = () => {
    const [selectedRows, setSelectedRows] = useState([]);
    const [toggleDelet, setToggleDelet] = useState(false);
    const [refresh, setRefresh] = useState(false);
    const dispatch = useDispatch();
    const { guard, editModal, seteditModal ,getSubareaById} = useContext(GuardContext);
    // add modal 
    const [modal, setModal] = useState(false);
    const toggle = () => setModal(!modal);

    const {
        data: guards,
        isLoading,
        isSuccess,
        isError,
        error,
        refetch
    } = useGetGuardsQuery('getGuards')


    useEffect(() => {
        dispatch(refetch);
        setRefresh(false);
      }, [refresh, dispatch]);

    const handleRowSelected = useCallback(state => {
        setSelectedRows(state.selectedRows);
    }, []);

   
    const editSubareaToggle = () => {
        seteditModal(!editModal);
    };
    const handleEditClick = (event) => {
        var idGuards = event.currentTarget.id;
        getSubareaById(idGuards);
        
        // setEditrow(state.target.id);
        editSubareaToggle();
      };
    return (
        <Card className="mb-2">
            <Row className="card-block">
                <Col sm="12" lg="12" xl="12">
                    <div className="table-responsive theme-scrollbar">
                    { isSuccess ? 
                        (
                        <DataTable
                            className='data-tables theme-scrollbar'
                            data={guards.data}
                            columns={tableColumns}
                            striped={true}
                            center={true}
                            pagination
                            selectableRows
                            onSelectedRowsChange={handleRowSelected}
                            clearSelectedRows={toggleDelet}
                        />
                        )
                        : <p>No data Found.</p>
                        }
                    </div>
                </Col>
            </Row>
        </Card>
    )
}

export default GuardsCard