import React, { useContext, useCallback, useEffect, useRef, useState } from 'react'
import { Card, CardBody, CardHeader, Col, Container, Form, Row } from 'reactstrap';
import { useForm } from 'react-hook-form';
import axios from "axios";
import { useParams } from 'react-router';
import { Btn, H4 } from '../../../AbstractElements';
import Modals from '../../../Common/Modals';
import { Close, Save } from '../../../Constant';
import SubareaContext from '../../../Helper/subarea';
import EditFormSubarea from './EditFormSubarea';
import { GoogleMap, LoadScript, Marker, Polygon } from '@react-google-maps/api'
import { toast } from 'react-toastify'
const EditSubareaModal = ({ area }) => {
    const { editModal, seteditModal, subareaById } = useContext(SubareaContext);
    const { register, handleSubmit, reset, formState, setValue } = useForm();
    const editToggle = () => {
        seteditModal(!editModal);
    };

    const [markers, setMarkers] = useState([
        {
            lat: subareaById.area.latitude,
            lng: subareaById.area.longitude
        }
    ])
    const polygonRef = useRef(null);
    const listenersRef = useRef([]);
    let colorArray = ['Red', 'Green', 'Yellow', 'Blue', 'Pink', 'Orange'];
    let points = []
    if (area.area.coordinate) {
        points = JSON.parse(area.area.coordinate.String)
    }
    let centerDefault = {
        lat: -6.9174639,
        lng: 107.6191228
    }

    const containerStyle = {
        height: "500px",
    };

    useEffect(() => {
        if (area.coordinate) {
            points = JSON.parse(area.area.coordinate.String)
        }

    }, [area.area.id])

    const [point, setPoint] = useState(points)
    // console.log(points);

    var new_path = JSON.stringify(point);
    const center = points[0] ?? centerDefault;

    const apiBaseUrl = process.env.REACT_APP_API_BASE_URL;

    // Call setPath with new edited path
    const onEdit = useCallback(() => {

    }, []);

    // Bind refs to current Polygon and listeners
    const onLoad = useCallback(
        polygon => {
            setPoint(points)
            polygonRef.current = polygon;
            const path = polygon.getPath();
            listenersRef.current.push(
                path.addListener("set_at", onEdit),
                path.addListener("insert_at", onEdit),
                path.addListener("remove_at", onEdit)
            );
        },
        [onEdit, points, setPoint]
    );
    // Clean up refs
    const onUnmount = useCallback(() => {
        listenersRef.current.forEach(lis => lis.remove());
        polygonRef.current = null;
    }, []);

    const onPolygonClick = (e) => {
        setMarkers((current) => [
            {
                lat: e.latLng.lat(),
                lng: e.latLng.lng()
            }
        ]);
        setValue("latitude", e.latLng.lat())
        setValue("longitude", e.latLng.lng())
    };

    //   console.log(subareaById);
    const onUpdateClicked = async (data) => {
        console.log("testing click :", data)

        let body = JSON.stringify({
            "area_id": subareaById?.area?.area_id,
            "no_card": data.no_card,
            "name": data.namaSubarea,
            "description": data.deskripsi,
            "address": data.address,
            "latitude": data.latitude,
            "longitude": data.longitude,
            "level_area": "1",
        });
        try {


            let config = {
                method: 'post',
                maxBodyLength: Infinity,
                url: `${apiBaseUrl}/subarea/update/${subareaById?.area?.id}`,
                headers: {
                    'Content-Type': 'application/json'
                },
                data: body
            };

            const response = await axios.request(config)
                .then((response) => {
                    console.log(JSON.stringify(response))
                    toast.success("Berhasil Update Data Subarea")
                })
                .catch((error) => {
                    toast.error("Gagal Update Data Subarea")
                    console.log(error);
                });


        } catch (error) {

            console.error('Error fetching search results:', error);
        }
        seteditModal(!editModal);
    }

    return (
        <Modals isOpen={editModal} title="Edit Subarea" toggler={editToggle} size="xl">
            <Container fluid={true}>
                <Row>
                    <Col sm="12" xl="6">
                        <Row>
                            <Col sm="12">
                                <Card>
                                    <CardHeader className="pb-0">
                                        <H4>Tampilan Map</H4>
                                        <span>{'Cari Berdasarkan Alamat, '}
                                            <a href="#javascript">{'Klik lokasi yang dituju pada Map'}</a> {'untuk mendapatkan koordinat lokasi.'}
                                        </span>
                                    </CardHeader>
                                    <CardBody>
                                        {/* maps */}
                                        <div className="map-js-height">
                                            <div id="gmap-simple" className="map-block">
                                                <LoadScript
                                                    id="script-loader"
                                                    googleMapsApiKey={process.env.REACT_APP_GOOGLEAPI}
                                                    language="id"
                                                    region="id"
                                                >

                                                    <GoogleMap
                                                        mapContainerStyle={containerStyle}
                                                        center={center}
                                                        zoom={19}
                                                        onClick={ev => {
                                                            toast.error("Pilih koordinat didalam Area yang sudah dibuat..");
                                                        }}
                                                    >
                                                        <Polygon
                                                            path={points}
                                                            editable
                                                            onMouseUp={onEdit}
                                                            onLoad={onLoad}
                                                            onUnmount={onUnmount}
                                                            options={{
                                                                fillColor: 'blue',
                                                                strokeColor: `#0a6ebd`,
                                                                fillOpacity: 0.5,
                                                                strokeWeight: 2
                                                            }}
                                                            onClick={onPolygonClick}
                                                        />
                                                        <Marker
                                                            position={ {
                                                                lat: "-6,8974348755264865",
                                                                lng: "107,63619310305349"
                                                            }} key={1} />
                                                        {
                                                            markers.map((marker) => (
                                                                <Marker
                                                                    position={{
                                                                        lat: marker.lat,
                                                                        lng: marker.lng
                                                                    }} key={marker.lat} />
                                                            ))}
                                                    </GoogleMap>

                                                </LoadScript>
                                            </div>
                                        </div>
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                    </Col>
                    <Col sm="12" xl="6">
                        <Row>
                            <Col sm="12">
                                <Card>
                                    <CardHeader className="pb-0">
                                        <H4>Form Data Subarea #{subareaById?.area?.name}</H4>
                                        <span>{'*Wajib diisi.'}
                                        </span>
                                    </CardHeader>
                                    <Form
                                        className="form-bookmark needs-validation"
                                        onSubmit={handleSubmit(onUpdateClicked)}
                                    >
                                        <EditFormSubarea editrow={subareaById?.area} register={register} formState={formState} />
                                        <Btn attrBtn={{ color: 'primary', type: 'submit' }}>{Save}</Btn>&nbsp;&nbsp;
                                        <Btn attrBtn={{ color: 'secondary', onClick: editToggle }} >{Close}</Btn>

                                    </Form>
                                </Card>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </Container>

        </Modals>
    )
}

export default EditSubareaModal