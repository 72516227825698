import  {  useState } from 'react';
import { Shield, Info,UserCheck,Package } from 'react-feather';
import { Card, Col, Input, InputGroup, Nav, NavItem, NavLink } from 'reactstrap'; 
import { RiwayatPatroli, Logistik, Tamu,Details } from '../../../Constant';

const TimelineTab = ({ callbackActive }) => {
  const [activeTab, setActiveTab] = useState('1');
  let TimelineTabData=[
    {icon:<Info />, tittle:Details,activeTabClass:"1"},
    {icon:<Shield />, tittle:RiwayatPatroli,activeTabClass:"2"}
  ]   
  return (
      <Col sm="12" className="box-col-12">
        <Card>
          <div className="social-tab">
            <Nav tabs >
              {TimelineTabData.map((data,index)=>(<NavItem key={index}>
                <NavLink className={activeTab === data.activeTabClass ? 'active' : ''} onClick={() => { setActiveTab(data.activeTabClass); callbackActive(data.activeTabClass);}}>
                  {data.icon}{data.tittle}
                </NavLink>
              </NavItem>))}
            </Nav>
            {/* <InputGroup>
              <Input  type="text" placeholder="Search......." />
              <span className="input-group-text"> 
                <i className="fa fa-search"></i>
              </span>
            </InputGroup> */}
          </div>
        </Card >
      </Col >
  );
};
export default TimelineTab;