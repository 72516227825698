import React from 'react'

import { FormGroup, Label } from 'reactstrap'

const EditFormArea = ({ editrow, register, formState }) => {
    
    return (
        <div>
            <FormGroup className="col-md-12">
                <Label>Nama Area</Label>
                <input className="form-control" name="namaArea" type="text" defaultValue={editrow.name} key={editrow.name}
                    autoComplete="off" ref="namaArea"
                    {...register('namaArea', { required: true })}
                    error={formState.errors?.title ? true : false} />
                <span style={{ color: 'red' }}>
                    {formState.errors.namaArea && 'Nama Area wajib diisi'}
                </span>
            </FormGroup>
            <FormGroup className="col-md-12">
                <Label>Deksripsi</Label>
                <input className="form-control" name="desc" type="textarea" defaultValue={editrow.description.String} autoComplete="off"
                     error={formState.errors?.title ? true : false}
                      {...register('desc', { required: true })}
                />
                <span style={{ color: 'red' }}> {formState.errors.namaArea && 'Deskripsi wajib diisi'}</span>
            </FormGroup>
        </div>
    )
}

export default EditFormArea