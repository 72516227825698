import React, { Fragment } from 'react'
import { Card, CardBody, Container } from 'reactstrap'
import { Breadcrumbs, Btn, H4 } from '../../../AbstractElements'

import Schedule from './Schedule'
import { Link } from 'react-router-dom'

const index = () => {

    return (
        <Fragment>
            <Breadcrumbs mainTitle="Jadwal Petugas" parent="Home" title="Jadwal Petugas" subParent="Guards" />
            <Container fluid={true}>
                <div className="row">
                    <div className="col-sm-12">
                        <Card>
                            <div className="card-header pb-0">
                                <H4>Halaman Penjadwalan Petugas</H4><span>Halaman ini digunakan oleh admin untuk melakukan penjadwalan tugas kepada petugas lapangan.</span>
                            </div>
                            <CardBody>
                                <p className="mb-0">"Tata cara: Klik tanggal yang dituju kemudian isi form sesuai dengan isian yang tersedia lalu tekan simpan."</p>
                                <div className="text-end">
                                    <Link to={`${process.env.PUBLIC_URL}/guard/schedule`}>
                                        <Btn attrBtn={{ color: "primary" }} >
                                            <i className={`fa fa-bookmark`} style={{ marginRight: '10px' }}></i>
                                            Generate Jadwal Bulan ini
                                        </Btn>
                                    </Link>
                                </div>
                            </CardBody>
                        </Card>
                    </div>
                    <Schedule />
                </div>
            </Container>
        </Fragment>
    )
}

export default index