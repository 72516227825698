import React, { Fragment, useState } from 'react'
import { PlusSquare } from 'react-feather';
import { Card, CardBody, Container, Col, Row } from 'reactstrap'
import { Breadcrumbs, H4 } from '../../AbstractElements'
import { createNewPatrol } from '../../Constant';
import { Link } from 'react-router-dom';
import SearchBar from './SearchBar';
import Sidebar from './Sidebar';
import CardsClass from './Cards';
import axios from 'axios';
const Patrol = () => {
  const [refresh, setRefresh] = useState(false);
  const [filterSearchBoolean, setFilterSearchBoolean] = useState(false);
  const [isloadingData, setIsloadingData] = useState(false);
  const [searchResults, setSearchResults] = useState([]);
  // const [filterValue, setFilterValue] = useState("");
  const [searchTerm, setSearchTerm] = useState('');
  const [pages, setPages] = useState(0);
  const refreshData = () => {
    setRefresh(!refresh)
  }
  const apiBaseUrl = process.env.REACT_APP_API_BASE_URL;
  console.log(process.env)
  const onSeacrhClicked = async (event) => {
    event.preventDefault();
    setIsloadingData(true)
    if (event === ""){
      setTimeout(() => {
        setFilterSearchBoolean(false)
        setIsloadingData(false)
      }, 2000);
      
    } else {
      try {
        const response = await axios.get(`${apiBaseUrl}/patrol/hisotryFilterByName?searchTerm=${searchTerm}`); // Replace with your API endpoint
        // console.log(response)
        setSearchResults(response.data);
        setTimeout(() => {
          setIsloadingData(false)
          setFilterSearchBoolean(true)
        }, 2000);
        
      } catch (error) {
        console.error('Error fetching search results:', error);
        setIsloadingData(false)
        setFilterSearchBoolean(false)
      }
    }
    // console.log()
  };

  return (
    <Fragment>
      <Breadcrumbs mainTitle="Patroli" parent="Home" title="Patroli" />
      <Container fluid={true}>
        <div className="row">
          <div className="project-list col-sm-12">
            <Card>
              <div className="card-header pb-0">
                <H4>Data Patroli</H4>
              </div>
              <CardBody>
                <span>Halaman yang berisi semua data Patroli dari PT. Mitra Abadi Setia 57</span>
              </CardBody>
            </Card>
          </div>
        </div>
        <Row>
          <Sidebar 
            refreshData={refresh} 
            setRefresh={setRefresh} 
            searchTerm={searchTerm} 
            setSearchTerm={setSearchTerm} 
            onSeacrhClicked={onSeacrhClicked} 
            setSearchResults={setSearchResults}
            filterSearchBoolean = {filterSearchBoolean} 
            setFilterSearchBoolean = {setFilterSearchBoolean}/>
          <CardsClass 
            refreshData={refresh} 
            setRefresh={setRefresh} 
            filterSearchBoolean={filterSearchBoolean} 
            setFilterSearchBoolean={setFilterSearchBoolean} 
            setSearchTerm={setSearchTerm} 
            isloadingData ={isloadingData}
            searchResults ={searchResults}
            pages = {pages}
            setPages = {setPages}
            />
        </Row>
      </Container>
    </Fragment>
  )
}

export default Patrol