import Context from "./index";
import React, { useEffect, useState } from "react";
import axios from "axios";
import { GuardsApi } from "../../api";

const GuardProvider = (props) => {
  const [guard, setGuard] = useState([]);
  const [myGuardData, setMyGuardData] = useState([]);
  const [activeTabss, setActiveTabss] = useState("1");
  const [editrow, setEditrow] = useState({});
  const [editimgurl, setEditImgUrl] = useState();
  const [gridView, setgridView] = useState(true);
  const [editModal, seteditModal] = useState(false);
  const [bookMarkClass, setBookMarkClass] = useState(false);
  useEffect(() => {
    const getGuarddata = async () => {
      try {
        await axios.get(GuardsApi).then((resp) => {
          setGuard(resp.data);
        });
      } catch (error) {
        console.log("cancelled", error);
      }
    };
    getGuarddata();
  }, [setGuard]);

  const addNewGuard = (guardData) => {
    const guardTemp = {
      id: guard.length + 1,
      name: guardData.name,
      created_at: "20-05-2023",
      updated_at: "20-05-2023",
      created_by: "1",
      updated_by: "1"
    };
    setGuard((prev) => [...prev, guardTemp]);
  };

  const updateguard = (id, updatedData) => {
    const guardTemp = {
      id: id,
      name: updatedData.name,
      created_at: "20-05-2023",
      updated_at: "20-05-2023",
      created_by: "1",
      updated_by: "1"
    };
    setGuard(guard.map((item) => (item.id === id ? guardTemp : item)));
  };

  const removeguard = (id) => {
    setGuard(guard.filter((data) => data.id !== id));
  };

  const myguard = (myGuardData) => {
    setMyGuardData((prev) => [...prev, myGuardData]);
  };

  const updateMyguard = (id, updateMydata) => {
    const guardTemp = {
      id: id,
      name: updateMydata.name,
      description: updateMydata.desc,
      client_id: 1,
      created_at: "20-05-2023",
      updated_at: "20-05-2023",
      created_by: "1",
      updated_by: "1"
    };
    setMyGuardData(
      myGuardData.map((item) => (item.id === id ? guardTemp : item))
    );
  };

  const removemyguard = (id) => {
    setMyGuardData(myGuardData.filter((data) => data.id !== id));
  };

  return (
    <Context.Provider
      value={{
        ...props,
        guard,
        myGuardData,
        activeTabss,
        editrow,
        editimgurl,
        editModal,
        gridView,
        bookMarkClass,
        setBookMarkClass: setBookMarkClass,
        setgridView,
        seteditModal: seteditModal,
        setEditrow: setEditrow,
        setActiveTabss: setActiveTabss,
        setEditImgUrl: setEditImgUrl,
        myguard: myguard,
        updateguard: updateguard,
        removeguard: removeguard,
        addNewGuard: addNewGuard,
        updateMyguard: updateMyguard,
        removemyguard: removemyguard,
      }}
    >
      {props.children}
    </Context.Provider>
  );
};

export default GuardProvider;
