import { apiSlice } from "../app/api/apiSlice"

export const clientApiSlice = apiSlice.injectEndpoints({
    endpoints: builder => ({
        getClients: builder.query({
            query: () => '/client',
            method: 'GET',
            keepUnusedDataFor: 0,
            providesTags: (result, error, arg) => [
                { type: 'Client', id: "LIST" },
                ...result.data.map(id => ({ type: 'Client', id }))
                // console.log(result)
            ]
        }),
        getClientsAll: builder.mutation({
            query: () => ({
                url: '/client',
                method: 'GET',
                mode: 'no-cors'
            }),
            async onQueryStarted(arg, { dispatch, queryFulfilled }) {
                try {
                    const { data } = await queryFulfilled
                    console.log(data)
                } catch (err) {
                    console.log(err)
                }
            }
        }),
        addNewClient: builder.mutation({
            query: initialData => ({
                url: '/auth/register',
                method: 'POST',
                body: {
                    ...initialData
                }
            }),
            async onQueryStarted(arg, { dispatch, queryFulfilled }) {
                try {
                    const { data } = await queryFulfilled
                    console.log(data)
                } catch (err) {
                    console.log(err)
                }
            }
        }),
        updateData: builder.mutation({
            query: initialPost => ({
                url: `/client/${initialPost.id}`,
                method: 'PATCH',
                body: {
                    ...initialPost
                }
            })
        }),
        deleteData: builder.mutation({
            query: ({ id }) => ({
                url: `/client/${id}`,
                method: 'DELETE',
                body: { id }
            })
        }),
        getDataByUserId: builder.query({
            query: id => `/posts/?userId=${id}`,
        }),
        getUserLogs: builder.query({
            query: () => '/client/userLogs',
            method: 'GET',
        }),
        getDashboardData: builder.query({
            query: () => '/client/dashboardData',
            method: 'GET',
        }),
        getSystemSetting: builder.query({
            query: () => '/client/systemSettings',
            method: 'GET',
        }),
    })
    
})

export const {
    useGetClientsQuery,
    useGetDataByUserIdQuery,
    useGetUserLogsQuery,
    useGetDashboardDataQuery,
    useGetSystemSettingQuery,
    useGetClientsAllMutation,
    useAddNewClientMutation,
    useUpdateDataMutation,
    useDeleteDataMutation,
} = clientApiSlice 