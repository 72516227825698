import Context from "./index";
import React, { useEffect, useState } from "react";
import axios from "axios";
import { AreaApi } from "../../api";

const AreaProvider = (props) => {
  const [area, setArea] = useState([]);
  const [myAreaData, setMyAreaData] = useState([]);
  const [activeTabss, setActiveTabss] = useState("1");
  const [editrow, setEditrow] = useState({});
  const [editimgurl, setEditImgUrl] = useState();
  const [gridView, setgridView] = useState(true);
  const [editModal, seteditModal] = useState(false);
  const [bookMarkClass, setBookMarkClass] = useState(false);
  useEffect(() => {
    const getAreadata = async () => {
      try {
        await axios.get(AreaApi).then((resp) => {
          setArea(resp.data);
        });
      } catch (error) {
        console.log("cancelled", error);
      }
    };
    getAreadata();
  }, [setArea]);

  const addNewArea = (areaData) => {
    const areaTemp = {
      id: area.length + 1,
      name: areaData.name,
      description: areaData.desc,
      client_id: 1,
      created_at: "20-05-2023",
      updated_at: "20-05-2023",
      created_by: "1",
      updated_by: "1"
    };
    setArea((prev) => [...prev, areaTemp]);
  };

  const updatearea = (id, updatedData) => {
    const areaTemp = {
      id: id,
      name: updatedData.name,
      description: updatedData.desc,
      client_id: 1,
      created_at: "20-05-2023",
      updated_at: "20-05-2023",
      created_by: "1",
      updated_by: "1"
    };
    setArea(area.map((item) => (item.id === id ? areaTemp : item)));
  };

  const removearea = (id) => {
    setArea(area.filter((data) => data.id !== id));
  };

  const myarea = (myAreaData) => {
    setMyAreaData((prev) => [...prev, myAreaData]);
  };

  const updateMyarea = (id, updateMydata) => {
    const areaTemp = {
      id: id,
      name: updateMydata.name,
      description: updateMydata.desc,
      client_id: 1,
      created_at: "20-05-2023",
      updated_at: "20-05-2023",
      created_by: "1",
      updated_by: "1"
    };
    setMyAreaData(
      myAreaData.map((item) => (item.id === id ? areaTemp : item))
    );
  };

  const removemyarea = (id) => {
    setMyAreaData(myAreaData.filter((data) => data.id !== id));
  };

  return (
    <Context.Provider
      value={{
        ...props,
        area,
        myAreaData,
        activeTabss,
        editrow,
        editimgurl,
        editModal,
        gridView,
        bookMarkClass,
        setArea: setArea,
        setBookMarkClass: setBookMarkClass,
        setgridView,
        seteditModal: seteditModal,
        setEditrow: setEditrow,
        setActiveTabss: setActiveTabss,
        setEditImgUrl: setEditImgUrl,
        myarea: myarea,
        updatearea: updatearea,
        removearea: removearea,
        addNewArea: addNewArea,
        updateMyarea: updateMyarea,
        removemyarea: removemyarea,
      }}
    >
      {props.children}
    </Context.Provider>
  );
};

export default AreaProvider;
