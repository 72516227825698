import React, { useContext, useEffect, useState } from 'react'
import {
    Aksi,
    Area,
    TableAreaSpanText,
    ID_Area,
    Deksripsi_Area,
    Nama_Area
} from "../../../Constant";
import { Col, Card, Table, Row, ButtonGroup } from "reactstrap";
import HeadingCommon from "../../../Common/Component/HeadingCommon";
import { Btn } from "../../../AbstractElements";
import AreaContext from "../../../Helper/area";
import NoArea from './NoArea';
import SweetAlert from 'sweetalert2';
import { Link } from 'react-router-dom';
import { useGetAreaByClientQuery } from "../../../Services/areaApiSlice"
import { useDispatch } from 'react-redux';
const AreaCard = ({ clientId ,refresh, setRefresh}) => {

    const dispatch = useDispatch();

    const { area, setEditrow, editModal, seteditModal, setArea } = useContext(AreaContext);
    const {
        data: areas,
        isLoading,
        isSuccess,
        isError,
        error,
        refetch
    } = useGetAreaByClientQuery({ clientId: clientId })
    //   useEffect(() => {
    //       setArea(areas)
    //     }, [setArea]);

    const editAreaToggle = () => {
        seteditModal(!editModal);
    };
    const OnEditHandleClick = (data) => {
        setEditrow(data);
        editAreaToggle();
    };

    const RemoveArea = (areaId) => {
        SweetAlert.fire({
            title: 'Apakah kamu yakin ?',
            text: 'Sekali data dihapus, maka data akan benar-benar hilang !',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Ok',
            cancelButtonText: 'cancel',
            reverseButtons: true,
        }).then((result) => {
            if (result.value) {
                // if (fillstar) removeArea(areaId);
                // removearea(areaId);
                SweetAlert.fire('Dihapus!', 'Data Berhasil dihapus.', 'success');
            } else {
                SweetAlert.fire('Data Masih Aman Terimpan !');
            }
            // console.log(areaId)
        });
    };

    useEffect(() => {
        dispatch(refetch);
        setRefresh(false)
    }, [refresh, dispatch]);

    let content;
    if (isLoading) {
        content = <p>"Loading..."</p>;
    } else if (isSuccess) {
        content = areas.data.map((item, index) =>
            <tr className="dashed" key={index}>
                <td>1</td>
                <td>{item.id}</td>
                <td>{item.name} </td>
                <td>{item.description.String}</td>
                <td>
                    <ButtonGroup>
                        <Btn attrBtn={{ color: "info", size: "md" }}>
                            <Link to={process.env.PUBLIC_URL + "/client/" + item.client_id + "/client-detail/" + item.id + "/subarea"}
                                style={{ color: 'white' }}>
                                <div className="likes-profile text-center">{"Subarea"}</div>

                            </Link>
                        </Btn>
                        <Btn attrBtn={{ color: "warning", size: "sm", onClick: () => OnEditHandleClick(item) }}>
                            <i className={`fa fa-pencil-square-o `}></i>
                        </Btn>
                        <Btn attrBtn={{ color: "danger", size: "sm", onClick: () => RemoveArea(item.id) }}>
                            <i className={`fa fa-trash `}></i>
                        </Btn>
                    </ButtonGroup>
                </td>
            </tr>
        )
    } else if (isError) {
        content = <p>{error}</p>;
    }

    return (
        <Card className="mb-2">
            <HeadingCommon Heading={Area} span={TableAreaSpanText} />
            <Row className="card-block">
                <Col sm="12" lg="12" xl="12">
                    <div className="table-responsive theme-scrollbar">
                        <Table className="table table-dashed mb-2 table-responsive theme-scrollbar ">
                            <thead>
                                <tr className="dashed">
                                    <th scope="col">{"#"}</th>
                                    <th scope="col">{ID_Area}</th>
                                    <th scope="col">{Nama_Area}</th>
                                    <th scope="col">{Deksripsi_Area}</th>
                                    <th scope="col">{Aksi}</th>
                                </tr>
                            </thead>
                            <tbody>
                                {isSuccess ?
                                    (areas.data.length > 0 ?
                                        content
                                        :
                                        <p>Data Area Masih Kosong.</p>) : ''
                                }
                            </tbody>
                        </Table>
                    </div>
                </Col>
            </Row>
        </Card>
    )
}

export default AreaCard