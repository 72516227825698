import { LI, UL, Image } from "../../../AbstractElements";
import {profileVector} from "../../../Data/Profiledata/profiledata";
import { dynamicImage } from "../../../Services/index";
import ProfileImageCard from "./ProfileImageCard";

const ProfileVector = () => {
  return (
    <div className="profile-vector">
      <UL attrUL={{ className: "dots-images simple-list" }}>
        {profileVector.map((item, i) => (
          <LI attrLI={{ className: `${item.classname} ` }} key={i}></LI>
        ))}
      </UL>
      <Image attrImage={{ className: "img-fluid img-100", src: dynamicImage("company/logo_security.png"),}} alt="image"/>
      <ProfileImageCard/>
    </div>
  );
};

export default ProfileVector;