import React, { useContext, useState } from 'react'
import { useForm } from 'react-hook-form';
import { Form, FormGroup, Input, Label } from 'reactstrap';
import { toast } from 'react-toastify';
import { Btn } from '../../../AbstractElements';
import Modals from '../../../Common/Modals';
import { Close, Save } from '../../../Constant';
import { useGetGuardsQuery } from '../../../Services/securityOfficerApiSlice';
import { useGetAreasQuery } from '../../../Services/areaApiSlice';
import { useAddNewSchedulesMutation } from '../../../Services/scheduleOfficerApiSlice';
// import AreaContext from '../../../Helper/area';
const AddScheduleModal = ({ modal, NewMessage, toggle, selectInfo, refresh, setRefresh }) => {
    const [addNewSchedules, { isLoading }] = useAddNewSchedulesMutation()
    const { register, handleSubmit, formState: { errors } } = useForm();
    // console.log(selectInfo)
    const {
        data: guards,
        isSuccess: guardsSuccess,
        isLoading: gLoading,
        isError: gError,
        error: gErrorResult
    } = useGetGuardsQuery('getGuards')

    const {
        data: areas,
        isSuccess: areasSuccess,
        isLoading: aLoading,
        isError: aError,
        error: aErrorResult
    } = useGetAreasQuery('getAreas')

    const onSaveClicked = async (data) => {


        try {
            if (selectInfo) {
                const response = await addNewSchedules(
                    {
                        area_id: data.area,
                        officer_id: data.petugas,
                        start_date: selectInfo.startStr,
                        end_date: selectInfo.startStr
                    }
                ).unwrap()
                let calendarApi = selectInfo.view.calendar

                calendarApi.unselect() // clear date selection
                // calendarApi.refetchEvents()
                console.log(calendarApi)
            }
            // navigate('/')
            setRefresh(true)

            toast.success("Berhasil Menyimpan Data Area.");
            toggle()
        } catch (err) {
            toast.error("Gagal Menyimpan Data Area.");
            console.error('Failed to save the post', err)
        }
    }

    let dataGuard;
    if (gLoading) {
        dataGuard = <p>"Loading..."</p>;
    } else if (guardsSuccess) {
        dataGuard = guards.data?.map((item, index) =>
            <option value={item.id} key={index}>{item.name}</option>
        )
    } else if (gError) {
        dataGuard = <p>{gErrorResult}</p>;
    }

    let dataArea;
    if (aLoading) {
        dataArea = <p>"Loading..."</p>;
    } else if (areasSuccess) {
        dataArea = areas.data?.map((item, index) =>
            <option value={item.id} key={index}>{item.name}</option>
        )
    } else if (aError) {
        dataArea = <p>{aErrorResult}</p>;
    }


    return (
        <Modals isOpen={modal} title={NewMessage} toggler={toggle} onSubmit={false}>
            <Form
                className="form-bookmark needs-validation"
                onSubmit={handleSubmit(onSaveClicked)}
            >
                <FormGroup>
                    <Label className="col-form-label" for="recipient-name">{'Pilih Petugas:'}</Label>
                    <select type="select" className=" form-select" {...register('petugas', { required: true })}>
                        {guardsSuccess ?
                            dataGuard
                            : ''
                        }
                    </select>
                    <div className="invalid-feedback">{'Example invalid custom select feedback'}</div>
                </FormGroup>
                <FormGroup>
                    <Label className="col-form-label" for="recipient-name">{'Pilih Area:'}</Label>
                    <select type="select" className=" form-select" {...register('area', { required: true })} >
                        {areasSuccess ?
                            dataArea
                            : ''
                        }
                    </select>
                    <div className="invalid-feedback">{'Example invalid custom select feedback'}</div>
                </FormGroup>
                <Btn attrBtn={{ color: 'primary', type: 'submit' }}>{Save}</Btn>&nbsp;&nbsp;
                <Btn attrBtn={{ color: 'secondary', onClick: toggle }} >{Close}</Btn>

            </Form>
        </Modals>
    )
}

export default AddScheduleModal