import { GoogleMap, LoadScript, InfoWindow, Marker, Polygon } from '@react-google-maps/api';
import React, { useCallback, useEffect, useRef, useState } from 'react'
import { Col, Row, Card, CardBody } from 'reactstrap'
import ClientProfileCard from './ClientProfileCard'
import HeadingCommon from "../../../Common/Component/HeadingCommon";
import AreaCard from './AreaCard';
import { useParams } from 'react-router';
import { CurrentLocation, MarkerMap } from '../../../Constant';
import axios from 'axios';
import { Btn, H4, H5, H6, Image } from "../../../AbstractElements";

const DetailArea = () => {
    const { id } = useParams(); // id as client Id
    const [refresh, setRefresh] = useState(false);
    const [showMap, setShowMap] = useState(false);
    const [location, setlocation] = useState();
    const [state, setState] = useState([])
    const [stateMarker, setStateMarker] = useState([])
    const [color, setColor] = useState('Grey')
    const apiBaseUrl = process.env.REACT_APP_API_BASE_URL;
    const polygonRef = useRef(null);
    const listenersRef = useRef([]);
    const showinfowindow = () => {
        setlocation({ ...location, address: true });
    };
    const containerStyle = {
        height: '500px'
    };

    let points = []

    const seeMap = async (e) => {
        e.preventDefault()

        if (showMap) {
            setShowMap(false)
            points = []
        } else {

            let config = {
                method: 'get',
                maxBodyLength: Infinity,
                url: `${apiBaseUrl}/areas/getPolygon/${id}`,
                headers: {
                    'Content-Type': 'application/json'
                },
            };
            let configMarker = {
                method: 'get',
                maxBodyLength: Infinity,
                url: `${apiBaseUrl}/areas/getMarker/${id}`,
                headers: {
                    'Content-Type': 'application/json'
                },
            };

            const response = await axios.request(config)
                .then((response) => {
                    console.log((response))
                    // setSearchResults(response.data);
                    if (response.status == 200 && response.data) {
                        setShowMap(true)
                        setState(response.data.data)
                    }
                    setTimeout(() => {
                        // setIsloadingData(false)
                    }, 2000);
                    // console.log(JSON.stringify(response.data));
                })
                .catch((error) => {
                    console.log(error);
                    // setIsloadingData(false)
                });


            const responseMarker = await axios.request(configMarker)
                .then((response) => {
                    console.log((response))
                    if (response.status == 200 && response.data) {
                        setShowMap(true)
                        setStateMarker(response.data.data)
                    }
                    
                    // setSearchResults(response.data);
                    setTimeout(() => {
                        // setIsloadingData(false)
                    }, 2000);
                    // console.log(JSON.stringify(response.data));
                })
                .catch((error) => {
                    console.log(error);
                    // setIsloadingData(false)
                });
            console.log("state : " ,state)

        }
    }
    const [point, setPoint] = useState(state)
    // Bind refs to current Polygon and listeners
    const onLoad = useCallback(
        polygon => {
            setPoint(state)
            polygonRef.current = polygon;
            const path = polygon.getPath();
            listenersRef.current.push( );
        },
        [points, setPoint]
    );
    // Clean up refs
    const onUnmount = useCallback(() => {
        listenersRef.current.forEach(lis => lis.remove());
        polygonRef.current = null;
    }, []);
    return (
        <Row>
            <Col xl="3" md="5" className="xl-30 box-col-40">
                <div
                    className="default-according style-1 faq-accordion job-accordion"
                    id="accordionoc4"
                >
                    <Row>
                        <ClientProfileCard refresh={refresh} setRefresh={setRefresh} />
                    </Row>
                </div>
            </Col>
            <Col xl="9" md="9" className="xl-70 box-col-60">
                <Row>
                    <AreaCard clientId={id} refresh={refresh} setRefresh={setRefresh} />
                </Row>
            </Col>

            {/* map area */}
            <Col xl="12" md="12">
                <Btn attrBtn={{ color: "primary text-center me-2 m-b-10", type: "button", onClick: (e) => seeMap(e) }}>Lihat Dalam Peta</Btn>

                {showMap ?
                    <Card>
                        <HeadingCommon Heading="Subarea Dalam Map" CardHeaderClassName="pb-0" span="Menampilkan semua posisi subarea pada map." />
                        <CardBody>
                            <div className="map-js-height">
                                <div id="gmap-simple" className="map-block">
                                    <LoadScript
                                        googleMapsApiKey={process.env.REACT_APP_GOOGLEAPI}
                                    >
                                        <GoogleMap
                                            mapContainerStyle={containerStyle}
                                            center={{
                                                lat: -6.9174639,
                                                lng: 107.6191228
                                            }}
                                            zoom={13}
                                        >
                                            {state ?
                                                state.map((item, i) =>
                                                    <Polygon
                                                        path={item.coordinate}
                                                        onLoad={onLoad}
                                                        onUnmount={onUnmount}
                                                        options={{
                                                            fillColor: 'blue',
                                                            strokeColor: `#0a6ebd`,
                                                            fillOpacity: 0.5,
                                                            strokeWeight: 2
                                                        }}
                                                    />
                                                )
                                                :
                                                ''
                                            }

                                            {stateMarker ?
                                                stateMarker.map((item, i) => 
                                                <Marker
                                                position={{
                                                    lat: item.lat,
                                                    lng: item.lng
                                                }} key={i} />
                                                ) 
                                                : 
                                                ''
                                            }
                                        </GoogleMap>

                                    </LoadScript>
                                </div>
                            </div>
                        </CardBody>
                    </Card> : ''
                }

            </Col>
        </Row>
    )
}

export default DetailArea